import {
  getLocalStorage,
  getLocalStorageProp,
} from '~/ZenOne-StoryBook/helpers/localStorage'
import {
  isHavingProps,
} from '~/ZenOne-StoryBook/helpers/object'

export const getInitialUser = () => getLocalStorage('user') || null
export const getInitialCard = () => {
  const params = getLocalStorageProp('practiceLocation', 'params')

  return isHavingProps(params) ? params?.card : {}
}
export const getInitialPracticeLocation = () => getLocalStorage('practiceLocation') || null
export const getInitialPractice = () => getLocalStorage('practice') || null

export const MARSHMALLOW_USER_ID = 14
export const AUSTIN_DENTAL_USER_ID = 112
export const CEDAR_PARK_USER_ID = 46
