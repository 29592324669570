
import { mapMutations } from 'vuex'
import {
  getLocalStorage,
  setLocalStorage,
} from '~/ZenOne-StoryBook/helpers/localStorage'
import { INVENTORY_LS_ALIAS } from '~/components/Inventory/helper'

export default {
  name: 'Resizer',
  props: {
    step: {
      type: Number,
      default: 5,
    },
    minWidth: {
      type: Number,
      default: 200,
    },
    maxWidth: {
      type: Number,
      default: 320,
    },
    currentWidth: {
      type: String,
      default: '320px',
    },
  },
  beforeDestroy() {
    this.resetListeners()
  },
  methods: {
    resetListeners() {
      this.stopResize()
      window.removeEventListener('mousemove', this.resize)
    },
    ...mapMutations('inventory', {
      setIsNavTreeResizing: 'SET_IS_NAV_TREE_RESIZING',
    }),
    mouseDownHandler(e) {
      e.preventDefault()
      window.addEventListener('mousemove', this.resize)
      window.addEventListener('mouseup', this.stopResize)
    },
    resize(e) {
      this.setIsNavTreeResizing(true)
      let width = e.pageX - this.step
      if (width > this.maxWidth) {
        width = this.maxWidth
      } else if (width < this.minWidth) {
        width = this.minWidth
      }
      this.$emit('on-resize', { width })
    },
    stopResize() {
      window.removeEventListener('mousemove', this.resize)
      this.setIsNavTreeResizing(false)
      const lsData = getLocalStorage(INVENTORY_LS_ALIAS)
      lsData.foldersNavWidth = this.currentWidth
      setLocalStorage({
        name: INVENTORY_LS_ALIAS,
        data: lsData,
      })
    },
  },
}
