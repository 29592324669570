import actions from './actions'
import mutations from './mutations'
import {
  CART_LS_ALIAS,
  getCartItemsAll,
  getFilteredDistributors,
  getInitialCartItemIds,
  getInitialCartSelectedIds,
  getInitialSflIds,
  getInitialSflSelectedIds,
  getIsAllSelected,
  getSavingsList,
  getSelectedSubtotalQty,
} from './helper'
import { getArrayLength } from '~/ZenOne-StoryBook/helpers/types'
import { getLocalStorageProp } from '~/ZenOne-StoryBook/helpers/localStorage'

const shoppingCart = {
  state: {
    searchTerm: '',
    isCheckout: false,
    distributors: [],
    withoutSavings: {},
    savingCosts: 0,
    savingSubtotal: 0,
    cartItemsTotalCount: 0,
    cartItemsCount: 0,
    isHavingActiveSavings: false,
    cartItemIds: getInitialCartItemIds(),
    selected: {
      cart: getInitialCartSelectedIds(),
      savedForLater: getInitialSflSelectedIds(),
    },
    deletedItems: [],
    selectAllCartItemsTrigger: false,
    deselectAllCartItemsTrigger: false,
    // Saved For Later
    savedForLaterList: [],
    savedForLaterIds: getInitialSflIds(),
    selectAllSflTrigger: false,
    deselectAllSflTrigger: false,
    isAllCartItemsSelected: getLocalStorageProp(CART_LS_ALIAS, 'isAllCartItemsSelected') ?? true,
  },
  getters: {
    searchTerm: (state) => state.searchTerm,
    distributors: (state) => state.distributors,
    distributorsFiltered: (state, getters) =>
      getFilteredDistributors(getters.distributors, getters.searchTerm),
    cartItemsAll: (state, getters) =>
      getCartItemsAll(getters.distributors, getters.savedForLaterList),
    cartItemIds: (state) => state.cartItemIds,
    selected: (state) => state.selected,
    isCheckout: (state) => state.isCheckout,
    savingsList: (state, getters) => getSavingsList(getters.distributors),
    withoutSavings: (state) => state.withoutSavings,
    savingSubtotal: (state) => state.savingSubtotal,
    savingCosts: (state) => state.savingCosts,
    isHavingActiveSavings: (state) => state.isHavingActiveSavings,
    cartSelectedIds: (state, getters) => getters.selected?.cart ?? [],
    selectedSubtotalQty: (state, getters) =>
      getSelectedSubtotalQty(getters.distributors),
    hasNoSelectedCartItems: (state, getters) =>
      getArrayLength(getters.cartSelectedIds) === 0,
    deletedItems: (state) => state.deletedItems,
    cartItemsCount: (state) => state.cartItemsCount,
    cartItemsTotalCount: (state) => state.cartItemsTotalCount,
    isEmptyCart: (state, getters) => getters.cartItemsCount === 0,
    selectAllCartItemsTrigger: (state) => state.selectAllCartItemsTrigger,
    deselectAllCartItemsTrigger: (state) => state.deselectAllCartItemsTrigger,
    isAllCartItemsSelected: (state, getters) => state.isAllCartItemsSelected,
    // Saved For Later
    savedForLaterList: (state) => state.savedForLaterList,
    savedForLaterIds: (state) => state.savedForLaterIds,
    savedForLaterSelectedIds: (state, getters) =>
      getters.selected?.savedForLater ?? [],
    sflCount: (state, getters) => getArrayLength(getters.savedForLaterList),
    hasNoSelectedSfl: (state, getters) =>
      getArrayLength(getters.savedForLaterSelectedIds) === 0,
    isAllSflSelected: (state, getters) =>
      getIsAllSelected(getters.savedForLaterSelectedIds, getters.sflCount),
    selectAllSflTrigger: (state) => state.selectAllSflTrigger,
    deselectAllSflTrigger: (state) => state.deselectAllSflTrigger,
  },
  mutations,
  actions,
  namespaced: true,
}

export default shoppingCart
