
import { mapGetters, mapMutations } from 'vuex'
import { directive as onClickaway } from 'vue-clickaway'
import iconSearch from '~/components/img/icon-search.svg'
import route from '~/mixins/route'

export default {
  name: 'TopMenuSearchBarInventory',
  directives: {
    onClickaway,
  },
  mixins: [route],
  data() {
    return {
      model: '',
      iconSearch,
    }
  },
  computed: {
    ...mapGetters({
      filters: 'inventory/filters',
      needToClearSearch: 'inventory/needToClearSearch',
      searchFilterValue: 'inventory/filterSearch',
    }),
    showClearSearchInputButton() {
      return !!this.model?.length
    },
  },
  watch: {
    needToClearSearch: {
      handler() {
        this.model = ''
      },
    },
  },
  mounted() {
    const { searchFilterValue = '' } = this
    if (searchFilterValue.length) this.model = searchFilterValue
  },
  methods: {
    ...mapMutations({
      applySearch: 'inventory/SET_NEED_FETCH_ITEMS',
      setFilters: 'inventory/SET_FILTERS',
    }),
    setSearchFilter() {
      this.setFilters({ ...this.filters, search: this.model })
    },
    onClickaway() {
      this.setSearchFilter()
    },
    enterKeyUpHandler() {
      this.setSearchFilterAndBlur()
      this.applySearch()
    },
    tabKeyDownHandler(e) {
      e.preventDefault()
      this.setSearchFilterAndBlur()
    },
    escapeKeyUpHandler() {
      this.setSearchFilterAndBlur()
    },
    clearSearchInputBtnClickHandler() {
      this.clearSearchInput()
    },
    clearSearchInput() {
      this.model = ''
      this.setSearchFilter()
      this.applySearch()
      this.focus()
    },
    setSearchFilterAndBlur() {
      this.setSearchFilter()
      this.blur()
    },
    blur() {
      this.$refs.searchInput.blur()
    },
    focus() {
      this.$refs.searchInput.focus()
    },
  },
}
