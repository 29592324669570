
export default {
  name: 'FilterCheckbox',
  props: {
    initialModel: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    filterAlias: {
      type: String,
      default: null,
    },
    select: {
      type: Function,
      default: () => {},
      required: true,
    },
  },
}
