
import practiceLocation from '~/mixins/practiceLocation'

export default {
  name: 'CanceledRetryTitle',
  mixins: [practiceLocation],
  props: {
    success: {
      type: Boolean,
      default: false,
    },
    addClass: {
      type: String,
      default: '',
    },
  },
}
