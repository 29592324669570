
import shrink from '../../mixins/shrink.js'
export default {
  name: 'TextShrink',
  mixins: [shrink],
  props: {
    width: {
      type: Number,
      default: 270,
    },
    name: {
      type: String || null,
      default: null,
    },
    maxLength: {
      type: Number,
      default: 60,
    },
    tooltip: {
      type: Boolean,
      default: true,
    },
    upperCase: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    shrinked() {
      const { shrink, name, maxLength } = this
      return shrink(name, maxLength)
    },
  },
}
