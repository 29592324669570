
import ColumnsSection from './ColumnsSection'
import FolderColumnCheckbox from './Checkbox'
import { DEFAULT_COLUMN, OPTIONAL_COLUMN } from '~/helpers/inventory/columns'
import { generateRandomString } from '~/ZenOne-StoryBook/helpers/random'
import ui from '~/ZenOne-StoryBook/mixins/ui'
import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'

export default {
  name: 'FolderColumnsForm',
  components: { FolderColumnCheckbox, ColumnsSection },
  mixins: [ui],
  props: {
    folder: {
      type: Object,
      default() {
        return {}
      },
      required: true,
    },
    isCreateMode: {
      type: Boolean,
      default: true,
      required: true,
    },
    dialog: {
      type: Boolean,
      default: false,
      required: true,
    },
    hasOptionalColumns: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      init: false,
      defaultColumns: [],
      optionalColumns: [],
      generateRandomString,
      DEFAULT_COLUMN,
      OPTIONAL_COLUMN,
    }
  },
  computed: {
    showOptionalSection() {
      return this.hasOptionalColumns
    },
  },
  watch: {
    dialog: {
      immediate: true,
      handler(value) {
        if (!value) {
          this.defaultColumns = []
          this.optionalColumns = []
          this.init = false
        } else {
          this.initModel()
        }
      },
    },
  },
  methods: {
    checkboxChangeModelHandler(data = {}) {
      this.$emit('on-folder-settings-changed', data)
    },
    initModel() {
      const { folder = {} } = this
      this.defaultColumns = isArrayWithLength(folder.defaultColumns)
        ? [...folder.defaultColumns]
        : []
      this.optionalColumns = isArrayWithLength(folder.optionalColumns)
        ? [...folder.optionalColumns]
        : []
      this.init = true
    },
  },
}
