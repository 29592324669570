import { isHavingProps } from '~/ZenOne-StoryBook/helpers/object'
import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'
import {
  columnsListDefault,
  columnsListOptional,
  columnsListOptionalRoot,
} from '~/helpers/inventory/columns'
import { INVENTORY_LS_ALIAS } from '~/components/Inventory/helper'
import { getLocalStorage } from '~/ZenOne-StoryBook/helpers/localStorage'

export const mapFolder = (folder = {}) => {
  const isRootFolder = folder.parent_id === null
  if (!isHavingProps(folder)) return {}

  // set expand from LS
  const options = getLocalStorage(INVENTORY_LS_ALIAS)?.foldersNavOptions
  if (isArrayWithLength(options)) {
    const index = options.findIndex((item) => item.id === folder.id)
    if (index !== -1) {
      folder.expand = options[index].expand
    }
  }

  const { columns = [] } = folder
  let defaultColumns = isRootFolder
    ? [...columnsListDefault]
    : [...columnsListDefault].filter((col) => col?.onlyRoot !== true)
  let optionalColumns = isRootFolder
    ? [...columnsListOptionalRoot]
    : [...columnsListOptional]

  if (isArrayWithLength(columns)) {
    defaultColumns = defaultColumns.map(col => columns.find(savedCol => savedCol.name === col.name) ?? col)
    optionalColumns = optionalColumns.map(col => columns.find(savedCol => savedCol.name === col.name) ?? col)
  }

  folder = {
    ...folder,
    defaultColumns,
    optionalColumns,
  }

  return folder
}
