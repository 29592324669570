import { isNotUndefined } from '~/ZenOne-StoryBook/helpers/types'

export const capitalizeFirstLetter = (string) => {
  if (isNotUndefined(string) && string.length > 0) {
    return string[0].toUpperCase() + string.substring(1)
  }
  return ''
}

export const snakeToCamel = (str) => {
  return str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase())
}
