import { isHavingProps } from '../helpers/object'
export const STATUS_ACTIVE = 'active'
export const STATUS_IN_REVIEW = 'in_review'

export const getLogo = (logo) => {
  return logo &&
    isHavingProps(logo) &&
    logo.data &&
    isHavingProps(logo.data) &&
    logo.data.thumb &&
    isHavingProps(logo.data.thumb) &&
    logo.data.thumb.data &&
    isHavingProps(logo.data.thumb.data) &&
    logo.data.thumb.data.path
    ? logo.data.thumb.data.path
    : null
}
