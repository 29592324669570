import { isNotUndefined } from '~/ZenOne-StoryBook/helpers/types'
import { STATUS_ACTIVE } from '~/ZenOne-StoryBook/helpers/parnter'

export const resolvePaymentSubscriptionLocalStorage = (store) => {
  const practiceLocation = store.getters['user/practiceLocation']
  const status = practiceLocation?.status
  if (isNotUndefined(status)) {
    if (status === STATUS_ACTIVE) {
      store.commit('subscription/SET_IS_RETRY_BTN_CLICKED', false)
      store.commit('subscription/SET_IS_RENEW_BTN_CLICKED', false)
      store.commit('subscription/SET_IS_RENEW_DECLINED', false)
    }
  }
}
