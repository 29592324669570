import { isNotUndefined } from '~/ZenOne-StoryBook/helpers/types'

export const capitalizeFirstLetter = (string) => {
  if (isNotUndefined(string) && string.length > 0) {
    return string[0].toUpperCase() + string.substring(1)
  }
  return ''
}

export const pluralizeText = (string = '', count = 0) => {
  if (string && string.length > 0 && count > 0) {
    return count === 1 ? string : `${string}s`
  }
  return string
}

// Remove special chars
export const toPlainText = (text = '') => {
  if (!text) return ''
  const scratchDiv = document.createElement('div')
  scratchDiv.innerHTML = text
  return scratchDiv.textContent
}
