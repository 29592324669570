
import { isHavingProps } from '~/ZenOne-StoryBook/helpers/object'

export default {
  name: 'ConfirmDialog',
  data() {
    return {
      dialog: false,
      title: '',
      message: '',
      okColor: 'green_02',
      buttonText: {
        ok: 'Ok',
        cancel: 'Cancel',
      },
      resolvePromise: undefined,
      rejectPromise: undefined,
    }
  },
  computed: {
    buttonTextOk() {
      return this.buttonText?.ok ?? 'Ok'
    },
    buttonTextCancel() {
      return this.buttonText?.cancel ?? 'Cancel'
    },
  },
  methods: {
    open(params = {}) {
      const { title = '', message = '', buttonText = {}, okColor = '' } = params
      if (title) {
        this.title = title
      }
      if (message) {
        this.message = message
      }
      if (buttonText && isHavingProps(buttonText)) {
        this.buttonText = { ...buttonText }
      }
      if (okColor) {
        this.okColor = okColor
      }
      this.dialog = true
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    resetModelAndClose() {
      this.close()
      this.resetModel()
    },
    resetModel() {
      this.title = ''
      this.message = ''
      this.buttonText = {
        ok: 'Ok',
        cancel: 'Cancel',
      }
    },
    close() {
      this.dialog = false
    },
    cancelBtnClickHandler() {
      this.resolvePromise(false)
      this.resetModelAndClose()
    },
    okBtnClickHandler() {
      this.resolvePromise(true)
      this.resetModelAndClose()
    },
  },
}
