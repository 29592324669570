import * as types from './mutation-types'
import {
  DELETE_ITEMS_FROM_CART,
  DISTRIBUTOR_PROCESS,
  DISTRIBUTOR_PROCESS_SUBTYPE_LOGIN_RESULT,
  ORDER_DELIVERY_CONFIRMATION,
} from '~/ZenOne-StoryBook/helpers/socket'
import { setLocalStorage } from '~/ZenOne-StoryBook/helpers/localStorage'
import {
  CONSUMER_CART_ALIAS,
  EVENT_NOTIFICATIONS_ALIAS,
} from '~/helpers/common'
import { isArrayAndHaveLength } from '~/ZenOne-StoryBook/helpers/array'
import { removeLoginResultEvent } from '~/helpers/connect-distributors'

export default {
  [types.ADD_NOTIFICATION](state, payload) {
    const { type: notificationType = '', subType = '' } = payload
    if (!notificationType) return false
    let { meta = {} } = payload
    if (notificationType === DELETE_ITEMS_FROM_CART) {
      meta = meta.deletedItems
      const oldList = state.notifications[DELETE_ITEMS_FROM_CART]
      if (!isArrayAndHaveLength(oldList)) {
        state.notifications[DELETE_ITEMS_FROM_CART] = meta
      } else {
        state.notifications[DELETE_ITEMS_FROM_CART] = [...oldList, ...meta]
      }
      setLocalStorage({
        name: CONSUMER_CART_ALIAS,
        data: {
          [DELETE_ITEMS_FROM_CART]: state.notifications[DELETE_ITEMS_FROM_CART],
        },
      })
    } else if (notificationType === DISTRIBUTOR_PROCESS) {
      if (!subType) return false
      const eventNotificationList = state.notifications.eventNotificationList
      let subTypeArrayOld = eventNotificationList[subType]
      meta.notificationId = Date.now()
      meta.subType = subType
      if (
        subType === DISTRIBUTOR_PROCESS_SUBTYPE_LOGIN_RESULT &&
        meta?.distributor_id
      ) {
        subTypeArrayOld = removeLoginResultEvent(
          subTypeArrayOld,
          meta.distributor_id
        )
      }
      const subTypeArrayNew = isArrayAndHaveLength(subTypeArrayOld)
        ? [...subTypeArrayOld, meta]
        : [meta]
      state.notifications.eventNotificationList = {
        ...state.notifications.eventNotificationList,
        [subType]: subTypeArrayNew,
      }
      setLocalStorage({
        name: EVENT_NOTIFICATIONS_ALIAS,
        data: {
          eventNotificationList: state.notifications.eventNotificationList,
        },
      })
    } else if (notificationType === ORDER_DELIVERY_CONFIRMATION) {
      const eventNotificationList = state.notifications.eventNotificationList
      const subTypeArrayOld = eventNotificationList[ORDER_DELIVERY_CONFIRMATION]
      meta.notificationId = Date.now()
      meta.subType = ORDER_DELIVERY_CONFIRMATION
      const subTypeArrayNew = isArrayAndHaveLength(subTypeArrayOld)
        ? [...subTypeArrayOld, meta]
        : [meta]
      state.notifications.eventNotificationList = {
        ...state.notifications.eventNotificationList,
        [ORDER_DELIVERY_CONFIRMATION]: subTypeArrayNew,
      }
      setLocalStorage({
        name: EVENT_NOTIFICATIONS_ALIAS,
        data: {
          eventNotificationList: state.notifications.eventNotificationList,
        },
      })
    } else {
      state.notifications[notificationType] = [
        ...state.notifications[notificationType],
        meta,
      ]
    }
  },
  [types.CLEAR_NOTIFICATIONS_BY_TYPE](state, payload) {
    const { type = null } = payload
    if (type) state.notifications[type] = []
  },
  [types.SET_NOTIFICATIONS_BY_SUBTYPE](state, payload) {
    const { subType = null, value = [] } = payload
    if (!subType || !Array.isArray(value)) return false
    state.notifications.eventNotificationList[subType] = value
  },
  [types.DELETE_EVENT_NOTIFICATION](state, payload) {
    const {
      event: { subType = '', notificationId = null },
    } = payload
    if (!subType || !notificationId) return false
    const subTypeArrayOld = state.notifications.eventNotificationList[subType]
    let subTypeArrayNew = []
    if (isArrayAndHaveLength(subTypeArrayOld) && subTypeArrayOld.length !== 1) {
      subTypeArrayNew = subTypeArrayOld.filter(
        (notification) =>
          parseInt(notification.notificationId) !== parseInt(notificationId)
      )
    }
    state.notifications.eventNotificationList[subType] = subTypeArrayNew
    setLocalStorage({
      name: EVENT_NOTIFICATIONS_ALIAS,
      data: {
        eventNotificationList: state.notifications.eventNotificationList,
      },
    })
  },
  [types.SET_INVALID_LOGIN_CHECK](state, { value }) {
    state.invalidLoginCheck = value
  },
}
