
import { mapGetters } from 'vuex'
import mixShrink from '~/ZenOne-StoryBook/mixins/shrink.js'
import { switchLocation } from '~/helpers/practiceLocation'

export default {
  name: 'HeaderLocation',
  components: {},
  mixins: [mixShrink],
  data() {
    return {
      showList: false
    }
  },
  computed: {
    ...mapGetters('user', ['user', 'practiceLocation']),
  },
  methods: {
    selectLocation(id) {
      switchLocation(id, this.$router, this.$store, this.$route.path)
    }
  }
}
