
import { mapGetters, mapActions } from 'vuex'
import NotificationAuthorizationAdminPortal from '~/components/NotificationAuthorizationAdminPortal'
import FullScreenLoader from '~/components/FullScreenLoader'
import Alert from '~/ZenOne-StoryBook/components/alert'
import TopBar from '~/components/TopBar'
import GitCurrentReleaseTag from '~/ZenOne-StoryBook/components/GitCurrentReleaseTag'
import RechargeSubscriptionBanner from '~/components/RechargeSubscriptionBanner'
import Header from '~/components/Header'
import storage from '~/ZenOne-StoryBook/mixins/localStorage'
import cart from '~/mixins/cart'
import route from '~/mixins/route'
import practiceLocation from '~/mixins/practiceLocation'
import notificationTop from '~/components/TopBar/mixins/notificationTop'
import { IMPERSONATE_USER_ID_LS_ALIAS } from '~/helpers/common'
import { getLocalStorage } from '~/ZenOne-StoryBook/helpers/localStorage'
import { isHavingProps } from '~/ZenOne-StoryBook/helpers/object'
import FadeFS from '~/components/FadeFS'
import UndoMoveFolderItemsButton from '~/components/Inventory/AlertActions/UndoMoveFolderItemsButton'
import { ALERT_DEFAULT_TIMEOUT } from '~/ZenOne-StoryBook/helpers/ui'

export default {
  name: 'LayoutDefault',
  components: {
    UndoMoveFolderItemsButton,
    FadeFS,
    GitCurrentReleaseTag,
    TopBar,
    RechargeSubscriptionBanner,
    Header,
    FullScreenLoader,
    Alert,
    NotificationAuthorizationAdminPortal,
  },
  mixins: [storage, cart, practiceLocation, route, notificationTop],
  data() {
    return {
      overlay: false,
      ALERT_DEFAULT_TIMEOUT,
    }
  },
  computed: {
    ...mapGetters({
      alert: 'ui/alert',
      practiceLocationVuex: 'user/practiceLocation',
      showFadeFS: `ui/showFadeFS`,
    }),
    routeName() {
      return `page__${this.$route.name}`
    },
    adminIsAuthorized() {
      return getLocalStorage(IMPERSONATE_USER_ID_LS_ALIAS) ?? false
    },
  },
  async mounted() {
    if (!isHavingProps(this.practiceLocationVuex)) {
      await this.SetUserPracticeLocation()
    }
  },
  methods: {
    ...mapActions('user', ['SetUserPracticeLocation']),
    closeAlert() {
      this.$store.commit('ui/HIDE_ALERT')
    },
  },
  watch: {
    '$route': {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.$store.dispatch('user/SetUserPracticeLocation')
        })
      }
    }
  }
}
