
import { mapGetters } from 'vuex'
import localStorage from '~/ZenOne-StoryBook/mixins/localStorage'
import CatalogFilters from '~/components/Catalog/CatalogFilters'
import FoldersNav from '~/components/Inventory/FoldersNav'

export default {
  name: 'LeftMenu',
  components: {
    FoldersNav,
    CatalogFilters,
  },
  mixins: [localStorage],
  props: {
    isCatalogRoute: {
      type: Boolean,
      default: false,
    },
    isInventoryRoute: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hover: false,
    }
  },
  computed: {
    ...mapGetters({
      rootCategoryList: 'catalog/rootCategoryList',
    }),
    show() {
      return this.isInventoryRoute || this.isCatalogRoute
    },
  },
}
