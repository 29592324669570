import { mapActions, mapGetters } from 'vuex'
import {
  RESPONSE_STATUS_CREATED,
  RESPONSE_STATUS_OK,
} from '~/ZenOne-StoryBook/helpers/response'
import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'
import { getSuccessMovingItemsMessage } from '~/components/Inventory/helper'
import {
  ACTION_TYPE_ADD,
  ACTION_TYPE_MOVE,
} from '~/components/Inventory/AlertActions/UndoMoveFolderItemsButton/helper'
import { ALERT_UNDO_TIMEOUT } from '~/ZenOne-StoryBook/helpers/ui'

export default {
  computed: {
    ...mapGetters({
      folders: 'inventory/folders',
    }),
  },
  methods: {
    ...mapActions({
      setLastMoveItemsData: 'inventory/setLastMoveItemsData',
    }),
    async addProductToFolder(
      productDistributorId = null,
      destinationFolderId = null
    ) {
      try {
        this.checkDestinationFolderId(destinationFolderId)
        if (!productDistributorId) {
          throw new Error('No product distributor id')
        }
        this.showFSLoader()
        const res = await this.addProductToFolderRequest(
          productDistributorId,
          destinationFolderId
        )
        if (res?.status !== RESPONSE_STATUS_CREATED) {
          throw new Error('Response error')
        }
        const workSpaceItem = res?.data?.workSpaceItem ?? {}
        const result = {
          workSpaceItem,
          productDistributorId,
        }
        this.setLastMoveItemsData({
          type: ACTION_TYPE_ADD,
          items: [
            {
              id: workSpaceItem?.id ?? null,
              originWorkspaceId: workSpaceItem?.work_space_id ?? null,
              productDistributorId,
            },
          ],
        })
        this.$emit('on-add-product-to-inventory', result)
        const content = getSuccessMovingItemsMessage(
          destinationFolderId,
          this.folders,
          1
        )
        this.showAlert({
          type: this.SUCCESS,
          content,
          actionComponent: 'UndoMoveFolderItemsButton',
          timeout: ALERT_UNDO_TIMEOUT,
        })
        return result
      } catch (e) {
        const error = e?.response?.data?.errors?.[0]?.detail ?? e
        console.error(error)
        return {
          workSpaceItem: {},
          productDistributorId,
        }
      } finally {
        this.hideFSLoader()
      }
    },
    async moveToFolder(
      workspaceItemIds = [],
      destinationFolderId = null,
      productDistributorId = null
    ) {
      try {
        this.checkDestinationFolderId(destinationFolderId)
        if (!isArrayWithLength(workspaceItemIds)) {
          throw new Error('No items selected')
        }
        this.showFSLoader()
        const res = await this.moveToFolderRequest(
          workspaceItemIds,
          destinationFolderId
        )
        if (res?.status !== RESPONSE_STATUS_OK) {
          throw new Error('Response error')
        }
        this.setLastMoveItemsData({
          type: ACTION_TYPE_MOVE,
          items: workspaceItemIds.map((id) => ({
            id,
          })),
        })
        this.$emit('on-move-items-to-folder', {
          workSpaceItem: res?.data?.workSpaceItem?.[0] ?? {},
          workspaceItemIds,
          productDistributorId,
          destinationFolderId,
          closeDialog: true,
        })
        const content = getSuccessMovingItemsMessage(
          destinationFolderId,
          this.folders,
          workspaceItemIds.length
        )
        this.showAlert({
          type: this.SUCCESS,
          content,
          actionComponent: 'UndoMoveFolderItemsButton',
          timeout: ALERT_UNDO_TIMEOUT,
        })
      } catch (e) {
        const error = `Add to folder request: ${
          e?.response?.data?.errors?.[0]?.detail ?? e
        }`
        console.error(error)
        this.showAlert({
          type: this.ERROR,
          content: error,
        })
      } finally {
        this.hideFSLoader()
      }
    },
    async addProductToFolderRequest(
      productDistributorId = null,
      destinationFolderId = null
    ) {
      return await this.$axios.post(
        `/workspaces/${destinationFolderId}/items`,
        {
          productDistributorId,
        }
      )
    },
    async moveToFolderRequest(
      workspaceItemIds = [],
      destinationWorkspaceId = null
    ) {
      return await this.$axios.patch(`/workspaces/items/move`, {
        workspaceItemIds,
        destinationWorkspaceId,
      })
    },
    checkDestinationFolderId(destinationFolderId) {
      if (!destinationFolderId) {
        throw new Error('No folder was selected')
      }
    },
  },
}
