export const SET_SEARCH_TERM = 'SET_SEARCH_TERM'
export const SET_MAKE_SEARCH_REQUEST_TRIGGER = 'SET_MAKE_SEARCH_REQUEST_TRIGGER'
export const SET_DROPDOWN_POSITION = 'SET_DROPDOWN_POSITION'
export const RESET_DROPDOWN_POSITION = 'RESET_DROPDOWN_POSITION'
export const SET_HISTORY = 'SET_HISTORY'
export const SET_MOST_RECENT_SUGGEST_STRING = 'SET_MOST_RECENT_SUGGEST_STRING'
export const SET_IS_LOADING_SUGGESTIONS = 'SET_IS_LOADING_SUGGESTIONS'
export const SET_CATEGORY_LIST = 'SET_CATEGORY_LIST'
export const SET_MODIFIER_VALUE_LIST = 'SET_MODIFIER_VALUE_LIST'
export const SET_CLICKED_ON_DELETE_HISTORY_ITEM =
  'SET_CLICKED_ON_DELETE_HISTORY_ITEM'
