import {
  AUTH_IMPERSONATE_USER_ID_ROUTE_NAME,
  EMAIL_CONFIRMATION_ID_ROUTE_NAME,
  WIZARD_ROUTE_NAME,
} from '~/helpers/route'
import {
  checkAddressIsFulfilled,
  completeWizard,
  isWizardCompleted,
} from '~/helpers/practiceLocation'
import { isGroupSubDomain } from '~/helpers/user'

export default async function({ $auth, route, redirect, store, $axios }) {
  try {
    const routeName = route?.name
    if (
      [
        WIZARD_ROUTE_NAME,
        AUTH_IMPERSONATE_USER_ID_ROUTE_NAME,
        EMAIL_CONFIRMATION_ID_ROUTE_NAME,
      ].includes(routeName)
    ) {
      return false
    }
    if (!$auth.loggedIn) {
      return false
    }
    const userAuth = $auth.user ?? {}
    if (userAuth.emailConfirmed && isWizardCompleted(userAuth.practiceLocation)) {
      if (isGroupSubDomain()) {
        redirect('https://my.zenone.com')
      }
      return false
    }
    if (!userAuth.emailConfirmed) {
      await redirect('/wizard')
      return false
    }
    const checked = await checkPracticeData(
      store,
      $axios,
      redirect,
      routeName,
      $auth
    )
    if (checked === false) {
      await redirect('/wizard')
    }
  } catch (e) {
    console.error(`Check wizard is complete: ${e}`)
  }
}

export const checkPracticeData = async (
  store,
  $axios,
  redirect,
  routeName,
  $auth
) => {
  try {
    const practiceLocation = $auth.user.practiceLocation
    if (isWizardCompleted(practiceLocation)) {
      return true
    }

    const distributorsIsValid = practiceLocation.distributors?.filter((el) => !el?.preferred).length > 0
    const practiceNameIsValid = !!practiceLocation.name
    const monthlyBudgetIsValid = practiceLocation.budget > 0
    const phoneNumberIsValid = !!practiceLocation.params?.phone
    const addressIsValid = checkAddressIsFulfilled(practiceLocation.address)
    const cardIsValid = practiceLocation.params?.card?.number
    const serviceAgreementAccepted = practiceLocation.serviceAgreement

    if (
      distributorsIsValid &&
      practiceNameIsValid &&
      monthlyBudgetIsValid &&
      phoneNumberIsValid &&
      addressIsValid &&
      cardIsValid &&
      serviceAgreementAccepted
    ) {
      if (await completeWizard($axios)) {
        return true
      }
    }

    return false
  } catch (e) {
    console.error(`checkPracticeData error: ${e}`)
    abortCheck(redirect, routeName, $auth)
  }
}

function abortCheck(redirect = null, routeName = '', $auth = {}) {
  $auth.logout('refresh')
  if (routeName !== 'login') redirect('/login')
  else return false
}
