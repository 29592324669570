export default {
  methods: {
    setCatalogFiltersQueryParams(filtersToken) {
      const queryParams = this.getQueryParamObjectFromFilters(filtersToken)

      setTimeout(async () => {
        await this.$router.push({
          query: { ...queryParams },
        })
      })
    },

    getQueryParamObjectFromFilters(catalogFilters) {
      const { filters, pageNumber, pageSize } = catalogFilters
      const queryParamObject = {}

      // exists and not default
      if (filters.fromOrderHistory) {
        queryParamObject.orderHistory = filters.fromOrderHistory
      }
      // exists and not default
      if (filters.distributorId && filters.distributorId.length) {
        queryParamObject.distr = filters.distributorId.join()
      }
      // exists and not default
      if (filters.manufacturerId && filters.manufacturerId.length) {
        queryParamObject.manuf = filters.manufacturerId.join()
      }
      // exists and not default
      if (filters.searchable) {
        queryParamObject.search = filters.searchable
      }
      // exists and not default
      if (filters.modifierValueId && filters.modifierValueId.length) {
        queryParamObject.modifier = filters.modifierValueId.join()
      }
      // exists and not default
      if (filters.grandparentId && filters.grandparentId.length) {
        queryParamObject.grandparentId = filters.grandparentId.join()
      }
      // exists and not default
      if (filters.parentId && filters.parentId.length) {
        queryParamObject.parentId = filters.parentId.join()
      }

      // exists and not default
      if (filters.categoryId && filters.categoryId.length) {
        queryParamObject.categoryId = filters.categoryId.join()
      }
      if (filters.packageQty && filters.packageQty.length) {
        queryParamObject.packageQty = filters.packageQty.join()
      }
      // exists and not default
      if (pageNumber && pageNumber !== 1) {
        queryParamObject.pg = pageNumber
      }
      // exists and not default
      if (pageSize && pageSize !== 35) {
        queryParamObject.ps = pageSize
      }

      return queryParamObject
    },
  },
}
