import mutations from './mutations'
import actions from './actions'
import { getLocalStorage } from '~/ZenOne-StoryBook/helpers/localStorage'
import { CONSUMER_PAYMENTS_ALIAS } from '~/helpers/common'
const subscription = {
  state: {
    showDialogPaymentInfoRecharge: false, // show add card dialog trigger in recharge notification panel outside
    isRetryBtnClicked:
      getLocalStorage(CONSUMER_PAYMENTS_ALIAS)?.subscription
        ?.isRetryBtnClicked || false,
    isRenewBtnClicked:
      getLocalStorage(CONSUMER_PAYMENTS_ALIAS)?.subscription
        ?.isRenewBtnClicked || false,
    isRenewDeclined:
      getLocalStorage(CONSUMER_PAYMENTS_ALIAS)?.subscription?.isRenewDeclined ||
      false,
  },
  getters: {
    showDialogPaymentInfoRecharge: (state) =>
      state.showDialogPaymentInfoRecharge,
    isRetryBtnClicked: (state) => state.isRetryBtnClicked,
    isRenewBtnClicked: (state) => state.isRenewBtnClicked,
    isRenewDeclined: (state) => state.isRenewDeclined,
  },
  actions,
  mutations,
  namespaced: true,
}

export default subscription
