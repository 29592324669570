export const SET_FOLDERS = 'SET_FOLDERS'
export const SET_FILTER_OPTIONS = 'SET_FILTER_OPTIONS'
export const SET_FOLDER_BY_ID = 'SET_FOLDER_BY_ID'
export const SET_ACTIVE_FOLDER = 'SET_ACTIVE_FOLDER'
export const SET_NEED_FETCH_ITEMS = 'SET_NEED_FETCH_ITEMS'

export const SET_SORT = 'SET_SORT'
export const RESET_SORT = 'RESET_SORT'

export const SET_CLEAR_FILTERS_TRIGGER = 'SET_CLEAR_FILTERS_TRIGGER'
export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export const SET_FILTERS = 'SET_FILTERS'
export const SET_FILTER = 'SET_FILTER'
export const SET_NEED_TO_CLEAR_SEARCH = 'SET_NEED_TO_CLEAR_SEARCH'

export const SET_IS_LOADING = 'SET_IS_LOADING'
export const SET_FOLDER_ITEMS = 'SET_FOLDER_ITEMS'
export const SET_FOLDER_ITEM_DATA_BY_INDEX = 'SET_FOLDER_ITEM_DATA_BY_INDEX'
export const SET_LAST_MOVE_ITEMS_DATA = 'SET_LAST_MOVE_ITEMS_DATA'
export const SET_WORKSPACE_ITEM_REMOVED_IDS = 'SET_WORKSPACE_ITEM_REMOVED_IDS'

export const SET_PAGINATION = 'SET_PAGINATION'
export const RESET_PAGINATION = 'RESET_PAGINATION'
export const SET_PAGINATION_PROP = 'SET_PAGINATION_PROP'

export const SET_SELECTED_LIST = 'SET_SELECTED_LIST'
export const SET_MOVED_ITEMS_DESTINATION_ID = 'SET_MOVED_ITEMS_DESTINATION_ID'
export const SWITCH_NEED_RERENDER_NAV_TREE = 'SWITCH_NEED_RERENDER_NAV_TREE'
export const SET_IS_BLOCKED_DRAG_NAV_TREE = 'SET_IS_BLOCKED_DRAG_NAV_TREE'

export const SET_IS_NAV_TREE_RESIZING = 'SET_IS_NAV_TREE_RESIZING'

export const SET_SHARED_FOLDER_ID_BY_ROUTE = 'SET_SHARED_FOLDER_ID_BY_ROUTE'

export const ADD_TO_QR_CODE_PRINT_QUEUE = 'ADD_TO_QR_CODE_PRINT_QUEUE'
export const CLEAR_QR_CODE_PRINT_QUEUE = 'CLEAR_QR_CODE_PRINT_QUEUE'
