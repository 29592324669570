export default function ({ store }) {
  store.app.router.afterEach((to, from, next) => {
    const { name: nameFrom = '' } = from
    const { name: nameTo = '' } = to
    if (nameFrom === 'index' && nameTo !== 'index') {
      store.commit('search/SET_SEARCH_TERM', '')
      store.commit('catalog/CLEAR_FILTERS', false)
    }
  })
}
