import * as types from './mutation-types'
import { initCheckRealTimePrices } from '~/helpers/checkPrices'
import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'

export default {
  async AddPrices(
    { commit, state },
    { productDistributorId = null, all = false, cart = false }
  ) {
    try {
      const checkingIds = await initCheckRealTimePrices(
        this.$axios,
        productDistributorId,
        all,
        cart
      )
      if (!isArrayWithLength(checkingIds)) return false
      commit(`prices/${types.ADD_PRICES}`, checkingIds, {
        root: true,
      })
    } catch (e) {
      console.error(`AddPrices vuex action: ${e}`)
    }
  },
  ResetPricesState({ commit }) {
    commit(types.CLEAR_PRICES)
    commit(types.SET_LAST_UPDATED_INDEX, null)
    commit(types.RESET_COUNTER)
    commit(types.RESET_TOTAL)
  },
}
