
import Vuex, { mapActions } from 'vuex'
import RechargeActions from './RechargeActions'
import ContentPastDue from './ContentPastDue'
import ContentCanceled from './ContentCanceled'
import ui from '~/ZenOne-StoryBook/mixins/ui'
import practiceLocation from '~/mixins/practiceLocation'
import subscriptionRetry from '~/mixins/subscriptionRetry'
import DialogPaymentInfo from '~/components/dialog/dialog-payment-info'
import { isNotUndefined } from '~/ZenOne-StoryBook/helpers/types'
import {
  PAYMENT_STATUS_DECLINED,
  PAYMENT_STATUS_PAID,
} from '~/ZenOne-StoryBook/helpers/payment'
const TIMEOUT_SUCCESS = 5000
export default {
  name: 'RechargeSubscriptionBanner',
  components: {
    RechargeActions,
    ContentPastDue,
    ContentCanceled,
    DialogPaymentInfo,
  },
  mixins: [ui, practiceLocation, subscriptionRetry],
  props: {
    isSettingsRoute: {
      type: Boolean,
      default: false,
    },
    isDetailProductCardRoute: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openDialogPaymentInfo: false,
      success: false,
    }
  },
  computed: {
    ...Vuex.mapGetters({
      showDialogPaymentInfoRecharge: `subscription/showDialogPaymentInfoRecharge`,
    }),
  },
  watch: {
    showDialogPaymentInfoRecharge() {
      this.showDialogPaymentInfo()
    },
    subscriptionPaymentNotificationList(list) {
      const lastPaymentStatus = list[list.length - 1]?.status
      if (isNotUndefined(lastPaymentStatus)) {
        const {
          isPracticeLocationPastDue = false,
          isPracticeLocationCanceled = false,
          isRenewDeclined = false,
        } = this
        if (lastPaymentStatus === PAYMENT_STATUS_PAID) {
          this.success = true
          setTimeout(this.SetUserPracticeLocation, TIMEOUT_SUCCESS)
          this.$store.commit('subscription/SET_IS_RETRY_BTN_CLICKED', false)
          this.$store.commit('subscription/SET_IS_RENEW_BTN_CLICKED', false)
          this.$store.commit('subscription/SET_IS_RENEW_DECLINED', false)
          this.$store.commit(
            'user/SET_PRACTICE_LOCATION_STATUS_WAS_UPDATED_ON_RECHARGE'
          )
        } else if (lastPaymentStatus === PAYMENT_STATUS_DECLINED) {
          if (isPracticeLocationPastDue) {
            this.$store.commit('subscription/SET_IS_RETRY_BTN_CLICKED', false)
          } else if (isPracticeLocationCanceled) {
            !isRenewDeclined
              ? this.$store.commit('subscription/SET_IS_RENEW_DECLINED', true)
              : this.$store.commit(
                  'subscription/SET_IS_RETRY_BTN_CLICKED',
                  false
                )
          }
        }
      }
    },
  },
  methods: {
    ...mapActions('user', ['SetUserPracticeLocation']),
    showDialogPaymentInfo() {
      this.openDialogPaymentInfo = true
    },
    closeDialogPaymentInfo() {
      this.openDialogPaymentInfo = false
    },
    async addCardSuccessfullyHandler() {
      try {
        await this.SetUserPracticeLocation()
        await this.rechargeSubscription(true)
        this.$store.commit('user/SET_CARD_WAS_UPDATED_ON_RECHARGE')
      } catch (e) {
        console.error('onAddCard', e)
        this.showAlert({
          type: 'error',
          content: 'The selected fail payment is invalid.',
        })
      }
    },
    addCardFailedHandler() {
      this.$store.commit('subscription/SET_IS_RETRY_BTN_CLICKED', false)
      this.$store.commit('subscription/SET_IS_RENEW_BTN_CLICKED', false)
      this.$store.commit('subscription/SET_IS_RENEW_DECLINED', true)
      this.showAlert({
        type: 'error',
        content: 'Add card was failed',
      })
    },
  },
}
