import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'
import {
  DETAIL_PRODUCT_CARD_ROUTE_NAME,
  INVENTORY_ROUTE_NAME,
} from '~/helpers/route'
import { getFolderIdFromRouteQuery } from '~/store/modules/inventory/helper'

export default async function ({ redirect, route, store, from }) {
  const routeName = route?.name ?? ''
  const prevRoute = from?.name ?? ''
  if (['inventory'].includes(routeName)) {
    redirect({ name: 'index' })
  }

  const folders = store.getters['inventory/folders']
  const isInventoryRoute = routeName === INVENTORY_ROUTE_NAME
  const prevRouteIsInventory = prevRoute === INVENTORY_ROUTE_NAME
  const prevRouteIsProductDetail = prevRoute === DETAIL_PRODUCT_CARD_ROUTE_NAME
  const isProductDetailRoute = routeName === DETAIL_PRODUCT_CARD_ROUTE_NAME

  if (isInventoryRoute) {
    const paginationFolderId = store.getters['inventory/paginationFolderId']
    const scrollPosition = store.getters['inventory/scrollPosition']
    const currentFolderId = getFolderIdFromRouteQuery(route)
    const hasScrollPosForAnotherFolder =
      paginationFolderId &&
      paginationFolderId !== currentFolderId &&
      scrollPosition > 0
    if (!prevRouteIsProductDetail || hasScrollPosForAnotherFolder) {
      store.commit('inventory/SET_NEED_TO_CLEAR_SEARCH')
      store.commit('inventory/CLEAR_FILTERS')
      store.commit('inventory/RESET_PAGINATION')
    }
  }

  if (!isInventoryRoute && !isProductDetailRoute && prevRouteIsInventory) {
    store.dispatch('inventory/ResetStateExceptFolders')
  }

  if (
    !isArrayWithLength(folders) ||
    (isInventoryRoute && !prevRouteIsInventory)
  ) {
    // get updated folders when going to inventory
    // from not inventory folder route
    await store.dispatch('inventory/SetFolders')
  }
}
