
import { mapActions, mapGetters, mapMutations } from 'vuex'
import FolderDialog from '../Dialogs/FolderDialog'
import FolderNavTextButton from './FolderNavButtonWithIcon'
import { isMovingParentFolderToChild, isRootFolderHasChildren } from './helper'
import ui from '~/ZenOne-StoryBook/mixins/ui'
import {
  FOLDER_TYPE,
  SUB_FOLDER_TYPE,
} from '~/components/Inventory/Dialogs/FolderDialog/helper'
import {
  RESPONSE_STATUS_NO_CONTENT,
  RESPONSE_STATUS_OK,
  RESPONSE_STATUS_TEAPOT,
} from '~/ZenOne-StoryBook/helpers/response'
import ConfirmDialog from '~/components/Dialogs/ConfirmDialog'
import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'
import { generateRandomString } from '~/ZenOne-StoryBook/helpers/random'
import request from '~/components/Inventory/mixins/request'
import { getSuccessMovingItemsMessage } from '~/components/Inventory/helper'
import folders from '~/components/Inventory/mixins/folders'
import { ACTION_TYPE_MOVE } from '~/components/Inventory/AlertActions/UndoMoveFolderItemsButton/helper'
import { ALERT_UNDO_TIMEOUT } from '~/ZenOne-StoryBook/helpers/ui'

export default {
  name: 'FoldersNav',
  components: {
    ConfirmDialog,
    FolderNavTextButton,
    FoldersNavTree: () =>
      import('~/components/Inventory/FoldersNav/FoldersNavTree/index.vue'),
    FolderDialog,
  },
  mixins: [ui, request, folders],
  data() {
    return {
      navTreeKey: generateRandomString(),
      foldersTree: [],
    }
  },
  computed: {
    ...mapGetters({
      items: `inventory/foldersTree`,
      folders: `inventory/folders`,
      folder: 'inventory/folder',
      rootFolderId: `inventory/rootFolderId`,
      currentFolderIsRoot: 'inventory/currentFolderIsRoot',
      folderId: `inventory/folderId`,
      needRerenderNavTree: `inventory/needRerenderNavTree`,
      isNavTreeResizing: `inventory/isNavTreeResizing`,
    }),
  },
  watch: {
    items: {
      immediate: true,
      handler(items) {
        if (isArrayWithLength(items)) {
          this.foldersTree = [...items]
        }
      },
    },
    needRerenderNavTree: {
      handler() {
        this.updateNavTreeKey()
      },
    },
  },
  methods: {
    ...mapActions({
      SetFolders: 'inventory/SetFolders',
      setLastMoveItemsData: 'inventory/setLastMoveItemsData',
    }),
    ...mapMutations({
      setFolders: 'inventory/SET_FOLDERS',
      setMovedItemsDestinationId: 'inventory/SET_MOVED_ITEMS_DESTINATION_ID',
    }),
    showCreateDialog({ parentFolderId = null, entityType = '' }) {
      this.$refs?.folderDialog.show({ folder: {}, parentFolderId, entityType })
    },
    createFolderBtnClickHandler() {
      this.showCreateDialog({
        parentFolderId: this.rootFolderId,
        entityType: FOLDER_TYPE,
      })
    },
    createSubFolder(parentFolderId = null) {
      this.showCreateDialog({ parentFolderId, entityType: SUB_FOLDER_TYPE })
    },
    async editFolder(folder = {}) {
      this.showFSLoader()
      const editFolder = await this.$store.dispatch(
        'inventory/GetFolderById',
        folder.id
      )
      this.hideFSLoader()
      this.$refs?.folderDialog.show({ folder: { ...editFolder } })
    },
    async deleteFolder(folder = {}) {
      await this.deleteFolderRequest({ folder })
    },
    async deleteFolderRequest({ folder = {}, force = false }) {
      try {
        const folderId = folder?.id ?? null
        if (!folderId) {
          throw new Error(`No folder id`)
        }

        this.showFSLoader()
        const res = await this.$axios.delete(
          `workspaces/${folderId}${force ? '?force=1' : ''}`
        )
        const status = res?.status ?? ''
        if (RESPONSE_STATUS_NO_CONTENT !== status)
          throw new Error(`Server response status ${res?.status}`)
        await this.$store.dispatch('inventory/SetFolders')
        if (this.folderId === folderId) {
          await this.$router.push(`/inventory/${this.rootFolderId}`)
        } else {
          await this.$store.dispatch('inventory/SetFolderById', this.folderId)
          await this.$store.commit('inventory/SET_NEED_FETCH_ITEMS')
        }
        this.updateNavTreeKey()
        this.hideFSLoader()
      } catch (e) {
        this.hideFSLoader()
        const needShowConfirm = e?.response?.status === RESPONSE_STATUS_TEAPOT
        if (needShowConfirm) {
          const isConfirmed = await this.$refs.confirmDeleteFolderDialog.open({
            title: 'Are you sure?',
            message: `${folder.name} folder contains products with QTYs on Hand. If you proceed to delete the folder you will loose all QTYs on Hand.`,
            buttonText: {
              ok: 'Delete',
              cancel: 'Cancel',
            },
            okColor: 'error',
          })
          if (isConfirmed) {
            await this.deleteFolderRequest({ folder, force: true })
            this.updateNavTreeKey()
          }
        } else {
          const error = `Can't delete folder: ${
            e?.response?.data?.errors?.[0]?.detail ?? e
          }`
          console.error(error)
          this.showAlert({
            type: this.ERROR,
            content: error,
          })
        }
      }
    },
    async updateFolder({ folderId = null, data = {} }) {
      try {
        const res = await this.$axios.patch(`/workspaces/${folderId}`, {
          ...data,
        })
        if (res?.status !== RESPONSE_STATUS_OK) {
          throw new Error('Server response issue')
        }
      } catch (e) {
        const error = `Can't update folder: ${
          e?.response?.data?.errors?.[0]?.detail ?? e
        }`
        console.error(error)
      }
    },
    async updateFolders(data = []) {
      const foldersBefore = [...this.folders]
      try {
        const folders = [...this.folders]
        for (const f of data) {
          const index = folders.findIndex(
            (item) => item.id === parseInt(f.workspaceId)
          )
          if (index === -1) return false
          const attributes = {}
          if (f.parent_id) {
            attributes.parent_id = f.parent_id
          }
          if (f.rank) {
            attributes.rank = f.rank
          }
          const folder = { ...folders[index], ...attributes }
          folders[index] = { ...folder }
        }

        this.setFolders(folders)
        this.updateNavTreeKey()
        const res = await this.$axios.patch(`/workspaces/update`, {
          workspaces: data,
        })
        if (res?.status !== RESPONSE_STATUS_OK) {
          throw new Error('Server response issue')
        }
      } catch (e) {
        this.setFolders(foldersBefore)
        this.updateNavTreeKey()
        const error = `Can't update folders: ${
          e?.response?.data?.errors?.[0]?.detail ?? e
        }`
        console.error(error)
      }
    },
    updateNavTreeKey() {
      this.navTreeKey = generateRandomString()
    },
    async moveFolder({ itemId = null, destinationId = null }) {
      if (!destinationId) return false
      const folderTree = [...this.items]
      if (!isRootFolderHasChildren(folderTree)) return false
      // eslint-disable-next-line camelcase
      const parent_id = parseInt(destinationId)
      if (isMovingParentFolderToChild(itemId, parent_id, folderTree)) {
        return false
      }
      const folders = [...this.folders]
      const index = folders.findIndex((item) => item.id === parseInt(itemId))
      if (index === -1) return false
      const folder = { ...folders[index], parent_id }
      folders[index] = { ...folder }
      this.setFolders(folders)
      this.updateNavTreeKey()
      await this.updateFolder({ folderId: itemId, data: { parent_id } })
    },
    async moveItemsToFolder(
      workspaceItemIds = [],
      destinationWorkspaceId = null
    ) {
      try {
        this.showFSLoader()
        const res = await this.moveToFolderRequest(
          workspaceItemIds,
          destinationWorkspaceId
        )
        if (res?.status !== RESPONSE_STATUS_OK) {
          throw new Error('Server response issue')
        }
        this.setLastMoveItemsData({
          type: ACTION_TYPE_MOVE,
          items: workspaceItemIds.map((id) => ({
            id,
          })),
        })
        this.updateFolderItemsAfterMoving({
          destinationFolderId: destinationWorkspaceId,
          workspaceItemIds,
        })
        this.updateFolderCountsAfterMoving({
          destinationFolderId: destinationWorkspaceId,
          movedItemsCount: workspaceItemIds.length,
        })
        this.setMovedItemsDestinationId({ folderId: destinationWorkspaceId })
        const content = getSuccessMovingItemsMessage(
          destinationWorkspaceId,
          this.folders,
          workspaceItemIds.length
        )
        this.showAlert({
          type: this.SUCCESS,
          content,
          actionComponent: 'UndoMoveFolderItemsButton',
          timeout: ALERT_UNDO_TIMEOUT,
        })
      } catch (e) {
        const error = `Move items to folder error: ${
          e?.response?.data?.errors?.[0]?.detail ?? e
        }`
        console.error(error)
        this.showAlert({
          type: this.ERROR,
          content: error,
        })
      } finally {
        this.hideFSLoader()
      }
    },
  },
}
