export const units = [
  {
    value: 'PK',
    name: 'Package',
  },
  {
    value: 'CA',
    name: 'Case',
  },
  {
    value: 'KT',
    name: 'Kit',
  },
]

export const unitsExtended = [
  {
    value: 'AM',
    name: 'Ampule',
  },
  {
    value: 'BG',
    name: 'Bag',
  },
  {
    value: 'BK',
    name: 'Bucket',
  },
  {
    value: 'BL',
    name: 'Barrels',
  },
  {
    value: 'BO',
    name: 'Book',
  },
  {
    value: 'BT',
    name: 'Bottles',
  },
  {
    value: 'BX',
    name: 'Box',
  },
  {
    value: 'CA',
    name: 'Case',
  },
  {
    value: 'CC',
    name: 'Cubic Centimeter',
  },
  {
    value: 'CF',
    name: 'Hundred Feet',
  },
  {
    value: 'CM',
    name: 'Centimeters',
  },
  {
    value: 'CN',
    name: 'Can',
  },
  {
    value: 'CR',
    name: 'Carton',
  },
  {
    value: 'CS',
    name: 'Hundred Square Feet',
  },
  {
    value: 'CT',
    name: 'Carat',
  },
  {
    value: 'DR',
    name: 'Drums',
  },
  {
    value: 'DS',
    name: 'Dose',
  },
  {
    value: 'DW',
    name: 'Pennyweight',
  },
  {
    value: 'DZ',
    name: 'Dozen',
  },
  {
    value: 'EA',
    name: 'Each',
  },
  {
    value: 'FT',
    name: 'Feet',
  },
  {
    value: 'GA',
    name: 'Gallon',
  },
  {
    value: 'GM',
    name: 'Grams',
  },
  {
    value: 'GR',
    name: 'Gross',
  },
  {
    value: 'HE',
    name: 'Head Count',
  },
  {
    value: 'IP',
    name: 'InnerPack',
  },
  {
    value: 'JR',
    name: 'Jar',
  },
  {
    value: 'KG',
    name: 'Kilogram',
  },
  {
    value: 'KT',
    name: 'Kit',
  },
  {
    value: 'LB',
    name: 'Pounds',
  },
  {
    value: 'LT',
    name: 'Liters',
  },
  {
    value: 'MC',
    name: 'Master Case',
  },
  {
    value: 'MG',
    name: 'Milligrams',
  },
  {
    value: 'MH',
    name: 'Man Hour',
  },
  {
    value: 'ML',
    name: 'Milliliter',
  },
  {
    value: 'OT',
    name: 'Troy Ounces',
  },
  {
    value: 'OZ',
    name: 'Ounces',
  },
  {
    value: 'PC',
    name: 'Pieces',
  },
  {
    value: 'PK',
    name: 'Package',
  },
  {
    value: 'PL',
    name: 'Pallet',
  },
  {
    value: 'PR',
    name: 'Pair',
  },
  {
    value: 'RL',
    name: 'Roll',
  },
  {
    value: 'SL',
    name: 'Sleeve',
  },
  {
    value: 'SO',
    name: 'Spool',
  },
  {
    value: 'ST',
    name: 'Set',
  },
  {
    value: 'TB',
    name: 'Tube',
  },
  {
    value: 'VL',
    name: 'Vial',
  },
  {
    value: 'YD',
    name: 'Yard',
  },
]
