import { isNotUndefined } from './types'

export const formatPrice = (value) => {
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const parseFloat2Digits = (item) => {
  if (item === null || item === 0 || item === '' || item === '0') return ''
  if (item >= 10000) return formatPrice(item)
  return parseFloat(item).toFixed(2)
}

export const toNumber = (value) =>
  value !== null && isNotUndefined(value) ? parseInt(value) : null
