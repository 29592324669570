
import { mapGetters } from 'vuex'
import ConnectFailedIcon from './ConnectFailedIcon'
import {
  FAIL_REASON_INTERNAL_ERROR,
  FAIL_REASON_EXTERNAL_ERROR,
  FAIL_REASON_WRONG_LOGIN,
  FAIL_REASON_NEED_VERIFICATION,
  STATUS_FAILED,
} from '~/helpers/connect-distributors'
import { isArrayAndHaveLength } from '~/ZenOne-StoryBook/helpers/array'

export default {
  name: 'TopMenuMyDistributors',
  components: { ConnectFailedIcon },
  data() {
    return {
      isLoading: true,
      practiceLocationDistributors: [],
    }
  },
  computed: {
    ...mapGetters({
      practiceLocation: 'user/practiceLocation',
    }),
    showConnectFailedIcon() {
      if (this.isLoading) return false
      let failedList = []
      const practiceLocationDistributors = this.practiceLocation?.distributors
      if (!isArrayAndHaveLength(practiceLocationDistributors)) return false
      const failedItems = practiceLocationDistributors.filter(
        ({ status }) => status === STATUS_FAILED
      )
      if (isArrayAndHaveLength(failedItems)) {
        failedList = failedItems.filter(({ failReason }) =>
          [
            null,
            FAIL_REASON_WRONG_LOGIN,
            FAIL_REASON_INTERNAL_ERROR,
            FAIL_REASON_EXTERNAL_ERROR,
            FAIL_REASON_NEED_VERIFICATION,
          ].includes(failReason)
        )
      }
      return !!failedList.length
    },
  },
  methods: {
  },
}
