import actions from './actions'
import mutations from './mutations'

const socket = {
  state: {
    prices: [], // product distributor prices for update
    lastUpdatedIndex: null,
    counter: 0, // current number of prices updates
    total: 0, // initial number of prices to update
  },
  getters: {
    prices: (state) => state.prices,
    lastUpdatedIndex: (state) => state.lastUpdatedIndex,
    counter: (state) => state.counter,
    total: (state) => state.total,
  },
  mutations,
  actions,
  namespaced: true,
}

export default socket
