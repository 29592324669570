import * as types from './mutation-types'
import { collectSuccessLoginResultNotification } from '~/helpers/connect-distributors'

export default {
  setDistributor({ commit, dispatch }, distributor) {
    commit(types.SET_DISTRIBUTOR, distributor)
    const successNotification =
      collectSuccessLoginResultNotification(distributor)
    commit('socket/ADD_NOTIFICATION', successNotification, {
      root: true,
    })
  },
}
