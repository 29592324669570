import moment from 'moment'

export const mapByOrderFilterOptions = (list = []) => {
  return list.map((o) => {
    const id = o?.id ? parseInt(o.id) : null
    const createdAt = o?.createdAt ?? null
    return {
      id,
      name: `#0${id} ordered on ${
        createdAt ? moment.utc(createdAt).format('MM/DD/YYYY') : 'N/A'
      }`,
    }
  })
}
