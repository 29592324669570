
import { mapGetters } from 'vuex'
import HistoryMenuItem from './HistoryMenuItem'
import { generateRandomString } from '~/ZenOne-StoryBook/helpers/random'
import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'
export default {
  name: 'HistoryMenu',
  components: { HistoryMenuItem },
  data() {
    return {
      generateRandomString,
    }
  },
  computed: {
    ...mapGetters({
      searchHistoryList: 'search/history',
    }),
    showNoHistoryMsg() {
      return !isArrayWithLength(this.searchHistoryList)
    },
  },
  methods: {
    onSearchInputFocus() {
      this.$emit('on-search-input-focus')
    },
  },
}
