import { isObject } from 'lodash'
import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'
import { isHavingProps } from '~/ZenOne-StoryBook/helpers/object'
import { getPriceTypeName } from '~/components/Catalog/ProductCardDetail/helper'

export const createNewPriceItemById = (id = null) => {
  return {
    productDistributorId: id,
    stockLevel: null,
    distributorPrice: {
      actualPrice: {
        type: '',
        price: null,
        typeName: '',
        lastCheckedAt: null,
        hasSocketMessage: false,
      },
    },
  }
}

export const findPriceIndexById = (priceList = [], id = null) => {
  if (!isArrayWithLength(priceList) || !id) return -1
  return priceList.findIndex(
    (price) => parseInt(price.productDistributorId) === parseInt(id)
  )
}

export const addMappedPriceByTypeToOriginList = (
  originList = {},
  type = '',
  hasPriceUpdateError = false
) => {
  const price = originList?.[type] ?? {}
  if (!price || !isObject(price) || !isHavingProps(price)) return originList
  if (type === 'actualPrice') {
    price.hasPriceUpdateError = hasPriceUpdateError
    price.hasSocketMessage = true
  }
  price.typeName = getPriceTypeName(price?.type)
  return {
    ...originList,
    [type]: price,
  }
}
