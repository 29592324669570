const middleware = {}

middleware['authCustom'] = require('../middleware/authCustom.js')
middleware['authCustom'] = middleware['authCustom'].default || middleware['authCustom']

middleware['authedRedirects'] = require('../middleware/authedRedirects.js')
middleware['authedRedirects'] = middleware['authedRedirects'].default || middleware['authedRedirects']

middleware['checkDistributorLogin'] = require('../middleware/checkDistributorLogin.js')
middleware['checkDistributorLogin'] = middleware['checkDistributorLogin'].default || middleware['checkDistributorLogin']

middleware['checkIsWizardCompleted'] = require('../middleware/checkIsWizardCompleted.js')
middleware['checkIsWizardCompleted'] = middleware['checkIsWizardCompleted'].default || middleware['checkIsWizardCompleted']

middleware['checkPrices'] = require('../middleware/checkPrices.js')
middleware['checkPrices'] = middleware['checkPrices'].default || middleware['checkPrices']

middleware['impersonateUser'] = require('../middleware/impersonateUser.js')
middleware['impersonateUser'] = middleware['impersonateUser'].default || middleware['impersonateUser']

middleware['inventory'] = require('../middleware/inventory.js')
middleware['inventory'] = middleware['inventory'].default || middleware['inventory']

middleware['payments'] = require('../middleware/payments.js')
middleware['payments'] = middleware['payments'].default || middleware['payments']

middleware['productDetail'] = require('../middleware/productDetail.js')
middleware['productDetail'] = middleware['productDetail'].default || middleware['productDetail']

middleware['search'] = require('../middleware/search.js')
middleware['search'] = middleware['search'].default || middleware['search']

middleware['vuexModules'] = require('../middleware/vuexModules.js')
middleware['vuexModules'] = middleware['vuexModules'].default || middleware['vuexModules']

export default middleware
