
import { mapActions, mapGetters } from 'vuex'
import ui from '~/ZenOne-StoryBook/mixins/ui'
import { isArrayWithLength, isNull } from '~/ZenOne-StoryBook/helpers/types'
import {
  ACTION_TYPE_ADD,
  ACTION_TYPE_MOVE,
  getProductDistributorUpdatedData,
  groupUndoResultByWorkspaceId,
  removedFromWorkspaceProductAttributes,
} from '~/components/Inventory/AlertActions/UndoMoveFolderItemsButton/helper'
import updateProductDistributor from '~/mixins/updateProductDistributor'
import route from '~/mixins/route'
import folders from '~/components/Inventory/mixins/folders'
import { isObjectNotUndefinedHasProps } from '~/ZenOne-StoryBook/helpers/object'

export default {
  name: 'UndoMoveFolderItemsButton',
  mixins: [ui, updateProductDistributor, route, folders],
  computed: {
    ...mapGetters({
      lastMoveItemsData: 'inventory/lastMoveItemsData',
      currentFolderIsRoot: 'inventory/currentFolderIsRoot',
      folderId: 'inventory/folderId',
    }),
  },
  methods: {
    ...mapActions({
      clearLastMoveItemsData: 'inventory/setLastMoveItemsData',
      deleteFolderItemsBatch: 'inventory/deleteFolderItemsBatch',
      setWorkspaceItemRemovedIds: 'inventory/setWorkspaceItemRemovedIds',
      setFolders: 'inventory/SetFolders',
      SetFolderItems: 'inventory/SetFolderItems',
      moveBackItems: 'inventory/moveBackItems',
      setGeneralProductWorkspace: 'catalog/setGeneralProductWorkspace',
    }),
    async clickHandler() {
      const { lastMoveItemsData = {} } = this
      this.clearLastMoveItemsData()
      this.hideAlert()
      const type = lastMoveItemsData?.type ?? null
      const items = lastMoveItemsData?.items ?? []
      if (isNull(type) || !isArrayWithLength(items)) return false
      try {
        switch (type) {
          case ACTION_TYPE_ADD: {
            for (const item of items) {
              await this.deleteFolderItemsBatch({
                selectedList: [item?.id],
                folderId: item?.originWorkspaceId,
                force: true,
              })
              this.updateProductDistributorInTops(
                item?.productDistributorId,
                removedFromWorkspaceProductAttributes
              )
            }
            this.setWorkspaceItemRemovedIds(
              items.map((i) => i?.productDistributorId)
            )
            await this.setFolders()
            break
          }
          case ACTION_TYPE_MOVE:
            await this.moveItemsBack(items.map((i) => i?.id))
            break
          default:
            break
        }
      } catch (e) {
        console.error(`Undo move items: ${e}`)
      }
    },
    async moveItemsBack(workspaceItemIds = []) {
      if (!isArrayWithLength(workspaceItemIds))
        throw new Error('No items to bring them back')
      const res = await this.moveBackItems(workspaceItemIds)
      if (!isArrayWithLength(res)) return false

      if (this.isInventoryRoute) {
        if (this.currentFolderIsRoot) {
          const groupedByWorkspaceId = groupUndoResultByWorkspaceId(res)
          if (!isObjectNotUndefinedHasProps(groupedByWorkspaceId)) return false
          for (const workspaceId in groupedByWorkspaceId) {
            this.updateFolderItemsAfterMoving({
              destinationFolderId: workspaceId,
              workspaceItemIds: groupedByWorkspaceId[workspaceId],
            })
          }
        } else {
          await Promise.all([
            this.SetFolderItems({
              needClearList: true,
              useLoader: false,
              saveScrollPos: true,
            }),
            this.setFolders(),
          ])
          this.SetActiveFolder(this.folderId)
        }
        return false
      }

      if (!this.isDetailProductCardRoute && !this.isCatalogRoute) return false
      this.setGeneralProductWorkspace({
        value: getProductDistributorUpdatedData(res[0]),
      })
    },
  },
}
