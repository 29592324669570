
export default {
  name: 'Tooltip',
  props: {
    right: {
      type: Boolean,
      default: true,
    },
    left: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
    titleText: {
      type: String,
      default: '',
    },
    iconSize: {
      type: String,
      default: '15',
    },
    maxWidth: {
      type: Number,
      default: 300,
    },
    isTitleFirst: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: null,
    },
  },
}
