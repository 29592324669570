import { API } from '../ZenOne-StoryBook/config/api'
import { headers } from './auth'
export const getBaseUrl = (nodeEnv) => process.env.BASE_URL

export const api = { ...API }

export const hostNameProdTidioChat =
  process.env.NODE_ENV === 'production'
    ? '//code.tidio.co/e2tldory6azpr7do2lo2vbkkz1cilzrm.js'
    : ''

export const getJsonApiHeaders = () => {
  return { headers }
}
