
import Vuex from 'vuex'
import Alert from '~/ZenOne-StoryBook/components/alert'
import FullScreenLoader from '~/components/FullScreenLoader'
import Logo from '~/components/logo/index.vue'

export default {
  name: 'LayoutRegistration',
  components: {
    Logo,
    Alert,
    FullScreenLoader,
  },
  computed: {
    ...Vuex.mapGetters({
      alert: 'ui/alert',
    }),
  },
  methods: {
    closeAlert() {
      this.$store.commit('ui/HIDE_ALERT')
    },
  },
}
