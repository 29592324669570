
import logo from '~/components/img/logo2.svg'
import { groupLogo, isGroupSubDomain } from '~/helpers/user'

export default {
  methods: { groupLogo, isGroupSubDomain },
  components: {},
  mixins: [],
  data: () => ({
    logo,
  }),
  computed: {},
  watch: {},
  created() {},
  mounted() {},
}
