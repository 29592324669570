import * as types from './mutation-types'

export default {
  [types.SET_DISTRIBUTOR](state, payload) {
    state.distributor = payload
  },
  [types.SET_CONNECT_FORM](state, payload) {
    const { distributorId = null } = payload
    state.connectForm = {
      distributorId,
    }
  },
  [types.SET_SEARCH_TERM](state, term) {
    state.searchTerm = term
  },
}
