
import PastDueRetryTitle from '~/components/Subscription/PastDue/PastDueRetryTitle'
export default {
  name: 'ContentPastDue',
  components: { PastDueRetryTitle },
  props: {
    success: {
      type: Boolean,
      default: false,
    },
    cardNumber: {
      type: String,
      default: '',
    },
  },
}
