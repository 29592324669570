
import { mapGetters } from 'vuex'
import mixShrink from '~/ZenOne-StoryBook/mixins/shrink.js'
export default {
  components: {},
  mixins: [mixShrink],
  computed: {
    ...mapGetters('user', ['user']),
    isDoctor() {
      return this.user?.practiceLocationRole === 'doctor'
    },
    name() {
      return this.shrink(this.user.name, this.isDoctor ? 6 : 9)
    }
  },
}
