import Vuex, { mapActions } from 'vuex'
import practiceLocation from '~/mixins/practiceLocation'

export default {
  data() {
    return {}
  },
  mixins: [practiceLocation],
  computed: {
    ...Vuex.mapGetters({
      subscriptionPaymentNotificationList: 'socket/subscriptionPayment',
      isRetryBtnClicked: 'subscription/isRetryBtnClicked',
      isRenewBtnClicked: 'subscription/isRenewBtnClicked',
      isRenewDeclined: 'subscription/isRenewDeclined',
    }),
    isRetryBtnLoading() {
      const { isRetryBtnClicked = false } = this
      return isRetryBtnClicked
    },
    isUpdateBtnLoading() {
      const { isRetryBtnClicked = false } = this
      return isRetryBtnClicked
    },
    isRenewBtnLoading() {
      const { isRenewBtnClicked = false } = this
      return isRenewBtnClicked
    },
    creditCardActionBtnText() {
      return this.cardNumber ? 'Update Credit Card' : 'Add Credit Card'
    },
  },
  methods: {
    ...mapActions('user', ['SetUserPracticeLocation']),
    async retryBtnClickHandler() {
      await this.rechargeSubscription()
    },
    async renewBtnClickHandler() {
      await this.rechargeSubscription(true)
    },
  },
}
