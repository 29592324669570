import { isObject } from 'lodash'
import { isHavingProps } from '../helpers/object'

export const mapLaravelPagination = (meta = {}) => {
  if (!meta || !isObject(meta) || !isHavingProps(meta)) {
    return {
      currentPage: 1,
      from: 1,
      lastPage: 1,
      perPage: 20,
    }
  }
  return {
    currentPage: meta?.current_page ?? 1,
    from: meta?.from ?? 1,
    lastPage: meta?.last_page ?? 1,
    perPage: meta?.per_page ?? 20,
    to: meta?.to ?? 1,
    total: meta?.total ?? 1,
  }
}
