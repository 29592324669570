import {
  AUTH_IMPERSONATE_USER_ID_ROUTE_NAME,
  LOGIN_ROUTE_NAME,
  REGISTRATION_ROUTE_NAME,
} from '~/helpers/route'
import {
  IMPERSONATE_USER_ID_LS_ALIAS,
} from '~/helpers/common'
import {
  clearLocalStorage,
  setLocalStorage,
} from '~/ZenOne-StoryBook/helpers/localStorage'

export default async function ({
  route = {},
  $auth = {},
  redirect = () => {},
  $socket = {},
}) {
  const routeName = route?.name ?? ''
  if (routeName === REGISTRATION_ROUTE_NAME) {
    clearLocalStorage()
  } else if (routeName === AUTH_IMPERSONATE_USER_ID_ROUTE_NAME) {
    const impersonateUserId = route?.params?.id ?? null
    if (impersonateUserId) {
      setLocalStorage({
        name: IMPERSONATE_USER_ID_LS_ALIAS,
        data: parseInt(impersonateUserId),
      })
      if (!$auth?.loggedIn) {
        redirect(`/${LOGIN_ROUTE_NAME}`)
        return false
      } else {
        $socket.init(impersonateUserId)
        await $auth.fetchUser()
        redirect('/')
      }
    }
  }
}
