
import ColumnsSectionTitle from '../ColumnsSectionTitle'
export default {
  name: 'ColumnsSection',
  components: { ColumnsSectionTitle },
  props: {
    titleText: {
      type: String,
      default: '',
      required: true,
    },
  },
}
