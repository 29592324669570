
import { mapActions, mapGetters, mapMutations } from 'vuex'
import logoSmall from './logo-small'
import TopMenuSearchBarCatalog from './TopMenuSearchBarCatalog'
import TopMenuSearchBarInventory from './TopMenuSearchBarInventory'
import TopMenuSearchBarDistributors from './TopMenuSearchBarDistributors'
import TopMenuBudget from './TopMenuBudget'
import TopMenuMyDistributors from './TopMenuMyDistributors'
import account from './account'
import TopMenuCart from './TopMenuCart'
import manageOrders from './manage-orders'
import localStorage from '~/ZenOne-StoryBook/mixins/localStorage'
import route from '~/mixins/route'
import HeaderLocation from '~/components/Header/Location/index.vue'

export default {
  name: 'Header',
  components: {
    HeaderLocation,
    // todo make some components functional
    logoSmall,
    TopMenuSearchBarCatalog,
    TopMenuSearchBarInventory,
    TopMenuSearchBarDistributors,
    TopMenuBudget,
    TopMenuMyDistributors,
    manageOrders,
    account,
    TopMenuCart,
  },
  mixins: [localStorage, route],
  props: {
    showFullScreenLoader: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      budgetCurrent: 'user/budgetCurrent',
      user: 'user/user'
    }),
    searchBarComponent() {
      if (this.isMyDistributorsRoute) {
        return 'TopMenuSearchBarDistributors'
      }
      if (this.isInventoryRoute) {
        return 'TopMenuSearchBarInventory'
      }
      return 'TopMenuSearchBarCatalog'
    },
    isMultiLocations() {
      return this.user?.practiceLocations?.length > 1
    }
  },
  methods: {
    ...mapActions('catalog', ['ClearFilters']),
    ...mapMutations('search', {
      setSearchTerm: 'SET_SEARCH_TERM',
    }),
    logoBtnClickHandler() {
      this.setSearchTerm('')
      this.ClearFilters()
      this.$router.push('/')
    },
  },
}
