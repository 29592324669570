import _ from 'lodash'
import { isHavingProps } from '~/ZenOne-StoryBook/helpers/object'
import { isArrayAndHaveLength } from '~/ZenOne-StoryBook/helpers/array'

export const mapSuggestListForCategories = (data) => {
  if (data && isHavingProps(data)) {
    const { category_key: categoryKey = {}, category_tree: categoryTree = {} } =
      data
    const rootList = []
    const subcategoryList = []
    const genericNameList = []
    for (const categoryId in categoryTree) {
      const category = categoryTree[categoryId]
      if (category && isHavingProps(category)) {
        const { level = 0 } = category
        if (level) {
          if (level === 1) rootList.push(category)
          else if (category.level === 2) subcategoryList.push(category)
          else if (category.level === 3) genericNameList.push(category)
        }
      }
    }
    if (!rootList.length || !subcategoryList.length) return false
    const list = []
    const genericNameParentIdList = []
    const genericNameParentList = {}
    Object.keys(categoryKey).forEach((suggestCategoryId) => {
      const genericName = _.find(
        genericNameList,
        (generic) =>
          generic.category_id.toString() === suggestCategoryId.toString()
      )
      if (genericName && isHavingProps(genericName)) {
        list.push(genericName)
        const subcategoryId = genericName.parent_category_id.toString()
        if (subcategoryId && !genericNameParentIdList.includes(subcategoryId))
          genericNameParentIdList.push(subcategoryId)
        const subcategory = _.find(
          subcategoryList,
          (subcategory) => subcategory.category_id.toString() === subcategoryId
        )
        subcategory.rootCategory = _.find(
          rootList,
          (category) =>
            category.category_id.toString() ===
            subcategory.parent_category_id.toString()
        )
        genericNameParentList[subcategoryId] = subcategory
      }
    })
    Object.keys(genericNameParentList).forEach((subcategoryId) => {
      list.forEach((genericName) => {
        if (genericName.parent_category_id.toString() === subcategoryId) {
          const subcategoryGenericNameList =
            genericNameParentList?.[subcategoryId]?.genericNameList
          if (isArrayAndHaveLength(subcategoryGenericNameList)) {
            subcategoryGenericNameList.push(genericName)
            genericNameParentList[subcategoryId].genericNameList =
              subcategoryGenericNameList
          } else
            genericNameParentList[subcategoryId].genericNameList = [genericName]
        }
      })
    })
    return genericNameParentList
  }
  return []
}
