import * as cols from './baseColumnsName'
export const DEFAULT_COLUMN = 'default'
export const OPTIONAL_COLUMN = 'optional'

export const BASE_COLUMNS_LIST = [
  cols.PRODUCT_IMAGE,
  cols.PRODUCT_NAME,
  cols.PRODUCT_DESCRIPTION,
  cols.PRODUCT_CATEGORY,
  cols.MANUFACTURER,
  cols.DISTRIBUTOR_AND_SKU,
  cols.PRICE,
  cols.LAST_ORDERED,
  cols.REORDER,
  cols.NOTE,
  cols.FOLDER,
  cols.QTY_NEED_TO_HAVE,
  cols.QTY_ON_HAND,
  cols.REORDER_WHEN_LTE,
  cols.EXPIRATION_DATE,
]

export const columnsListDefault = [
  {
    title: 'Image',
    name: cols.PRODUCT_IMAGE,
    visible: true,
    width: '60',
  },
  {
    title: 'Product Name',
    name: cols.PRODUCT_NAME,
    visible: true,
    width: '250',
    align: 'left',
  },
  {
    title: 'Product Category',
    name: cols.PRODUCT_CATEGORY,
    visible: true,
    width: '150',
    align: 'left',
  },
  {
    title: 'Manufacturer',
    name: cols.MANUFACTURER,
    visible: true,
    required: true,
    width: '200',
  },
  {
    title: 'Distributor & SKU',
    name: cols.DISTRIBUTOR_AND_SKU,
    visible: true,
    required: true,
    width: '154',
  },
  {
    title: 'Price',
    name: cols.PRICE,
    visible: true,
    width: '100',
  },
  {
    title: 'Last Ordered',
    name: cols.LAST_ORDERED,
    visible: true,
    width: '172',
  },
  {
    title: 'Reorder',
    name: cols.REORDER,
    visible: true,
    width: '150',
  },
  {
    title: 'My Notes',
    name: cols.NOTE,
    visible: true,
    width: '100',
  },
  {
    title: 'Location',
    name: cols.FOLDER,
    visible: true,
    width: '120',
    onlyRoot: true,
  },
]

export const columnsListOptional = [
  {
    title: 'QTY Need to Have',
    name: cols.QTY_NEED_TO_HAVE,
    visible: false,
    optional: true,
    width: '135',
  },
  {
    title: 'QTY On Hand',
    name: cols.QTY_ON_HAND,
    visible: false,
    optional: true,
    width: '115',
  },
  {
    title: 'Reorder QTY',
    description: 'Reorder the product when QTY On Hand is equal to or less than this value',
    name: cols.REORDER_WHEN_LTE,
    visible: false,
    optional: true,
    width: '115',
  },
  {
    title: 'Expiration Date',
    name: cols.EXPIRATION_DATE,
    visible: false,
    optional: true,
    width: '130',
  },
  {
    title: 'Product Description',
    name: cols.PRODUCT_DESCRIPTION,
    optional: true,
    visible: false,
    width: '350',
    align: 'left',
  },
]

export const columnsListOptionalRoot = [
  {
    title: 'Product Description',
    name: cols.PRODUCT_DESCRIPTION,
    optional: true,
    visible: false,
    width: '350',
    align: 'left',
  },
]
