
import NotificationAuthorizationAdminPortal from '~/components/NotificationAuthorizationAdminPortal'
import FullScreenLoader from '~/components/FullScreenLoader'
import { getLocalStorage } from '~/ZenOne-StoryBook/helpers/localStorage'
import { IMPERSONATE_USER_ID_LS_ALIAS } from '~/helpers/common'
export default {
  components: {
    FullScreenLoader,
    NotificationAuthorizationAdminPortal,
  },
  computed: {
    adminIsAuthorized() {
      return getLocalStorage(IMPERSONATE_USER_ID_LS_ALIAS) ?? false
    },
  },
}
