
import { mapActions, mapGetters, mapMutations } from 'vuex'
import HistoryMenu from './HistoryMenu'
import SearchBarCatalog from './SearchBar'
import catalogFiltersQueryParams from '~/components/Catalog/CatalogFilters/mixins/catalog-filters-query-params'
import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'

export default {
  name: 'TopMenuSearchBarCatalog',
  components: {
    SearchBarCatalog,
    HistoryMenu,
  },
  mixins: [catalogFiltersQueryParams],
  data() {
    return {
      inputFocusTrigger: false,
      showDropdown: false,
    }
  },
  computed: {
    ...mapGetters({
      history: 'search/history',
      searchTerm: 'search/searchTerm',
      catalogFiltersInStore: 'catalog/filters',
      catalogPageNumberInStore: 'catalog/pageNumber',
      catalogPageSizeInStore: 'catalog/pageSize',
      catalogSortInStore: 'catalog/sort',
      showFadeFS: `ui/showFadeFS`,
    }),
    showHistory() {
      return Boolean(this.showDropdown)
    },
    showSuggestions() {
      return false
    },
  },
  watch: {
    catalogFiltersInStore: {
      deep: true,
      handler() {
        this.updateQueryParams()
      },
    },
    catalogPageSizeInStore() {
      this.updateQueryParams()
    },
    catalogPageNumberInStore() {
      this.updateQueryParams()
    },
    catalogSortInStore() {
      this.updateQueryParams()
    },
    showDropdown: {
      handler(value) {
        this.setShowFadeFS(!!value)
        if (value) return false
      },
    },
  },
  async mounted() {
    if (isArrayWithLength(this.history)) return false
    await this.FetchHistory()
  },
  beforeDestroy() {
    if (!this.showFadeFS) return false
    this.setShowFadeFS(false)
  },
  methods: {
    ...mapMutations('ui', {
      setShowFadeFS: 'SET_SHOW_FADE_FS',
    }),
    ...mapActions('catalog', ['ClearFilters']),
    ...mapActions('search', ['FetchHistory']),
    onSearchInputFocus() {
      this.inputFocusTrigger = !this.inputFocusTrigger
    },
    updateQueryParams() {
      this.setCatalogFiltersQueryParams({
        filters: this.catalogFiltersInStore,
        sort: this.catalogSortInStore,
        pageSize: this.catalogPageSizeInStore,
        pageNumber: this.catalogPageNumberInStore,
      })
    },
    onSetShowDropdown(value = false) {
      this.showDropdown = value
    },
  },
}
