import logoSourceClub from '~/components/img/logo-source-club.png'
import { getLocalStorage, setLocalStorage } from '~/ZenOne-StoryBook/helpers/localStorage'
import { IMPERSONATE_USER_EMAIL_LS_ALIAS, IMPERSONATE_USER_ID_LS_ALIAS } from '~/helpers/common'

export const getRefreshedUser = async (store) => {
  await store.dispatch('user/SetUserPracticeLocation')

  return store.getters['user/user']
}

export const loginUser = async (data, $auth, $store) => {
  await $auth.loginWith('refresh', { data })

  if (getLocalStorage(IMPERSONATE_USER_ID_LS_ALIAS)) {
    setLocalStorage({
      name: IMPERSONATE_USER_EMAIL_LS_ALIAS,
      data: data.email,
    })
  }

  await $store.dispatch('user/SetUserPracticeLocation')
}

export const isGroupSubDomain = () => {
  return window.location.hostname === 'sc.zenone.com'
}

export const isSourceClubCustomer = (store) => {
  return isGroupSubDomain() || store.getters['user/practiceLocation']?.buyingGroups?.includes('source_club')
}

export const groupLogo = () => {
  if (window.location.hostname === 'sc.zenone.com') {
    return logoSourceClub
  }
}

export const groupName = () => {
  if (window.location.hostname === 'sc.zenone.com') {
    return 'source_club'
  }
  return null
}
