
export default {
  name: 'ColumnsSectionTitle',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
}
