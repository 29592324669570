import _, { isArray, isBoolean } from 'lodash'
import {
  getLocalStorage,
  getLocalStorageProp,
  setLocalStorage,
} from '~/ZenOne-StoryBook/helpers/localStorage'
import {
  DEFAULT_PAGE_SIZE,
  INVENTORY_LS_ALIAS,
} from '~/components/Inventory/helper'
import {
  isHavingProps,
  isObjectNotUndefinedHasProps,
} from '~/ZenOne-StoryBook/helpers/object'
import { isArrayWithLength, isObject } from '~/ZenOne-StoryBook/helpers/types'
import { isArrayAndHaveLength } from '~/ZenOne-StoryBook/helpers/array'

export const getFolderIdFromRouteQuery = ($route = {}) => {
  const id = $route?.params?.id ?? null
  return id ? parseInt(id) : null
}

export const DEFAULT_SORT = {
  id: 0,
  name: 'Default',
  value: 'rootCategoryName',
}
export const getInitialSort = () => {
  let sort = getLocalStorageProp(INVENTORY_LS_ALIAS, 'sort') ?? {}
  if (!isObjectNotUndefinedHasProps(sort)) {
    sort = { ...DEFAULT_SORT }
  }
  return sort
}

export const DEFAULT_PAGINATION = {
  currentPage: 1,
  from: 1,
  lastPage: 1,
  perPage: DEFAULT_PAGE_SIZE,
  scrollPosition: 0,
  folderId: null,
}

export const DEFAULT_FILTERS = {
  folderId: [],
  onlyBackordered: false,
  rootCategoryId: [],
  genericNameId: [],
  distributorId: [],
  manufacturerId: [],
  search: '',
}
export const OPTIONS_BY_FILTER_NAME = {
  rootCategoryId: 'rootCategories',
  genericNameId: 'categories',
  distributorId: 'distributors',
  manufacturerId: 'manufacturers',
}

export const saveFiltersToLS = (filters = {}) => {
  const dataLS = getLocalStorage(INVENTORY_LS_ALIAS)
  setLocalStorage({
    name: INVENTORY_LS_ALIAS,
    data: {
      ...dataLS,
      filters,
    },
  })
}

export const getFiltersFromLS = () => {
  const dataLS = getLocalStorage(INVENTORY_LS_ALIAS)
  const filters = dataLS?.filters
  if (isObjectNotUndefinedHasProps(filters)) {
    const data = {
      name: INVENTORY_LS_ALIAS,
      data: {
        ...dataLS,
        filters: { ...DEFAULT_FILTERS },
      },
    }
    setLocalStorage(data)
    return { ...DEFAULT_FILTERS }
  }
  return { ...filters, search: '' }
}

export const getNewChangedReorderQtyList = (currentList = [], item = {}) => {
  const { folderItemId = null, qty = 0 } = item
  if (!folderItemId) return currentList
  let newList
  if (currentList.length === 0) {
    newList = [item]
  } else {
    const index = currentList.findIndex(
      (currentItem) =>
        parseInt(currentItem.folderItemId) === parseInt(item.folderItemId)
    )
    if (index !== -1) {
      currentList[index].qty = qty
      newList = [...currentList]
    } else {
      newList = [...currentList, item]
    }
  }
  return Array.isArray(newList) ? newList : []
}

export const getRootFolderId = (folders = []) => {
  let rootFolderId = null
  for (const folder of folders) {
    if (folder.parent_id) continue
    rootFolderId = folder?.id
  }
  return rootFolderId
}

export const getCurrentFolderIsRoot = (folderId = null, rootFolderId = null) =>
  folderId && folderId === rootFolderId

export const getFolderProp = (folderId, folders = [], prop = '') => {
  if (!folderId || !isArrayWithLength(folders) || !prop) return null
  const index = folders.findIndex((folder) => folder.id === folderId)
  if (index === -1) return null
  const folder = folders?.[index] ?? {}
  if (!isObject(folder) || !isHavingProps(folder)) return null
  return folder?.[prop] ?? null
}

export const getSingleFolderId = (folders = [], rootFolderId = null) => {
  if (!rootFolderId || !isArrayWithLength(folders)) return null
  return folders.filter((folder) => folder.id !== rootFolderId)[0].id
}

export const getIsSearchFilterActive = (value = '') => !!value?.length

export const getFiltersIsActive = (filters = {}) => {
  if (!isHavingProps(filters)) return false
  let result = false
  for (const name in DEFAULT_FILTERS) {
    const value = filters?.[name] ?? []
    if (isArrayWithLength(value) || (!isArray(value) && value)) {
      result = true
      break
    }
  }
  return result
}

export const getColumnsVisibility = (folder = {}) => {
  if (
    !folder ||
    !isObject(folder) ||
    !isHavingProps(folder) ||
    !folder?.columns ||
    !isArrayWithLength(folder.columns)
  )
    return {}
  return folder.columns.reduce((acc, col) => {
    const { name = '', visible = false } = col
    acc[name] = visible
    return acc
  }, {})
}

export const getFilterQuery = (filters = {}) => {
  if (!isHavingProps(filters)) return {}

  let filtersObject = {}
  for (const filterName in filters) {
    const value = filters[filterName]

    if (!value || (isArray(value) && !value.length)) continue

    const propName = `filters[${filterName}]`
    filtersObject = {
      ...filtersObject,
      [propName]: isArray(value)
        ? JSON.stringify(value)
        : isBoolean(value)
          ? (value ? 1 : 0)
          : value,
    }
  }
  return filtersObject
}

export const getRequestParams = (sort = '', pagination = {}, filters = {}) => {
  let params = {}
  if (isObjectNotUndefinedHasProps(pagination)) {
    params = {
      ...params,
      'page[number]': pagination.currentPage,
      'page[size]': pagination.perPage ?? DEFAULT_PAGE_SIZE,
    }
  }
  if (isHavingProps(filters)) {
    params = {
      ...params,
      ...filters,
    }
  }
  if (sort) {
    params.sort = sort
  }

  return params
}

export const canBeAddedToCart = (folderItem = {}) => {
  if (!isObjectNotUndefinedHasProps(folderItem)) return false
  return (
    !folderItem.inCart &&
    folderItem.isActiveStatus &&
    folderItem.price > 0 &&
    folderItem.stockLevel > 0 &&
    folderItem.reorderQty > 0
  )
}

export const getReorderRequestString = (folderItemIds = []) => {
  let queryString = ''
  if (isArrayAndHaveLength(folderItemIds)) {
    queryString = folderItemIds.reduce((acc, item, index) => {
      const { workspaceItemId = null, qty = 0 } = item
      if (workspaceItemId)
        acc += `items[${workspaceItemId}]=${qty}${
          index !== folderItemIds.length - 1 ? '&' : ''
        }`
      return acc
    }, '')
    if (queryString) queryString = `?${queryString}`
  }
  return queryString
}

export const getUpdatedOrderHistoryByIndex = (
  orderHistory = [],
  productDistributorId = null,
  data = {}
) => {
  let hasHistoryChanges = false
  const historyUpdated = [...orderHistory]
  if (isArrayWithLength(orderHistory)) {
    for (let i = 0; i < orderHistory.length; i++) {
      const historyItem = orderHistory[i]
      if (historyItem?.productDistributorId !== productDistributorId) continue
      if (!hasHistoryChanges) hasHistoryChanges = true
      historyUpdated[i] = {
        ...historyItem,
        ...data,
      }
    }
  }

  return { historyUpdated, hasHistoryChanges }
}

export const getIsOnlySearchFilterActive = (
  filters = {},
  isSearchFilterActive = false
) => {
  if (!isSearchFilterActive) {
    return false
  }
  let hasDefaultFilter = false
  const defaultFilters = { ...DEFAULT_FILTERS }
  delete defaultFilters.search
  for (const filterName in defaultFilters) {
    if (!_.isEqual(defaultFilters?.[filterName], filters?.[filterName])) {
      hasDefaultFilter = true
      break
    }
  }
  return !hasDefaultFilter
}

export const getQrcodePrintQueueFromLS = () => {
  return getLocalStorage(INVENTORY_LS_ALIAS)?.qrcodePrintQueue ?? []
}

export const getQrcodePrintQueueCounter = (list = []) =>
  !isArrayWithLength(list) ? 0 : list.length

export const getCanPrintAll = (folders = []) => {
  return true
  /*  if (folders.length < 2) return false
  let result = false
  for (const folder of folders) {
    const cols = folder?.columns ?? []
    if (!isArrayWithLength(cols)) continue
    const qrCodeColIndex = cols.findIndex(
      (col) => col.name === baseCols.QR_CODE
    )
    if (qrCodeColIndex === -1) continue
    const isQrCodeColumnVisible =
      folder.columns[qrCodeColIndex]?.visible ?? false
    if (!isQrCodeColumnVisible) continue
    const count = folder?.count ?? 0
    if (count > 0) {
      result = true
      break
    }
  }
  return result */
}

export const getVisibleColumns = (folder = {}) => {
  if (
    !isObjectNotUndefinedHasProps(folder) ||
    !isArrayWithLength(folder?.defaultColumns)
  )
    return []
  let columns = []
  const defaultColumns = [...folder.defaultColumns]
  if (isArrayWithLength(defaultColumns)) {
    columns = [...defaultColumns]
  }
  const optionalColumns =
    folder?.optionalColumns && isArrayWithLength(folder?.optionalColumns)
      ? [...folder.optionalColumns]
      : []
  if (isArrayWithLength(optionalColumns)) {
    columns = [...columns, ...optionalColumns]
  }
  return columns.filter((column) => column?.visible === true)
}
