import { isNull } from '~/ZenOne-StoryBook/helpers/types'
import { isObjectNotUndefinedHasProps } from '~/ZenOne-StoryBook/helpers/object'

export const ACTION_TYPE_ADD = 'add'
export const ACTION_TYPE_MOVE = 'move'

export const removedFromWorkspaceProductAttributes = {
  isAddedToWorkSpace: false,
  workSpaceId: null,
  workSpaceItemId: null,
  workSpaceName: null,
}

export const getProductDistributorUpdatedData = (workSpaceItem = {}) => {
  if (!isObjectNotUndefinedHasProps(workSpaceItem)) return {}
  const workSpaceId = workSpaceItem?.work_space_id ?? null
  if (isNull(workSpaceId)) return false
  return {
    workSpaceItemId: workSpaceItem?.id ?? null,
    workSpaceId,
    workSpaceName: workSpaceItem?.work_space_name ?? '',
  }
}

export const groupUndoResultByWorkspaceId = (data = []) =>
  // eslint-disable-next-line camelcase
  data.reduce((acc, { id, work_space_id }) => {
    if (!acc[work_space_id]) {
      acc[work_space_id] = []
    }
    acc[work_space_id].push(id)
    return acc
  }, {})
