import { isGroupSubDomain } from '~/helpers/user'
import { clearLocalStorage } from '~/ZenOne-StoryBook/helpers/localStorage'

export default async function(ctx) {
  if (ctx.route.path.includes('/auth/impersonate')) {
    clearLocalStorage()
  }

  if (routeOption(ctx.route, 'auth', false)) {
    return
  }

  // do not redirect from sc.zenone.com to /login. There is the Registration component on index page for them
  if (isGroupSubDomain() && insidePage('/', ctx) && !ctx.$auth.$state.loggedIn) {
    return
  }

  const matches = []
  const Components = getMatchedComponents(ctx.route, matches)
  if (!Components.length) {
    return
  }
  const { login, callback } = ctx.$auth.options.redirect
  const pageIsInGuestMode = routeOption(ctx.route, 'auth', 'guest')
  if (ctx.$auth.$state.loggedIn) {
    const { tokenExpired, refreshTokenExpired, isRefreshable } = ctx.$auth.check(true)
    if (!login || insidePage(login, ctx) || pageIsInGuestMode) {
      ctx.$auth.redirect('home')
    }
    if (refreshTokenExpired) {
      ctx.$auth.reset()
    } else if (tokenExpired) {
      if (isRefreshable) {
        try {
          await ctx.$auth.refreshTokens()
        } catch (error) {
          ctx.$auth.reset()
        }
      } else {
        ctx.$auth.reset()
      }
    }
  } else if (!pageIsInGuestMode && (!callback || !insidePage(callback, ctx))) {
    ctx.$auth.redirect('login')
  }
}

function insidePage(page, ctx) {
  return normalizePath(ctx.route.path, ctx) === normalizePath(page, ctx)
}

function routeOption(route, key, value) {
  return route.matched.some((m) => {
    if (process.client) {
      return Object.values(m.components).some((component) => component.options && component.options[key] === value)
    } else {
      return Object.values(m.components).some((component) => Object.values(component._Ctor).some((ctor) => ctor.options && ctor.options[key] === value))
    }
  })
}

function getMatchedComponents(route, matches = []) {
  return [].concat(...[], ...route.matched.map(function(m, index) {
    return Object.keys(m.components).map(function(key) {
      matches.push(index)
      return m.components[key]
    })
  }))
}

function normalizePath(path = '', ctx) {
  let result = path.split('?')[0]
  if (ctx && ctx.base) {
    result = result.replace(ctx.base, '/')
  }
  if (result.charAt(result.length - 1) === '/') {
    result = result.slice(0, -1)
  }
  result = result.replace(/\/+/g, '/')
  return result
}
