import * as types from './mutation-types'
import {
  setLocalStorage,
  getLocalStorage,
} from '~/ZenOne-StoryBook/helpers/localStorage'
import { CONSUMER_PAYMENTS_ALIAS } from '~/helpers/common'
import { isNotUndefined } from '~/ZenOne-StoryBook/helpers/types'

export default {
  [types.SET_SHOW_DIALOG_PAYMENT_INFO_RECHARGE](state) {
    const { showDialogPaymentInfoRecharge = false } = state
    state.showDialogPaymentInfoRecharge = !showDialogPaymentInfoRecharge
  },
  [types.SET_IS_RETRY_BTN_CLICKED](state, payload) {
    // todo optimize it
    if (payload === true) {
      setLocalStorage({
        name: CONSUMER_PAYMENTS_ALIAS,
        data: {
          subscription: {
            isRetryBtnClicked: payload,
          },
        },
      })
    } else if (payload === false) {
      const paymentsLS = getLocalStorage(CONSUMER_PAYMENTS_ALIAS)
      if (isNotUndefined(paymentsLS?.subscription?.isRetryBtnClicked))
        delete paymentsLS.subscription.isRetryBtnClicked
      setLocalStorage({
        name: CONSUMER_PAYMENTS_ALIAS,
        data: paymentsLS,
      })
    }
    state.isRetryBtnClicked = payload
  },
  [types.SET_IS_RENEW_BTN_CLICKED](state, payload) {
    if (payload === true) {
      setLocalStorage({
        name: CONSUMER_PAYMENTS_ALIAS,
        data: {
          subscription: {
            isRenewBtnClicked: payload,
          },
        },
      })
    } else if (payload === false) {
      const paymentsLS = getLocalStorage(CONSUMER_PAYMENTS_ALIAS)
      if (isNotUndefined(paymentsLS?.subscription?.isRenewBtnClicked))
        delete paymentsLS.subscription.isRenewBtnClicked
      setLocalStorage({
        name: CONSUMER_PAYMENTS_ALIAS,
        data: paymentsLS,
      })
    }
    state.isRenewBtnClicked = payload
  },
  [types.SET_IS_RENEW_DECLINED](state, payload) {
    if (payload === true) {
      setLocalStorage({
        name: CONSUMER_PAYMENTS_ALIAS,
        data: {
          subscription: {
            isRenewDeclined: payload,
          },
        },
      })
    } else if (payload === false) {
      const paymentsLS = getLocalStorage(CONSUMER_PAYMENTS_ALIAS)
      if (isNotUndefined(paymentsLS?.subscription?.isRenewDeclined))
        delete paymentsLS.subscription.isRenewDeclined
      setLocalStorage({
        name: CONSUMER_PAYMENTS_ALIAS,
        data: paymentsLS,
      })
    }
    state.isRenewDeclined = payload
  },
}
