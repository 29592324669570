import * as types from './mutation-types'

export default {
  [types.SET_USER](state, payload) {
    state.user = payload
  },
  [types.SET_EMAIL_IS_CONFIRMED](state) {
    const userCopy = { ...state.user }
    state.user = { ...userCopy, emailConfirmed: 1 }
  },
  [types.SET_BUDGET](state, payload) {
    state.budget = payload
  },
  [types.SET_NAME](state, payload) {
    const userCopy = { ...state.user }
    state.user = { ...userCopy, name: payload }
  },
  [types.SET_CARD](state, payload) {
    state.card = payload
  },
  [types.SET_PRACTICE](state, payload) {
    state.practice = payload
  },
  [types.SET_PRACTICE_LOCATION](state, payload) {
    state.practiceLocation = payload
  },
  [types.SET_BUDGET_CURRENT](state, payload) {
    if (state.practiceLocation) {
      state.practiceLocation.budgetCurrent = payload
    }
  },
  [types.SET_AUTHORIZED_ONLY](state, payload) {
    if (state.practiceLocation) {
      state.practiceLocation.authorizedSavingsOnly = payload
    }
  },
  [types.SET_PRACTICE_ID](state, payload) {
    state.practiceId = payload
  },
  [types.SET_CURRENT_PRACTICE_LOCATION_ID](state, payload) {
    state.currentPracticeLocationId = payload
  },
  [types.SET_WIZARD_IS_COMPLETED](state) {
    const practiceLocation = { ...state.practiceLocation }
    practiceLocation.wizardCompleted = 1
    state.practiceLocation = practiceLocation
  },
  [types.SET_CARD_WAS_UPDATED_ON_RECHARGE](state) {
    const currentValue = state.cardWasUpdatedOnRecharge
    state.cardWasUpdatedOnRecharge = !currentValue
  },
  [types.SET_PRACTICE_LOCATION_STATUS_WAS_UPDATED_ON_RECHARGE](state) {
    const currentValue = state.plStatusWasUpdatedOnRecharge
    state.plStatusWasUpdatedOnRecharge = !currentValue
  },
}
