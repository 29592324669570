import { isHavingProps } from '~/ZenOne-StoryBook/helpers/object'
import { isArrayAndHaveLength } from '~/ZenOne-StoryBook/helpers/array'
const jvTag = '_jv' //
export const clearJsonApiVuexState = (store, types = []) => {
  if (!store || !isHavingProps(store)) return false
  const jvZenModuleState = store?._modules?.root?.state?.jvZen
  if (!jvZenModuleState || !isHavingProps(jvZenModuleState)) return false
  if (isArrayAndHaveLength(types)) {
    for (const type of types) {
      const keys = Object.keys(jvZenModuleState)
      if (keys.includes(type))
        store.commit('jvZen/clearRecords', { [jvTag]: { type } })
    }
  } else {
    const types = Object.keys(jvZenModuleState)
    if (types.length === 0 || (types.length === 1 && types[0] === jvTag))
      return false
    for (const type of types) {
      if (type !== jvTag)
        store.commit('jvZen/clearRecords', { [jvTag]: { type } })
    }
  }
}
