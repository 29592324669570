import shareMutations from 'vuex-shared-mutations'

export default ({ store }) => {
  window.onNuxtReady(() => {
    shareMutations({
      predicate: [
        'inventory/SET_FOLDERS',
        'inventory/SET_FOLDER_BY_ID',
        'inventory/SET_ACTIVE_FOLDER',
        'inventory/SET_SHARED_FOLDER_ID_BY_ROUTE',
        'inventory/SET_NEED_FETCH_ITEMS',
      ],
    })(store)
  })
}
