import Vue from 'vue'
import Vuetify from 'vuetify'
import IconCatalog from '~/ZenOne-StoryBook/components/SvgIcons/IconCatalog'
Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    values: {
      test: {
        component: IconCatalog,
      },
    },
  },
})
