
import { mapMutations } from 'vuex'
import DistributorSearch from '~/components/ConnectDistributors/DistributorSearch'
import { SET_SEARCH_TERM } from '~/store/modules/distributors/mutation-types'
export default {
  name: 'TopMenuSearchBarDistributors',
  components: { DistributorSearch },
  beforeDestroy() {
    this.setSearchTerm('')
  },
  methods: {
    ...mapMutations({
      setSearchTerm: `distributors/${SET_SEARCH_TERM}`,
    }),
  },
}
