import {
  STATUS_ACTIVE,
  STATUS_FAILED,
  STATUS_PENDING,
} from '~/helpers/connect-distributors'
import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'

export const sortByName = (a, b) => (a.name > b.name ? 1 : -1)

export const sortList = (distributorList = []) => {
  if (!isArrayWithLength(distributorList)) return []
  const preferredList = []
  const commonList = []
  const unlinkedList = []

  for (const distributor of distributorList) {
    const status = distributor?.status ?? null
    const preferred = distributor?.preferred ?? false
    if (preferred) {
      distributor.isInPreferredList = true
      preferredList.push(distributor)
    } else if (
      !preferred &&
      [STATUS_ACTIVE, STATUS_PENDING, STATUS_FAILED].includes(status)
    ) {
      distributor.isInCommonList = true
      commonList.push(distributor)
    } else if (status === null) {
      distributor.isInUnlinkedList = true
      unlinkedList.push(distributor)
    }
  }

  return [
    ...commonList.sort(sortByName),
    ...preferredList.sort(sortByName),
    ...unlinkedList.sort(sortByName),
  ]
}

export const sortByStatus = (list = []) => {
  if (!isArrayWithLength(list)) return []
  const activeList = list.filter((item) => item?.status === STATUS_ACTIVE)
  if (isArrayWithLength(activeList)) activeList.sort(sortByName)
  const notActiveList = list.filter((item) =>
    [STATUS_PENDING, STATUS_FAILED].includes(item?.status)
  )
  if (isArrayWithLength(activeList)) notActiveList.sort(sortByName)
  const res = [...activeList, ...notActiveList]
  return res
}
