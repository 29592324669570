
import practiceLocation from '~/mixins/practiceLocation'

export default {
  name: 'PastDueRetryTitle',
  mixins: [practiceLocation],
  props: {
    success: {
      type: Boolean,
      default: false,
    },
    addClass: {
      type: String,
      default: '',
    },
    cardNumber: {
      type: String,
      default: '',
    },
  },
  computed: {
    statusText() {
      return this.success ? 'successfully charged.' : 'declined.'
    },
  },
}
