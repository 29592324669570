export const PRODUCT_IMAGE = 'productImage'
export const PRODUCT_NAME = 'productName'
export const PRODUCT_DESCRIPTION = 'productDescription'
export const PRODUCT_CATEGORY = 'productCategory'
export const MANUFACTURER = 'manufacturer'
export const DISTRIBUTOR_AND_SKU = 'distributorAndSku'
export const PRICE = 'price'
export const LAST_ORDERED = 'lastOrdered'
export const REORDER = 'reorder'
export const REORDER_WHEN_LTE = 'reorderWhenLte'
export const NOTE = 'note'
export const FOLDER = 'folder'
export const QTY_NEED_TO_HAVE = 'qtyNeedToHave'
export const QTY_ON_HAND = 'qtyOnHand'
// export const QR_CODE = 'qrCode'
export const EXPIRATION_DATE = 'expirationDate'
