
export default {
  name: 'ButtonRound',
  props: {
    onClick: { type: Function, default: () => {} },
    title: {
      type: String,
      default: 'Btn Title',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'sm',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String || null,
      default: null,
    },
    minWidth: {
      type: String,
      default: '',
    },
    type: {
      type: String || null,
      default: 'button',
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  computed: {
    getType() {
      return this?.type ? this.type : 'button'
    },
    getStyle() {
      const { minWidth = '' } = this
      if (minWidth) {
        return `min-width: ${minWidth}`
      }
      return null
    },
  },
  methods: {
    clickHandler() {
      this.onClick()
    },
  },
}
