import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  isArrayWithLength,
  isNotUndefined,
} from '../ZenOne-StoryBook/helpers/types'
import { isHavingProps } from '../ZenOne-StoryBook/helpers/object'
import { OVERNIGHT_DELIVERY, STANDARD_DELIVERY } from '../helpers/cart'
import { API as api } from '../ZenOne-StoryBook/config/api'
import { findTopItem } from '~/components/Catalog/helper'
import { CART_ITEM_SELECTION_TYPE } from '~/store/modules/shopping_cart/helper'

export default {
  computed: {
    ...mapGetters({
      tops: 'catalog/tops',
    }),
    isShippingOvernight() {
      const { distributor = {} } = this
      let overnight = distributor?.cart?.shipping?.overnight
      overnight = isNotUndefined(overnight) ? overnight : null
      if (overnight && isHavingProps(overnight)) {
        const {
          isOvernight = false,
          shipMethodId = null,
          shippingCost = null,
        } = overnight
        return isOvernight && shipMethodId !== null && shippingCost !== null
      } else {
        return false
      }
    },
    isOvernightSelected() {
      if (!this.distributor) return null
      const {
        distributor: {
          cart: {
            shipping: { isOvernightSelected = false },
          },
        },
      } = this
      return isOvernightSelected
    },
    estimatedDelivery() {
      const { distributor = {}, isOvernightSelected = null } = this
      if (!isHavingProps(distributor)) return null
      const deliveryType = !isOvernightSelected
        ? STANDARD_DELIVERY
        : OVERNIGHT_DELIVERY
      return distributor?.cart?.shipping?.[deliveryType]?.estimatedDelivery
    },
    estimatedShipping() {
      const { distributor = {}, isOvernightSelected = null } = this
      if (!isHavingProps(distributor)) return null
      const deliveryType = !isOvernightSelected
        ? STANDARD_DELIVERY
        : OVERNIGHT_DELIVERY
      const estimatedShipping =
        distributor?.cart?.shipping?.[deliveryType]?.estimatedShipping
      return estimatedShipping ? parseFloat(estimatedShipping) : 0
    },
  },
  methods: {
    ...mapMutations('catalog', {
      setTopItem: 'SET_TOP_ITEM',
    }),
    ...mapActions({
      addCartItemIds: 'shoppingCart/addCartItemIds',
      addItemsToSelected: 'shoppingCart/addItemsToSelected',
    }),
    getOvernightSelected(distributor) {
      const {
        cart: {
          shipping: { isOvernightSelected = false },
        },
      } = distributor
      return isOvernightSelected
    },
    getEstimatedShipping(distributor) {
      const isOvernightSelected = this.getOvernightSelected(distributor)
      const deliveryType = !isOvernightSelected
        ? STANDARD_DELIVERY
        : OVERNIGHT_DELIVERY
      return distributor?.cart?.shipping?.[deliveryType]?.estimatedShipping
    },
    async setCartItemsCount() {
      await this.$store.dispatch('user/SetUserPracticeLocation')
    },
    async addProductToCart(data = {}) {
      try {
        if (!data || !isHavingProps(data)) throw new Error('No product to add')
        const { productDistributorId = null, qty = 0 } = data
        if (!productDistributorId) {
          throw new Error('No product distributor id')
        }
        if (qty < 1) {
          throw new Error('Invalid qty')
        }
        const res = await this.$axios.post(`${api.CART_ITEMS}/add`, {
          data: {
            type: 'cart-items',
            attributes: {
              productDistributorId,
              qty,
            },
          },
        })
        const cartItemId = res?.data?.cartItemId ?? null
        if (!cartItemId) {
          throw new Error('No cart item id')
        }
        const cartItem = {
          id: cartItemId,
          qty,
        }
        const addedShoppingCart = true
        this.updateTopItemInCartState([
          { productDistributorId, cartItem, addedShoppingCart },
        ])
        this.updateProductList(productDistributorId, {
          cartItem,
          addedShoppingCart,
        })
        await this.setCartItemsCount()
        this.addCartItemIds({ ids: [cartItemId] })
        this.addItemsToSelected({
          type: CART_ITEM_SELECTION_TYPE,
          ids: [cartItemId],
        })
        return cartItem
      } catch (e) {
        console.error('addProductToCart', e)
      }
    },
    updateProductList(productDistributorId = null, data = {}) {
      const list = this?.products?.list ? [...this.products.list] : []
      if (
        !isArrayWithLength(list) ||
        !productDistributorId ||
        !isHavingProps(data)
      )
        return false
      const index = list.findIndex(
        (productDistributor) =>
          parseInt(productDistributor.productDistributorId) ===
          parseInt(productDistributorId)
      )
      if (index !== -1) {
        const productDistributorCopy = { ...this.products.list[index] }
        for (const prop in data) {
          productDistributorCopy[prop] = data?.[prop]
        }
        this.$set(this.products.list, index, productDistributorCopy)
      }
    },
    async changeQtyInCart(data = {}) {
      const {
        productDistributorId = null,
        cartItem = {},
        addedShoppingCart = true,
      } = data
      this.updateProductList(productDistributorId, {
        cartItem,
        addedShoppingCart,
      })
      this.updateTopItemInCartState([
        { productDistributorId, cartItem, addedShoppingCart },
      ])
      await this.setCartItemsCount()
    },
    updateTopItemInCartState(productDistributors = []) {
      if (!isArrayWithLength(productDistributors)) return false
      const { tops = {} } = this
      const ordered = tops?.ordered?.productList ?? []
      const recentlyPurchased = tops?.recentlyPurchased?.productList ?? []
      const mostPurchased = tops?.mostPurchased?.productList ?? []
      if (
        !isArrayWithLength(ordered) &&
        !isArrayWithLength(recentlyPurchased) &&
        !isArrayWithLength(mostPurchased)
      ) {
        return false
      }
      const list = {
        ordered,
        recentlyPurchased,
        mostPurchased,
      }
      for (const productDistributor of productDistributors) {
        const productDistributorId =
          productDistributor?.productDistributorId ?? null
        if (!productDistributorId) continue
        const topItemsList = findTopItem(productDistributorId, list)
        if (!isArrayWithLength(topItemsList)) return false
        for (const topItem of topItemsList) {
          const { typeName = '', index = null } = topItem
          if (typeName && index !== null) {
            const { cartItem = {}, addedShoppingCart = true } =
              productDistributor
            this.setTopItem({
              type: typeName,
              index,
              item: {
                ...list[typeName][index],
                addedShoppingCart,
                cartItem,
              },
            })
          }
        }
      }

      return true
    },
  },
}
