import actions from './actions'
import mutations from './mutations'

const resources = {
  state: {
    distributorsLight: [],
    rootCategoriesLight: [],
  },
  getters: {
    distributorsLight: (state) => state.distributorsLight,
    rootCategoriesLight: (state) => state.rootCategoriesLight,
  },
  mutations,
  actions,
  namespaced: true,
}

export default resources
