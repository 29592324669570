import { render, staticRenderFns } from "./login.vue?vue&type=template&id=b5dc9d46&scoped=true&"
import script from "./login.vue?vue&type=script&lang=js&"
export * from "./login.vue?vue&type=script&lang=js&"
import style0 from "./login.vue?vue&type=style&index=0&id=b5dc9d46&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5dc9d46",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/home/runner/work/ZenOne-Consumer-Portal/ZenOne-Consumer-Portal/components/logo/index.vue').default})
