const issue = {
  state: {
    issue: [],
  },
  mutations: {
    setIssue: (state, value) => {
      state.issue.push(value)
    },
  },
  getters: {
    getIssue: (state) => state.issue,
  },
  namespaced: true,
}
export default issue
