import actions from './actions'
import mutations from './mutations'
import {
  DEFAULT_FILTERS,
  DEFAULT_SORT,
  OPTIONS_BY_FILTER_NAME,
  sortOrderHistoryToTop,
} from '~/components/Catalog/CatalogFilters/helper'
import {
  getFiltersOptionsFromLS,
  getIsFiltersActive,
} from '~/store/modules/catalog/helper'
import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'
import { getCatalogFilterTags } from '~/helpers/filterTags/common'
export const DEFAULT_PAGE_NUMBER = 1
export const DEFAULT_PAGE_SIZE = 35

const catalog = {
  state: {
    isFetching: false,
    tops: null, // null indicates not loaded yet.
    sort: DEFAULT_SORT,
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: DEFAULT_PAGE_NUMBER,
    // going forward, this should be the single central source of truth reguarding catalog filters.
    // local storage should not be used, query params should not be used (unless initial page load).
    filters: { ...DEFAULT_FILTERS },
    distributorsOverall: [], // complete distributors list =>
    // should be used for distributor filter options without active search filter
    filterOptions: {
      categories: getFiltersOptionsFromLS('categories'),
      distributors: getFiltersOptionsFromLS('distributors'),
      generics: getFiltersOptionsFromLS('generics'),
      subcategories: getFiltersOptionsFromLS('subcategories'),
      manufacturers: getFiltersOptionsFromLS('manufacturers'),
      modifiers: getFiltersOptionsFromLS('modifiers'),
      packages: getFiltersOptionsFromLS('packages'),
    },
    // Detail product
    generalProductWorkspace: null,
  },
  getters: {
    tops: (state) => state.tops,
    hasTopOrdered: (state, getters) => isArrayWithLength(getters.tops?.ordered),
    hasTopRecentlyPurchased: (state, getters) =>
      isArrayWithLength(getters.tops?.recentlyPurchased),
    hasMostPurchased: (state, getters) =>
      isArrayWithLength(getters.tops?.mostPurchased),
    sort: (state) => state.sort,
    pageSize: (state) => state.pageSize,
    pageNumber: (state) => state.pageNumber,
    isFiltersActive: (state, getters) => getIsFiltersActive(getters.filters),
    filters: (state) => state.filters,
    distributorsOverall: (state) => state.distributorsOverall,
    filterOptions: (state) => state.filterOptions,
    filterTags: (state, getters) =>
      getCatalogFilterTags(
        getters.filterOptions,
        getters.filters,
        DEFAULT_FILTERS,
        OPTIONS_BY_FILTER_NAME,
        sortOrderHistoryToTop
      ),
    isFetching: (state) => state.isFetching,
    isSearchActive: (state, getters) => !!getters.filters.searchable,
    showTops: (state, getters) =>
      !getters.isFiltersActive &&
      !getters.isSearchActive &&
      !getters.isFetching,
    generalProductWorkspace: (state) => state.generalProductWorkspace,
  },
  mutations,
  actions,
  namespaced: true,
}

export default catalog
