import { isNull } from 'lodash'
import {
  isHavingProps,
  isObjectNotUndefinedHasProps,
} from '~/ZenOne-StoryBook/helpers/object'
import {
  isArrayWithLength,
  isNotUndefined,
} from '~/ZenOne-StoryBook/helpers/types'
import {
  BY_CREDIT_CARD,
  BILL_BY_INVOICE,
} from '~/ZenOne-StoryBook/helpers/payment'
export const OVERNIGHT_DELIVERY = 'overnight'
export const STANDARD_DELIVERY = 'standard'
export const CUT_OFF_TIME_DEFAULT = '15:00:00-06:00'

export const mapDistributors = (
  data = {},
  cartSelectedIds = [],
  isAllCartItemsSelected = true
) => {
  let cartItemsTotalCount = 0
  let cartItemsCount = 0
  const {
    data: distributorsData = [],
    isHavingActiveSavings = false,
    withoutSavings = {},
  } = data
  let { savingCosts = 0, savingSubtotal = 0 } = data
  savingCosts = parseFloat(savingCosts)
  savingSubtotal = parseFloat(savingSubtotal)
  const distributorList = distributorsData.reduce((acc, distributor) => {
    const { attributes, id } = distributor
    let standardShipping
    let differenceToFreeShipping = 0
    const {
      name,
      eta,
      overnightEta,
      cutOffTime = CUT_OFF_TIME_DEFAULT,
      isOvernight,
      overnightShipMethodId,
      overnightShippingCost,
      paymentType = 'Credit Card',
      currentDatetime = null,
      subtotalInStockWithoutSavings = 0,
      handling = 0,
      tax = 0,
      taxWithOvernightCost = 0,
      total = 0,
      subtotalInStock = 0,
      isCoveredByZen = false,
    } = attributes

    const cartItems = mapCartItems(
      [...attributes.cartItems],
      isHavingActiveSavings,
      cartSelectedIds,
      isAllCartItemsSelected
    )

    let {
      minOrderTotalFree = null,
      subtotal = 0,
      fixedShippingCost = null,
    } = attributes
    if (fixedShippingCost && fixedShippingCost > 0) {
      fixedShippingCost = parseFloat(fixedShippingCost)
    }
    if (minOrderTotalFree && minOrderTotalFree > 0) {
      minOrderTotalFree = parseFloat(minOrderTotalFree)
    }
    if (subtotal && subtotal > 0) {
      subtotal = parseFloat(subtotal)
    } else {
      subtotal = 0
    }

    if (isArrayWithLength(cartItems)) {
      cartItemsCount += cartItems.length
      cartItemsTotalCount += cartItems.reduce((acc, cartItem) => {
        acc = acc + (cartItem?.qty ? cartItem.qty : 0)
        return acc
      }, 0)
    }

    if (!minOrderTotalFree) {
      standardShipping = fixedShippingCost
    } else if (minOrderTotalFree > 0) {
      if (subtotal < minOrderTotalFree) {
        standardShipping = fixedShippingCost
        differenceToFreeShipping = parseFloat(minOrderTotalFree - subtotal)
      } else {
        standardShipping = 0
      }
    }
    const distributorItem = {
      id,
      name,
      currentDatetime,
      cartItems,
      cart: {
        subtotal,
        subtotalInStock,
        total,
        handling,
        tax,
        taxWithOvernightCost,
        paymentType,
        subtotalInStockWithoutSavings,
        shipping: {
          isOvernightSelected: false,
          cutOffTime,
          standard: {
            estimatedDelivery: eta,
            estimatedShipping: standardShipping,
            differenceToFreeShipping,
            fixedShippingCost,
            minOrderTotalFree,
            isCoveredByZen,
          },
          overnight: {
            isOvernight,
            shipMethodId: overnightShipMethodId,
            shippingCost: parseFloat(overnightShippingCost),
            estimatedShipping: parseFloat(overnightShippingCost),
            estimatedDelivery: overnightEta,
          },
        },
      },
    }
    acc.push(distributorItem)
    return acc
  }, [])
  return {
    distributors: distributorList,
    cartItemsCount,
    cartItemsTotalCount,
    savingCosts,
    savingSubtotal,
    withoutSavings,
    isHavingActiveSavings,
  }
}

export const mapSavedForLater = (list = [], selectedIds = []) => {
  return {
    savedForLaterList: list.reduce((acc, cartItem) => {
      const productManufacturerId =
        cartItem?.['product-distributor']?.product?._jv?.id
      cartItem.productManufacturerId = isNotUndefined(productManufacturerId)
        ? parseInt(productManufacturerId)
        : 0
      const mapProductImage = (cartItem) => {
        const images = cartItem?.['product-distributor']?.product?.images
        if (images && isHavingProps(images)) {
          const imagesKeys = Object.keys(images)
          const fileId = imagesKeys[0]
          if (fileId) {
            const image = images[fileId]
            const thumb = image?.thumb?.path
            if (isNotUndefined(thumb)) return thumb
            const imagePath = image?.path
            return isNotUndefined(imagePath) ? imagePath : null
          }
        }
        return null
      }
      delete cartItem?._jv
      const cartItemId = cartItem?.id ?? null
      if (!isNull(cartItemId)) cartItem.id = parseInt(cartItemId)
      cartItem.checked = selectedIds.includes(cartItem.id)
      const savedForLaterItem = {
        ...cartItem,
        productImage: mapProductImage(cartItem),
      }
      acc.push(savedForLaterItem)
      return acc
    }, []),
  }
}
export const mapOrders = (orders) => {
  const orderList = orders.reduce((acc, order) => {
    let paymentTooltip = ''
    let paymentTitle = ''
    const {
      number,
      total,
      distributor: { name: distributorName = '', paymentType = null },
    } = order
    if (paymentType) {
      if (paymentType === BY_CREDIT_CARD) {
        paymentTooltip = `The credit card you have stored in Zen was used to process the payment for this order. You can find order details as well as a copy of the invoice in Manage Orders.`
        paymentTitle = 'Credit Card'
      } else if (paymentType === BILL_BY_INVOICE) {
        paymentTooltip = `This distributor will be sending you an invoice or will charge the credit card you have stored on their website.`
        paymentTitle = 'Invoice'
      }
    }

    const orderItem = {
      number,
      total,
      distributorName,
      payment: {
        title: paymentTitle,
        tooltip: paymentTooltip,
      },
    }
    acc.push(orderItem)
    return acc
  }, [])
  return { orderList }
}

export const getStandardShippingData = (
  standardShipping = {},
  estimatedTotal = 0
) => {
  const NOT_AVAILABLE = 'N/A'
  const FREE = 'Free'
  let result = {
    cost: FREE,
    infoComponent: 'DescriptionFree',
  }
  const {
    fixedShippingCost,
    minOrderTotalFree,
    isCoveredByZen = false,
  } = standardShipping
  if (isCoveredByZen) {
    return {
      ...result,
      infoComponent: 'FreeShippingZoLabel',
    }
  }
  estimatedTotal = isNotUndefined(estimatedTotal) ? estimatedTotal : 0
  if (fixedShippingCost === null) {
    if (minOrderTotalFree === null) {
      result = {
        cost: NOT_AVAILABLE,
        infoComponent: 'DescriptionEmpty',
      }
    } else if (minOrderTotalFree > 0) {
      result = {
        cost: estimatedTotal <= minOrderTotalFree ? NOT_AVAILABLE : FREE,
        infoComponent:
          estimatedTotal <= minOrderTotalFree
            ? 'DescriptionDelta'
            : 'DescriptionFree',
      }
    }
  } else if (fixedShippingCost > 0) {
    if (minOrderTotalFree === null) {
      result = {
        cost: fixedShippingCost,
        infoComponent: 'DescriptionEmpty',
      }
    } else if (minOrderTotalFree > 0) {
      result = {
        cost: estimatedTotal <= minOrderTotalFree ? fixedShippingCost : FREE,
        infoComponent:
          estimatedTotal <= minOrderTotalFree
            ? 'DescriptionDelta'
            : 'DescriptionFree',
      }
    }
  }
  return result
}

const mapCartItems = (
  originList = [],
  isHavingActiveSavings = false,
  cartSelectedIds = [],
  isAllCartItemsSelected = true
) => {
  if (!isArrayWithLength(originList)) return []
  const list = []
  for (let item of originList) {
    item.productManufacturerId = item?.productId ? parseInt(item.productId) : 0
    item.checked = isAllCartItemsSelected || cartSelectedIds.includes(item?.id)

    const savings = item?.savings ?? null
    const savingsSimilar = item?.savingsSimilar ?? null
    const hasSavings = !isNull(savings)
    const hasSavingsSimilar = !isNull(savingsSimilar)
    if (hasSavings || hasSavingsSimilar || isHavingActiveSavings) {
      item = mapSavings(
        item,
        hasSavings,
        hasSavingsSimilar,
        isHavingActiveSavings
      )
    }
    list.push(item)
  }
  return list
}

const getBestChoiceType = (
  item = {},
  hasSavings = false,
  hasSavingsSimilar = false
) => {
  if (hasSavings && !hasSavingsSimilar) return SAME_BRAND_SAVING_TYPE
  else if (hasSavingsSimilar && !hasSavings) return SIMILAR_SAVING_TYPE
  else {
    const sameBrandPrice = item?.savings?.productDistributorPrice ?? 0
    const similarPrice = item?.savingsSimilar?.productDistributorPrice ?? 0
    if (sameBrandPrice > similarPrice) return SIMILAR_SAVING_TYPE
    else return SAME_BRAND_SAVING_TYPE
  }
}

const mapSavings = (
  item = {},
  hasSavings = false,
  hasSavingsSimilar = false,
  isHavingActiveSavings = false
) => {
  let itemUpdated = {}
  const cartItemId = item?.id ?? null
  const initialCartItem = item?.initialCartItem ?? {}
  if (!isHavingProps(initialCartItem)) {
    if (!hasSavings && !hasSavingsSimilar) return item
    const bestChoiceType = getBestChoiceType(
      item,
      hasSavings,
      hasSavingsSimilar
    )
    itemUpdated = {
      ...item,
      savings: [
        {
          ...item,
          productNameForSort: item?.productName ?? '',
          cartItemId,
          type: ORIGIN_SAVING_TYPE,
          isSelected: getIsSavingSelected(
            item,
            ORIGIN_SAVING_TYPE,
            bestChoiceType,
            isHavingActiveSavings
          ),
        },
      ],
    }
    const qty = item?.qty ?? 0
    const priceOrigin = item?.productDistributorPrice ?? 0
    const etaOrigin = item?.eta ?? ''
    // todo refactor
    const sameBrandSaving = hasSavings
      ? {
          ...item.savings,
          type: SAME_BRAND_SAVING_TYPE,
          priceOrigin,
          etaOrigin,
          qty,
          cartItemId,
          isSelected: getIsSavingSelected(
            item,
            SAME_BRAND_SAVING_TYPE,
            bestChoiceType,
            isHavingActiveSavings
          ),
        }
      : {
          type: SAME_BRAND_SAVING_TYPE,
          productDistributorId: null,
          cartItemId,
          isSelected: false,
        }
    itemUpdated.savings = [...itemUpdated.savings, sameBrandSaving]

    const similarSaving = hasSavingsSimilar
      ? {
          ...item.savingsSimilar,
          type: SIMILAR_SAVING_TYPE,
          priceOrigin,
          etaOrigin,
          qty,
          cartItemId,
          isSelected: getIsSavingSelected(
            item,
            SIMILAR_SAVING_TYPE,
            bestChoiceType,
            isHavingActiveSavings
          ),
        }
      : {
          type: SIMILAR_SAVING_TYPE,
          productDistributorId: null,
          cartItemId,
          isSelected: false,
        }
    itemUpdated.savings = [...itemUpdated.savings, similarSaving]

    return itemUpdated
  } else {
    delete item.initialCartItem
    const initialProductId = initialCartItem?.productId ?? null
    const currentProductId = item.productId
    const priceOrigin = initialCartItem?.productDistributorPrice ?? 0
    const etaOrigin = initialCartItem?.eta ?? ''
    const qty = item?.qty ?? 0
    itemUpdated = {
      ...item,
      savings: [
        {
          ...initialCartItem,
          productNameForSort: initialCartItem?.productName ?? '',
          cartItemId,
          type: ORIGIN_SAVING_TYPE,
          isSelected: false,
          qty,
          showInitialChoiceLabel: true,
        },
      ],
    }
    if (initialProductId === currentProductId) {
      let sameBrandSaving = {
        type: SAME_BRAND_SAVING_TYPE,
        priceOrigin,
        etaOrigin,
        qty,
        cartItemId,
        isSelected: true,
      }

      const newSameBrandSaving = item?.savings ?? []
      if (isObjectNotUndefinedHasProps(newSameBrandSaving)) {
        sameBrandSaving = {
          ...sameBrandSaving,
          ...newSameBrandSaving,
        }
      } else {
        sameBrandSaving = {
          ...sameBrandSaving,
          ...item,
        }
      }
      itemUpdated.savings = [
        ...itemUpdated.savings,
        {
          ...sameBrandSaving,
        },
      ]
      const similarSaving = hasSavingsSimilar
        ? {
            ...item.savingsSimilar,
            type: SIMILAR_SAVING_TYPE,
            priceOrigin,
            etaOrigin,
            qty,
            cartItemId,
            isSelected: false,
          }
        : {
            type: SIMILAR_SAVING_TYPE,
            productDistributorId: null,
            cartItemId,
            isSelected: false,
          }
      itemUpdated.savings = [...itemUpdated.savings, similarSaving]
    } else {
      const sameBrandSaving = hasSavings
        ? {
            ...item.savings,
            type: SAME_BRAND_SAVING_TYPE,
            priceOrigin,
            etaOrigin,
            qty,
            cartItemId,
            isSelected: false,
          }
        : {
            type: SAME_BRAND_SAVING_TYPE,
            productDistributorId: null,
            cartItemId,
            isSelected: false,
          }
      itemUpdated.savings = [
        ...itemUpdated.savings,
        sameBrandSaving,
        {
          ...item,
          type: SIMILAR_SAVING_TYPE,
          priceOrigin,
          etaOrigin,
          qty,
          cartItemId,
          isSelected: true,
        },
      ]
    }
  }
  return itemUpdated
}

export const ORIGIN_SAVING_TYPE = 'origin'
export const SAME_BRAND_SAVING_TYPE = 'sameBrand'
export const SIMILAR_SAVING_TYPE = 'similar'

export const calculateCanSaveAmount = (
  subtotal = 0,
  handling = 0,
  shipping = 0,
  savingSubtotal = 0,
  savingCosts = 0
) => {
  return subtotal + handling + shipping - (savingSubtotal + savingCosts)
}

const getIsSavingSelected = (
  item = {},
  type = '',
  bestChoiceType = '',
  isHavingActiveSavings
) => {
  if (!type || !isHavingProps(item)) return false
  const hasOriginType = type === ORIGIN_SAVING_TYPE
  const hasSameBrandType = type === SAME_BRAND_SAVING_TYPE
  const hasSimilarType = type === SIMILAR_SAVING_TYPE
  const initialCartItem = item?.initialCartItem ?? {}
  const initialProductId = initialCartItem?.productId ?? null
  if (!initialProductId && !isHavingActiveSavings) {
    if (hasSameBrandType && bestChoiceType === SAME_BRAND_SAVING_TYPE)
      return true
    else if (hasSimilarType && bestChoiceType === SIMILAR_SAVING_TYPE)
      return true
  } else {
    if (hasOriginType) {
      return !initialProductId
    }
    const productId = item?.productId
    if (
      hasSameBrandType &&
      !isNull(initialProductId) &&
      productId === initialProductId
    )
      return true
    else if (
      hasSimilarType &&
      !isNull(initialProductId) &&
      productId !== initialProductId
    )
      return true
  }
  return false
}
