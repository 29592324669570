import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d27621b8 = () => interopDefault(import('../pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _6fc6524e = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _198dd25c = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _93a71494 = () => interopDefault(import('../pages/inventory/index.vue' /* webpackChunkName: "pages/inventory/index" */))
const _6a1c9f00 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _c5beff78 = () => interopDefault(import('../pages/my-distributors.vue' /* webpackChunkName: "pages/my-distributors" */))
const _0372d6b0 = () => interopDefault(import('../pages/order/index.vue' /* webpackChunkName: "pages/order/index" */))
const _46c13a6b = () => interopDefault(import('../pages/product-detail/index.vue' /* webpackChunkName: "pages/product-detail/index" */))
const _862dc21c = () => interopDefault(import('../pages/registration.vue' /* webpackChunkName: "pages/registration" */))
const _17977c93 = () => interopDefault(import('../pages/savings/index.vue' /* webpackChunkName: "pages/savings/index" */))
const _179f65dc = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _9dfc8138 = () => interopDefault(import('../pages/wizard.vue' /* webpackChunkName: "pages/wizard" */))
const _597e7d80 = () => interopDefault(import('../pages/auth/impersonate-user/index.vue' /* webpackChunkName: "pages/auth/impersonate-user/index" */))
const _5fcecb66 = () => interopDefault(import('../pages/reset/reset.vue' /* webpackChunkName: "pages/reset/reset" */))
const _014a0d69 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6c464f2a = () => interopDefault(import('../pages/auth/impersonate-user/_id/index.vue' /* webpackChunkName: "pages/auth/impersonate-user/_id/index" */))
const _59fe1adc = () => interopDefault(import('../pages/confirmation/_id/index.vue' /* webpackChunkName: "pages/confirmation/_id/index" */))
const _9c7074be = () => interopDefault(import('../pages/inventory/_id/index.vue' /* webpackChunkName: "pages/inventory/_id/index" */))
const _10f51b93 = () => interopDefault(import('../pages/order/_id/index.vue' /* webpackChunkName: "pages/order/_id/index" */))
const _27bb6b5a = () => interopDefault(import('../pages/product-detail/_pmid/index.vue' /* webpackChunkName: "pages/product-detail/_pmid/index" */))
const _2beb5c87 = () => interopDefault(import('../pages/reset/_hash/index.vue' /* webpackChunkName: "pages/reset/_hash/index" */))
const _24339cab = () => interopDefault(import('../pages/product-detail/_pmid/distributor/index.vue' /* webpackChunkName: "pages/product-detail/_pmid/distributor/index" */))
const _2f032cc7 = () => interopDefault(import('../pages/product-detail/_pmid/distributor/_pdid.vue' /* webpackChunkName: "pages/product-detail/_pmid/distributor/_pdid" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/auth",
    component: _d27621b8,
    name: "auth"
  }, {
    path: "/cart",
    component: _6fc6524e,
    name: "cart"
  }, {
    path: "/forgot-password",
    component: _198dd25c,
    name: "forgot-password"
  }, {
    path: "/inventory",
    component: _93a71494,
    name: "inventory"
  }, {
    path: "/login",
    component: _6a1c9f00,
    name: "login"
  }, {
    path: "/my-distributors",
    component: _c5beff78,
    name: "my-distributors"
  }, {
    path: "/order",
    component: _0372d6b0,
    name: "order"
  }, {
    path: "/product-detail",
    component: _46c13a6b,
    name: "product-detail"
  }, {
    path: "/registration",
    component: _862dc21c,
    name: "registration"
  }, {
    path: "/savings",
    component: _17977c93,
    name: "savings"
  }, {
    path: "/settings",
    component: _179f65dc,
    name: "settings"
  }, {
    path: "/wizard",
    component: _9dfc8138,
    name: "wizard"
  }, {
    path: "/auth/impersonate-user",
    component: _597e7d80,
    name: "auth-impersonate-user"
  }, {
    path: "/reset/reset",
    component: _5fcecb66,
    name: "reset-reset"
  }, {
    path: "/",
    component: _014a0d69,
    name: "index"
  }, {
    path: "/auth/impersonate-user/:id",
    component: _6c464f2a,
    name: "auth-impersonate-user-id"
  }, {
    path: "/confirmation/:id",
    component: _59fe1adc,
    name: "confirmation-id"
  }, {
    path: "/inventory/:id",
    component: _9c7074be,
    name: "inventory-id"
  }, {
    path: "/order/:id",
    component: _10f51b93,
    name: "order-id"
  }, {
    path: "/product-detail/:pmid",
    component: _27bb6b5a,
    name: "product-detail-pmid"
  }, {
    path: "/reset/:hash",
    component: _2beb5c87,
    name: "reset-hash"
  }, {
    path: "/product-detail/:pmid?/distributor",
    component: _24339cab,
    name: "product-detail-pmid-distributor"
  }, {
    path: "/product-detail/:pmid?/distributor/:pdid",
    component: _2f032cc7,
    name: "product-detail-pmid-distributor-pdid"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
