import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'
import { sortByName } from '~/components/ConnectDistributors/helper'

export const DEFAULT_FILTERS = {
  distributorId: [],
  manufacturerId: [],
  grandparentId: [], // Category
  categoryId: [], // Generic Name
  parentId: [], // Generic Name
  modifierValueId: [],
  packageQty: [],
  fromOrderHistory: false,
  searchable: '',
}

export const OPTIONS_BY_FILTER_NAME = {
  distributorId: 'distributors',
  manufacturerId: 'manufacturers',
  grandparentId: 'categories',
  categoryId: 'generics',
  parentId: 'subcategories',
  modifierValueId: 'modifiers',
  packageQty: 'packages',
}

export const DEFAULT_SORT = {
  name: 'Popularity',
  value: '-popularity',
}

export const sortDistributorList = (list = []) => {
  if (!isArrayWithLength(list)) return []
  const connected = list.filter((item) => item?.isConnected).sort(sortByName)
  const notConnected = list
    .filter((item) => !item?.isConnected)
    .sort(sortByName)
  return [...connected, ...notConnected]
}

export const getFilterItemClass = (list = [], index = 0) => {
  if (!list.length) return false
  return list.length - 1 === index
}

export const sortOrderHistoryToTop = (list = []) => {
  if (!isArrayWithLength(list)) return []
  const index = list.findIndex(
    (item) => item?.filterName === 'fromOrderHistory'
  )
  if (index !== -1) {
    const fromOrderHistoryItem = list[index]
    list.splice(index, 1)
    list.unshift(fromOrderHistoryItem)
  }
  return list
}

export const FILTER_BY_DISTRIBUTOR_ID = 'distributorId'
export const FILTER_BY_MANUFACTURER_ID = 'manufacturerId'
