
import ContentPastDue from '../ContentPastDue'
import CanceledRetryTitle from '~/components/Subscription/Canceled/CanceledRetryTitle'
export default {
  name: 'ContentCanceled',
  components: { ContentPastDue, CanceledRetryTitle },
  props: {
    success: {
      type: Boolean,
      default: false,
      required: true,
    },
    isRenewDeclined: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  computed: {
    showContentPastDue() {
      return this.isRenewDeclined
    },
  },
}
