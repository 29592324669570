import * as types from './mutation-types'
import { DEFAULT_FILTERS } from './index'

export default {
  [types.SET_FILTERS](state, payload) {
    state.filters = payload
  },
  [types.SET_DISTRIBUTORS](state, payload) {
    state.availableDistributors = payload
  },
  [types.SET_DISTRIBUTORS_PAYMENT_HISTORY](state, payload) {
    state.availableDistributorsPaymentHistory = payload
  },
  [types.CLEAR_FILTERS](state, commit) {
    state.filters = DEFAULT_FILTERS
  },
  [types.SET_BY_ORDER_FILTER_OPTIONS](state, payload = []) {
    state.byOrderFilterOptions = payload
  },
  [types.CLEAR_BY_ORDER_FILTER_OPTIONS](state) {
    state.byOrderFilterOptions = []
  },
  [types.SET_PURCHASE_ORDER_ID_FILTER](state, payload = null) {
    state.filters.orderId = payload
  },
}
