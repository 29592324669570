
import BtnTextLink from '~/ZenOne-StoryBook/components/btn-text-link'
import ui from '~/ZenOne-StoryBook/mixins/ui'
import FilterOptionsItem from '~/components/Inventory/Dialogs/FiltersDialog/FilterOptionsItem'
import { generateRandomString } from '~/ZenOne-StoryBook/helpers/random'
import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'

const OPTIONS_LIMIT = 5
const OPTIONS_SHOW_MORE_LIMIT = 10
export default {
  name: 'FilterList',
  components: { FilterOptionsItem, BtnTextLink },
  mixins: [ui],
  props: {
    filterAlias: {
      type: String,
      default: '',
      required: true,
    },
    alwaysExpanded: {
      type: Boolean,
      default: false,
    },
    shrinkLabel: {
      type: Boolean,
      default: false,
    },
    hideSearch: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
      required: true,
    },
    initialModel: {
      type: Array,
      default() {
        return []
      },
      required: true,
    },
    options: {
      type: Array,
      default() {
        return []
      },
      required: true,
    },
  },
  data() {
    return {
      search: '',
      expand: false,
      model: [],
      generateRandomString,
    }
  },
  computed: {
    hasSearchTerm() {
      return this.search !== ''
    },
    counter() {
      const { options = [] } = this
      return isArrayWithLength(options) ? options.length : 0
    },
    show() {
      return this.counter > 0
    },
    optionsFiltered() {
      const options = [...this.options]
      if (this.hasSearchTerm) {
        if (isArrayWithLength(options)) {
          return options.filter((item) => {
            if (item.name && typeof item.name === 'string') {
              return item.name
                .toLowerCase()
                .trim()
                .includes(this.search.toLowerCase())
            } else return false
          })
        }
      } else if (this.counter >= OPTIONS_SHOW_MORE_LIMIT && !this.expand) {
        options.length = 5
      }

      return options
    },
    showSearchBar() {
      return !this.hideSearch && this.counter > OPTIONS_SHOW_MORE_LIMIT
    },
    showActionBtn() {
      return !(
        this.counter < OPTIONS_SHOW_MORE_LIMIT ||
        this.hasSearchTerm ||
        this.alwaysExpanded
      )
    },
    actionBtnText() {
      return !this.expand ? 'Show more' : 'Hide'
    },
  },
  watch: {
    counter: {
      handler() {
        this.initState()
      },
    },
    initialModel() {
      this.model = this.initialModel
    },
  },
  mounted() {
    this.initState()
  },
  methods: {
    initState() {
      this.expand = this.getExpandInitial()
      this.model = this.getModelInitial()
    },
    isOptionLabelBold(option) {
      return option?.isConnected === true
    },
    getExpandInitial() {
      if (this.alwaysExpanded) return true
      return this.counter <= OPTIONS_LIMIT
    },
    getModelInitial() {
      return [...this.initialModel]
    },
    actionBtnClickHandler() {
      this.expand = !this.expand
    },
    clearSearchTerm() {
      this.search = ''
    },
    isSelected(id) {
      const { model = [] } = this
      if (!isArrayWithLength(model)) return false
      return model.includes(id)
    },
    onSelect(id) {
      let modelCopy = [...this.model]
      if (this.isSelected(id)) {
        const set = new Set(modelCopy)
        set.delete(id)
        modelCopy = [...set]
      } else {
        modelCopy.push(id)
      }
      this.model = [...modelCopy]
      this.$emit('on-filter-select', {
        filterAlias: this.filterAlias,
        value: this.model,
      })
    },
    getOptionCounter(option = {}) {
      return option?.productCount || option?.count || null
    },
    getLabel(option = {}) {
      return option?.name ? option.name.toString() : ''
    },
  },
}
