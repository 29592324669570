import actions from './actions'
import mutations from './mutations'
import {
  getInitialCard,
  getInitialPractice,
  getInitialPracticeLocation,
  getInitialUser,
} from '~/store/modules/user/helper'
const user = {
  state: {
    user: getInitialUser(),
    practiceLocation: getInitialPracticeLocation(),
    practice: getInitialPractice(),
    budget: 0,
    card: getInitialCard(),
    cardWasUpdatedOnRecharge: false,
    plStatusWasUpdatedOnRecharge: false,
    currentPracticeLocationId: null,
  },
  getters: {
    user: (state) => state.user,
    userId: (state, getters) => getters.user?.id ?? null,
    isEmailConfirmed: (state) => state.user?.emailConfirmed === 1,
    budget: (state) => state.budget,
    budgetCurrent: (state) => state?.practiceLocation?.budgetCurrent || 0,
    name: (state, getters) => getters.user?.name ?? '',
    practice: (state) => state.practice,
    practiceId: (state) => state?.practice?.id ?? null,
    practiceLocation: (state) => state.practiceLocation,
    shipToAddress: (state) => state?.practiceLocation?.address ?? {},
    savedTotal: (state) => state?.practiceLocation?.savedPriceTotal ?? 0,
    practiceLocationId: (state) => state?.practiceLocation?.id ?? null,
    practiceLocationName: (state) => state?.practiceLocation?.name ?? '',
    card: (state) => state.card,
    cardWasUpdatedOnRecharge: (state) => state.cardWasUpdatedOnRecharge,
    plStatusWasUpdatedOnRecharge: (state) => state.plStatusWasUpdatedOnRecharge,
    currentPracticeLocationId: (state) => state.currentPracticeLocationId,
  },
  mutations,
  actions,
  namespaced: true,
}

export default user
