
import Vue from 'vue'
import TextShrink from '~/ZenOne-StoryBook/components/TextShrink'
Vue.component('TextShrink', TextShrink)
export default {
  name: 'FilterOptionsItem',
  components: { TextShrink },
  props: {
    id: {
      type: Number,
      default: null,
    },
    counter: {
      type: Number,
      default: null,
    },
    initialModel: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    shrinkLabel: {
      type: Boolean,
      default: false,
    },
    shrinkLength: {
      type: Number,
      default: 15,
    },
    boldLabelText: {
      type: Boolean,
      default: false,
    },
    select: {
      type: Function,
      default: () => {},
      required: true,
    },
  },
}
