import { isObject } from 'lodash'
import * as types from './mutation-types'
import { API as api } from '~/ZenOne-StoryBook/config/api'
import { headers } from '~/ZenOne-StoryBook/config/auth'

import { RESPONSE_STATUS_OK } from '~/ZenOne-StoryBook/helpers/response'
import { mapResourceLightItem } from '~/store/modules/resources/helper'
import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'
import { isHavingProps } from '~/ZenOne-StoryBook/helpers/object'
export default {
  async FetchResourceLight(
    // eslint-disable-next-line no-empty-pattern
    {},
    { resourceName = '', propList = ['name'], addParams = {} }
  ) {
    try {
      const params =
        addParams && isObject(addParams) && isHavingProps(addParams)
          ? { ...addParams }
          : {}
      const res = await this.$axios.get(
        `${resourceName}?fields[${resourceName}]=${propList.join(',')}`,
        {
          headers,
          params,
        }
      )
      if (res?.status !== RESPONSE_STATUS_OK) {
        throw new Error('Response error')
      }
      const list = res?.data?.data
      return isArrayWithLength(list)
        ? list.map((item) => mapResourceLightItem(item, propList))
        : []
    } catch (e) {
      const error = `Get ${resourceName}: ${
        e?.response?.data?.errors?.[0]?.detail ?? e
      }`
      console.error(error)
      return []
    }
  },
  async FetchDistributorsLight({ dispatch }, propList = ['name']) {
    return await dispatch('FetchResourceLight', {
      resourceName: `${api.DISTRIBUTORS}`,
      propList,
      addParams: {
        full: true,
        sort: 'name',
        'filter[status]': 'active',
      },
    })
  },
  async FetchRootCategoriesLight({ dispatch }, propList = ['name']) {
    return await dispatch('FetchResourceLight', {
      resourceName: `${api.CATEGORIES}`,
      propList,
      addParams: {
        full: true,
        'filter[level]': 1,
        sort: 'name',
      },
    })
  },
  async FetchCategoryChildrenByParentLight(
    // for subcategories and generic names
    { dispatch },
    { categoryIds = [], propList = ['name'] }
  ) {
    return await dispatch('FetchResourceLight', {
      resourceName: `${api.CATEGORIES}`,
      propList,
      addParams: {
        full: true,
        'filter[parentId]': categoryIds.join(','),
        sort: 'name',
      },
    })
  },
  async SetDistributorsLight({ commit, dispatch }) {
    try {
      commit(
        types.SET_DISTRIBUTORS_LIGHT,
        await dispatch('FetchDistributorsLight')
      )
    } catch (e) {
      console.error('Set DistributorsLight: ', e)
    }
  },
  async SetRootCategoriesLight({ commit, dispatch }) {
    try {
      commit(
        types.SET_ROOT_CATEGORIES_LIGHT,
        await dispatch('FetchRootCategoriesLight')
      )
    } catch (e) {
      console.error('Set RootCategoriesLight: ', e)
    }
  },
}
