import axios from 'axios'
import { getBaseUrl } from '~/config/api'

if (
  process.env.NODE_ENV === 'production' &&
  window.location.hostname === 'my.zenone.com'
) {
  document.addEventListener('tidioChat-conversationStart', function () {
    setTimeout(async function () {
      const tidioChatIframe = document.getElementById('tidio-chat-iframe')
      let messageVisitor = ''
      let emailVisitor = ''

      if (tidioChatIframe) {
        const messageVisitorCollection =
          tidioChatIframe.contentWindow.document.getElementsByClassName(
            'message-visitor'
          )

        const emailVisitorCollection =
          tidioChatIframe.contentWindow.document.getElementsByClassName(
            'always-online'
          )

        if (emailVisitorCollection.length > 0) {
          emailVisitor =
            emailVisitorCollection[0].getElementsByTagName('input')[0].value
        }

        if (messageVisitorCollection.length > 0) {
          messageVisitor = messageVisitorCollection[0].textContent
        }
      }

      if (messageVisitor && emailVisitor) {
        await axios.post(
          `${getBaseUrl(
            process.env.NODE_ENV
          )}/api/slack/send/offline-conversation`,
          {
            email: emailVisitor,
            message: messageVisitor,
          },
          {
            baseURL: null,
            headers: {
              Accept: 'application/vnd.api+json',
              'Content-Type': 'application/vnd.api+json',
            },
          }
        )
      }
    })
  })
}
