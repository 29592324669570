import * as types from './mutation-types'
import { setLocalStorage } from '~/ZenOne-StoryBook/helpers/localStorage'

export default {
  async SetUserPracticeLocation({ commit }) {
    try {
      await this.$auth.fetchUser()

      const user = this.$auth.user
      const pl = user.practiceLocation

      setLocalStorage({ name: 'practiceLocationId', data: pl.id })
      setLocalStorage({ name: 'practiceLocation', data: pl })
      setLocalStorage({ name: 'practiceId', data: pl.practice.id })
      setLocalStorage({ name: 'practice', data: pl.practice })
      setLocalStorage({ name: 'user', data: user })

      commit(types.SET_USER, user)
      commit(types.SET_PRACTICE_LOCATION, pl)
      commit(types.SET_PRACTICE, pl.practice)
      commit(types.SET_PRACTICE_ID, pl.practiceId)
      commit(types.SET_BUDGET, pl.budget ?? 0)
      commit(types.SET_CARD, pl.params?.card ?? {})
      commit(types.SET_BUDGET_CURRENT, pl.budgetCurrent)
      commit(types.SET_AUTHORIZED_ONLY, pl.authorizedSavingsOnly)
      commit('shoppingCart/SET_CART_ITEMS_TOTAL_COUNT', pl.cartCounter, { root: true })

      return pl
    } catch (e) {
      console.error('SetUserData: ', e)
      return false
    }
  },
}
