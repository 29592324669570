import { mapGetters } from 'vuex'
import storage from '~/ZenOne-StoryBook/mixins/localStorage'
import ui from '~/ZenOne-StoryBook/mixins/ui'
import { status as practiceLocationStatusList } from '~/ZenOne-StoryBook/components/Distributor/DistributorPractices/helper'
import { isNotUndefined } from '~/ZenOne-StoryBook/helpers/types'
import { ERROR } from '~/ZenOne-StoryBook/helpers/ui'

export default {
  mixins: [storage, ui],
  computed: {
    ...mapGetters('user', ['practiceLocation', 'practice', 'card']),
    practiceLocationId() {
      return this.practiceLocation.id
    },
    practiceId() {
      return this.practice.id
    },
    practiceLocationStatus() {
      return this?.practiceLocation?.status ?? ''
    },
    isPracticeLocationPastDue() {
      return this.practiceLocationStatus === practiceLocationStatusList.PAST_DUE
    },
    isPracticeLocationCanceled() {
      return this.practiceLocationStatus === practiceLocationStatusList.CANCELED
    },
    isPracticeLocationInactive() {
      return this.practiceLocationStatus === practiceLocationStatusList.INACTIVE
    },
    showRechargeSubscriptionPanel() {
      return this.isPracticeLocationPastDue || this.isPracticeLocationCanceled
    },
    cardNumber() {
      return this?.practiceLocation?.params?.card?.number ?? ''
    },
    renewalAmount() {
      const renewalAmount = this?.practiceLocation?.renewalAmount
      return isNotUndefined(renewalAmount) ? renewalAmount : 0
    },
  },
  methods: {
    async rechargeSubscription(isRenew = false) {
      const { practiceLocationId = null } = this
      const res = await this.$store.dispatch(
        'subscription/RechargeSubscription',
        { practiceLocationId, isRenew }
      )
      if (!res) {
        this.showAlert({
          type: ERROR,
          content: 'Recharge subscription payment was failed',
        })
      }
    },
  },
}
