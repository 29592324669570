import { isNull } from 'lodash'
import {
  TOP_10_MOST,
  TOP_10_ORDERED,
  TOP_10_RECENTLY,
} from '~/components/Catalog/constants'
import { findTopItem, mapProductStatistics } from '~/components/Catalog/helper'
import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'
import { sortDistributorList } from '~/components/Catalog/CatalogFilters/helper'
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from '~/store/modules/catalog/index'
import { API as api } from '~/ZenOne-StoryBook/config/api'
import { mapResourceLightItem } from '~/store/modules/resources/helper'
import {
  mapBasicOptions,
  mapModifiersOptions,
  mapPackagesOptions,
} from '~/store/modules/catalog/helper'

export default {
  ClearFilterOptions({ commit }) {
    commit('CLEAR_FILTER_OPTIONS')
  },
  ClearFilters({ commit }) {
    commit('CLEAR_FILTERS')
    commit('CLEAR_FILTER_OPTIONS')
  },
  UpdateFiltersOptions({ commit, dispatch, getters }, filterOptions = {}) {
    commit(
      'SET_MODIFIERS_FILTER_OPTIONS',
      mapModifiersOptions(filterOptions?.modifiers ?? {})
    )
    const basicOptionsList = [
      'categories',
      'distributors',
      'generics',
      'manufacturers',
      'subcategories',
    ]
    for (const basicOption of basicOptionsList) {
      const data = mapBasicOptions(filterOptions[basicOption], basicOption)
      commit(`SET_${basicOption.toUpperCase()}_FILTER_OPTIONS`, data)
    }
    commit(
      'SET_PACKAGES_FILTER_OPTIONS',
      mapPackagesOptions(filterOptions?.packages ?? [])
    )
  },
  async FetchAndSetTops({ commit, dispatch, getters }) {
    try {
      const res = await Promise.all([
        !getters.hasTopOrdered
          ? dispatch('FetchProductsTop', TOP_10_ORDERED)
          : '',
        !getters.hasTopRecentlyPurchased
          ? dispatch('FetchProductsTop', TOP_10_RECENTLY)
          : '',
        !getters.hasMostPurchased
          ? dispatch('FetchProductsTop', TOP_10_MOST)
          : '',
      ])
      if (!isArrayWithLength(res)) return false
      const [ordered = [], recentlyPurchased = [], mostPurchased = []] = res
      commit('SET_TOPS', {
        ordered: {
          productList: ordered,
        },
        recentlyPurchased: {
          productList: recentlyPurchased,
        },
        mostPurchased: {
          productList: mostPurchased,
        },
      })
    } catch (e) {
      console.error('FetchAndSetTops', e)
    }
  },
  async FetchProductsTop({ commit, state, dispatch }, type = null) {
    try {
      if (!type) throw new Error('No type for top request')
      let entity = null
      switch (type) {
        case TOP_10_MOST:
          entity = 'topPurchased'
          break
        case TOP_10_ORDERED:
          entity = 'topOrdered'
          break
        case TOP_10_RECENTLY:
          entity = 'recentlyPurchased'
          break
      }
      const { data } = await dispatch(
        'jvZen/get',
        [`product-distributors/${entity}`, {}],
        { root: true }
      )
      return mapProductStatistics(data)
    } catch (e) {
      console.error('fetchProductsTop', e)
    }
  },
  async SetDistributorList({ commit, dispatch }) {
    const distributors = sortDistributorList(
      await dispatch(
        'resources/FetchDistributorsLight',
        ['name', 'productCount', 'isConnected'],
        {
          root: true,
        }
      )
    )
    commit('SET_DISTRIBUTORS_OVERALL', distributors)
  },
  async SetManufacturerList(
    { commit, state, dispatch },
    pageNumber = DEFAULT_PAGE_NUMBER
  ) {
    const { data = [], pagination = {} } = await dispatch(
      'FetchManufacturerList',
      pageNumber
    )
    commit('SET_MANUFACTURER_FILTER_OPTIONS', {
      data,
      isIntersection: pageNumber >= 2,
    })
    return { pagination }
  },
  async FetchManufacturerList(
    { commit, state, dispatch },
    pageNumber = DEFAULT_PAGE_NUMBER
  ) {
    try {
      const params = {
        'filter[hasActiveProducts]': true,
        'filter[status]': 'active',
        'page[number]': pageNumber,
        'page[size]': DEFAULT_PAGE_SIZE,
        sort: 'name',
      }
      const { data = [], meta = {} } = await dispatch(
        'jvZen/get',
        [
          `${api.MANUFACTURERS}?fields[${api.MANUFACTURERS}]=name,productCount`,
          { params },
        ],
        {
          root: true,
        }
      )
      const list = !isArrayWithLength(data)
        ? []
        : data.map((item) =>
            mapResourceLightItem(item, ['name', 'productCount'])
          )
      return { data: list, pagination: meta.page ?? {} }
    } catch (e) {
      console.error('fetchManufacturerList', e)
    }
  },
  SetFilterManufacturer(
    { commit, state, dispatch },
    { value, vendorName = '' }
  ) {
    commit('SET_FILTER_MANUFACTURER', { value })
  },
  updateTopItemsInCartState({ commit, getters }, { productDistributors = [] }) {
    if (!isArrayWithLength(productDistributors)) return false
    const tops = { ...getters.tops }
    const ordered = tops?.ordered?.productList ?? []
    const recentlyPurchased = tops?.recentlyPurchased?.productList ?? []
    const mostPurchased = tops?.mostPurchased?.productList ?? []
    if (
      !isArrayWithLength(ordered) &&
      !isArrayWithLength(recentlyPurchased) &&
      !isArrayWithLength(mostPurchased)
    ) {
      return false
    }

    const list = {
      ordered,
      recentlyPurchased,
      mostPurchased,
    }

    for (const productDistributor of productDistributors) {
      const productDistributorId =
        productDistributor?.productDistributorId ?? null
      if (!productDistributorId) continue
      const topItemsList = findTopItem(productDistributorId, list)
      if (!isArrayWithLength(topItemsList)) return false
      for (const topItem of topItemsList) {
        const { typeName = '', index = null } = topItem
        if (typeName && !isNull(index)) {
          const { cartItem = {}, addedShoppingCart = true } = productDistributor
          commit('SET_TOP_ITEM', {
            type: typeName,
            index,
            item: {
              ...list[typeName][index],
              addedShoppingCart,
              cartItem,
            },
          })
        }
      }
    }

    return true
  },
  setGeneralProductWorkspace({ commit }, { value = {} }) {
    commit('SET_GENERAL_PRODUCT_WORKSPACE', { value })
  },
  clearGeneralProductWorkspace({ commit }) {
    commit('SET_GENERAL_PRODUCT_WORKSPACE', { value: null })
  },
}
