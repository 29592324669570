import { api, getBaseUrl } from '~/config/api'
import { headers } from '~/ZenOne-StoryBook/config/auth'
import { isHavingProps } from '~/ZenOne-StoryBook/helpers/object'
import { mapSuggestListForCategories } from '~/components/Header/TopMenuSearchBarCatalog/helper'
import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'
import { capitalizeFirstLetter } from '~/ZenOne-StoryBook/helpers/format'

export default {
  OnSelectHistoryItem({ commit }, { searchQuery = '' }) {
    commit('SET_SEARCH_TERM', searchQuery)
    commit('SET_MAKE_SEARCH_REQUEST_TRIGGER')
  },
  OnSelectDropdownAttribute({ commit, getters }, { suggest = {} }) {
    const { key: keyName = '', value: modifierValueName = '' } = suggest
    if (!keyName || !modifierValueName) return false

    const {
      category_tree_ids: [
        grandparentId = null,
        parentId = null,
        categoryId = null,
      ],
      modifier_value_id: modifierValueId = null,
    } = suggest
    const capitalizedName = `${capitalizeFirstLetter(modifierValueName)}`
    const nameFormatted = `${keyName} ${capitalizedName}`
    commit('SET_SEARCH_TERM', nameFormatted)
    commit(
      'catalog/UPDATE_FILTERS',
      {
        grandparentId: grandparentId ? [grandparentId] : [],
        categoryId: categoryId ? [categoryId] : [],
        parentId: parentId ? [parentId] : [],
        modifierValueId: modifierValueId ? [modifierValueId] : [],
      },
      { root: true }
    )
    commit('SET_MAKE_SEARCH_REQUEST_TRIGGER')
  },
  OnSelectDropdownCategory(
    { commit, dispatch, getters },
    { type = '', suggest = {} }
  ) {
    const subcategoryId = suggest?.category_id ?? null
    if (!subcategoryId) return false
    const {
      rootCategory: { category_id: rootCategoryId = null },
      genericNameList = [],
    } = suggest
    let filters = {}
    if (type === 'category') {
      filters = {
        ...filters,
        grandparentId: rootCategoryId ? [parseInt(rootCategoryId)] : [],
        categoryId: [],
        parentId: [],
      }
    } else if (type === 'subcategory') {
      filters = {
        ...filters,
        grandparentId: rootCategoryId ? [parseInt(rootCategoryId)] : [],
        parentId: subcategoryId ? [parseInt(subcategoryId)] : [],
        categoryId: [],
      }
    } else if (type === 'genericName') {
      const genericId = genericNameList.map((generic) =>
        parseInt(generic.category_id)
      )
      filters = {
        ...filters,
        grandparentId: rootCategoryId ? [parseInt(rootCategoryId)] : [],
        categoryId: [parseInt(genericId)],
        parentId: subcategoryId ? [parseInt(subcategoryId)] : [],
      }
    }
    commit('catalog/UPDATE_FILTERS', filters, { root: true })
    commit('SET_MAKE_SEARCH_REQUEST_TRIGGER')
  },
  async RemoveHistoryItemByQuery(
    { commit, dispatch, getters },
    searchQuery = ''
  ) {
    try {
      await this.$axios.delete(`user-searches/destroyByQuery`, {
        data: {
          headers,
          search_query: searchQuery,
        },
      })
      commit(
        'SET_HISTORY',
        [...getters.history].filter(
          (item) => item?.attributes?.search_query !== searchQuery
        )
      )
      commit('RESET_DROPDOWN_POSITION')
    } catch (e) {
      console.error(`Search history delete error: ${e}`)
    } finally {
      commit('SET_CLICKED_ON_DELETE_HISTORY_ITEM', false)
    }
  },
  async FetchHistory({ commit }) {
    try {
      const {
        data: { data = [] },
      } = await this.$axios.get('user-searches/show')
      commit('SET_HISTORY', isArrayWithLength(data) ? data : [])
    } catch (e) {
      console.error('onSetSearchHistory', e)
    }
  },
  async FetchSuggestions({ commit, dispatch, getters }, searchQuery = '') {
    try {
      if (searchQuery.length === 0) {
        return
      }

      // duplicate network call
      if (getters.mostRecentSuggestString === searchQuery) {
        return null
      }
      commit('SET_MOST_RECENT_SUGGEST_STRING', searchQuery)
      commit('SET_IS_LOADING_SUGGESTIONS', true)
      const { data } = await this.$axios.post(
        `${getBaseUrl(process.env.NODE_ENV)}/api/${api.SEARCH}/${api.SUGGEST}`,
        {
          data: {
            type: 'search',
            attributes: {
              searchable: searchQuery,
            },
          },
        },
        {
          headers,
        }
      )

      // search string used in network call is stale. Ignore results.
      if (getters.mostRecentSuggestString !== searchQuery) {
        return {}
      }

      commit('SET_IS_LOADING_SUGGESTIONS', false)

      if (!data || !isHavingProps(data)) {
        // no results.
        commit('SET_CATEGORY_LIST', [])
        commit('SET_MODIFIER_VALUE_LIST', [])
      } else {
        const suggestForCategoriesList = mapSuggestListForCategories(data)
        commit('SET_CATEGORY_LIST', Object.values(suggestForCategoriesList))
        commit('SET_MODIFIER_VALUE_LIST', data?.key_values ?? [])
      }
    } catch (e) {
      console.error(`Get suggestions error: ${e}`)
      return {}
    }
  },
}
