
export default {
  name: 'FolderNavTextButton',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    clickHandler() {
      this.$emit('on-create-folder-btn-click')
    },
  },
}
