import * as types from './mutation-types'
import { DEFAULT_POSITION } from './helper'

export default {
  [types.SET_SEARCH_TERM](state, payload) {
    state.searchTerm = payload
  },
  [types.SET_MAKE_SEARCH_REQUEST_TRIGGER](state) {
    state.makeSearchRequestTrigger = !state.makeSearchRequestTrigger
  },
  [types.SET_DROPDOWN_POSITION](state, payload) {
    state.dropdownPosition = payload
  },
  [types.RESET_DROPDOWN_POSITION](state, payload) {
    state.dropdownPosition = DEFAULT_POSITION
  },
  [types.SET_HISTORY](state, payload) {
    state.history = payload
  },
  [types.SET_CLICKED_ON_DELETE_HISTORY_ITEM](state, payload) {
    state.clickedOnDeleteHistoryItem = payload
  },
  [types.SET_MOST_RECENT_SUGGEST_STRING](state, payload) {
    state.mostRecentSuggestString = payload
  },
  [types.SET_IS_LOADING_SUGGESTIONS](state, payload) {
    state.isLoadingSuggestions = payload
  },
  [types.SET_CATEGORY_LIST](state, payload) {
    state.categoryList = payload
  },
  [types.SET_MODIFIER_VALUE_LIST](state, payload) {
    state.modifierValueList = payload
  },
}
