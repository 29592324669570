
import { mapGetters } from 'vuex'
import defaultLayout from '~/layouts/default'
import LeftMenu from '~/components/LeftMenu'
import NotificationAuthorizationAdminPortal from '~/components/NotificationAuthorizationAdminPortal'
import Resizer from '~/components/Resizer'
import {
  getLocalStorage,
  getLocalStorageProp,
  setLocalStorage,
} from '~/ZenOne-StoryBook/helpers/localStorage'
import { INVENTORY_LS_ALIAS } from '~/components/Inventory/helper'

const DEFAULT_LEFT_MENU_WIDTH = '320px'
export default {
  name: 'LeftMenuLayout',
  components: {
    Resizer,
    LeftMenu,
    NotificationAuthorizationAdminPortal,
  },
  extends: defaultLayout,
  data() {
    return {
      leftMenuWidth: '',
      leftMenuRef: null,
      sticky: false,
    }
  },
  computed: {
    ...mapGetters({
      showTops: 'catalog/showTops',
    }),
    getWidthStyle() {
      if (!this.isInventoryRoute) return 'width: 250px'
      else return `width: ${this.leftMenuWidth}`
    },
    showProductList() {
      return this.isCatalogRoute && !this.showTops
    },
  },
  watch: {
    isInventoryRoute: {
      immediate: true,
      handler(value) {
        if (value) {
          this.leftMenuWidth = this.getInitialLeftMenuWidth()
        }
      },
    },
  },
  mounted() {
    this.leftMenuRef = this.$refs?.leftMenu ?? null
  },
  methods: {
    getInitialLeftMenuWidth() {
      let width = getLocalStorageProp(INVENTORY_LS_ALIAS, 'foldersNavWidth')
      if (width) return width
      else {
        const lsData = getLocalStorage(INVENTORY_LS_ALIAS)
        width = DEFAULT_LEFT_MENU_WIDTH
        lsData.foldersNavWidth = width
        setLocalStorage({
          name: INVENTORY_LS_ALIAS,
          data: lsData,
        })
        return width
      }
    },
    onResize({ width = '' }) {
      if (!width) return false
      this.leftMenuWidth = `${width}px`
    },
  },
}
