
import { mapGetters } from 'vuex'
import defaultLayout from '~/layouts/default'
import LeftMenu from '~/components/LeftMenu'

export default {
  name: 'CatalogLayout',
  components: {
    LeftMenu,
  },
  extends: defaultLayout,
  computed: {
    ...mapGetters({
      showTops: 'catalog/showTops',
    }),
  },
  watch: {
    showFadeFS: {
      handler(value) {
        const wrapper = this.$refs.wrapper
        if (!wrapper) return false
        wrapper.style.overflowY = value ? 'hidden' : 'scroll'
      },
    },
  },
}
