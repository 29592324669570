export const ROOT_ROUTE_NAME = 'index'
export const CART_ROUTE_NAME = 'cart'
export const WORKSPACE_ROUTE_NAME = 'workspace-id'
export const INVENTORY_ROUTE_NAME = 'inventory-id'
export const SETTINGS_ROUTE_NAME = 'settings'
export const REGISTRATION_ROUTE_NAME = 'registration'
export const DETAIL_PRODUCT_CARD_ROUTE_NAME =
  'product-detail-pmid-distributor-pdid'
export const MY_DISTRIBUTORS_ROUTE_NAME = 'my-distributors'
export const LOGIN_ROUTE_NAME = 'login'
export const FORGOT_PASSWORD_ROUTE_NAME = 'forgot-password'
export const RESET_PASSWORD_HASH_ROUTE_NAME = 'reset-hash'
export const AUTH_ROUTE_NAME = 'auth'
export const AUTH_IMPERSONATE_USER_ROUTE_NAME = 'auth-impersonate-user'
export const AUTH_IMPERSONATE_USER_ID_ROUTE_NAME = 'auth-impersonate-user-id'
export const WIZARD_ROUTE_NAME = 'wizard'
export const EMAIL_CONFIRMATION_ID_ROUTE_NAME = 'confirmation-id'
