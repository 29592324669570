
import { mapActions, mapGetters, mapMutations } from 'vuex'
import SavedTotal from './SavedTotal'
import { isNotUndefined } from '~/ZenOne-StoryBook/helpers/types'
export default {
  name: 'TopBar',
  components: { SavedTotal },
  computed: {
    ...mapGetters({
      workspaces: `workspace/workspaces`,
      folders: `inventory/folders`,
      rootFolderId: `inventory/rootFolderId`,
      user: `user/user`,
    }),
    inventoryLinkPath() {
      return `/inventory/${this.rootFolderId}`
    },
    isOrderHistory() {
      return this.$route.path === '/' && this.$route.query.orderHistory
    },
    isCatalog() {
      return this.$route.path === '/' && !this.$route.query.orderHistory
    },
    isInventory() {
      return this.$route.path.includes('inventory')
    },
    isSavings() {
      return this.$route.path.includes('savings')
    },
    hasAccessToCatalog() {
      return !!this.user?.permissions?.hasAccessToCatalog
    },
  },
  methods: {
    ...mapActions('catalog', ['ClearFilters']),
    ...mapMutations({
      setOrderHistoryFilter: 'catalog/SET_ORDER_HISTORY',
    }),
    toCatalog() {
      this.setOrderHistoryFilter(false)
      if (!isNotUndefined(this.$route?.query?.orderHistory)) {
        setTimeout(() => {
          this.$router.push('/')
        }, 100)
      }
    },
    toOrderHistory() {
      this.setOrderHistoryFilter(true)
      setTimeout(() => {
        this.$router.push('/')
      }, 100)
    },
  },
}
