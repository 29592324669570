
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'HistoryMenuItem',
  props: {
    index: {
      type: Number,
      default: 0,
    },
    searchQuery: {
      type: Object,
      default() {
        return {}
      },
      required: true,
    },
  },
  data() {
    return {
      isHovered: false,
    }
  },
  computed: {
    ...mapGetters({
      dropdownPosition: 'search/dropdownPosition',
    }),
    value() {
      return this.searchQuery?.attributes?.search_query ?? null
    },
  },
  methods: {
    ...mapActions({
      RemoveHistoryItemByQuery: 'search/RemoveHistoryItemByQuery',
      OnSelectHistoryItem: 'search/OnSelectHistoryItem',
    }),
    ...mapMutations({
      setClickedOnDeleteHistoryItem:
        'search/SET_CLICKED_ON_DELETE_HISTORY_ITEM',
      setSearchTerm: 'search/SET_SEARCH_TERM',
      makeSearchRequest: 'search/SET_MAKE_SEARCH_REQUEST_TRIGGER',
    }),
    clickHandler() {
      const searchQuery = this.value ?? null
      if (!searchQuery) return false
      this.OnSelectHistoryItem({ searchQuery })
    },
    async deleteItemClickHandler() {
      this.setClickedOnDeleteHistoryItem(true)
      const searchQuery = this.value ?? null
      if (!searchQuery) {
        this.setClickedOnDeleteHistoryItem(false)
        return false
      }
      await this.RemoveHistoryItemByQuery(searchQuery)
      this.$emit('on-search-input-focus')
    },
  },
}
