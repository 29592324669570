import * as types from './mutation-types'
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE } from './index'
import {
  DEFAULT_FILTERS,
  DEFAULT_SORT,
} from '~/components/Catalog/CatalogFilters/helper'
import { isHavingProps } from '~/ZenOne-StoryBook/helpers/object'
import {
  getLocalStorage,
  saveLocalStorageProp,
  setLocalStorage,
} from '~/ZenOne-StoryBook/helpers/localStorage'
import { CATALOG_ALIAS } from '~/components/Catalog/helper'
import { saveFilterOptionsToLS } from '~/store/modules/catalog/helper'

export default {
  [types.SET_TOPS](state, payload) {
    state.tops = payload
  },
  [types.SET_TOP_ITEM](state, { type = '', index = 0, item = {} }) {
    if (
      !type ||
      !isHavingProps(item) ||
      !state?.tops?.[type]?.productList?.[index]
    )
      return false
    const productList = [...state?.tops?.[type].productList]
    productList[index] = { ...item }
    state.tops[type].productList = [...productList]
  },
  [types.SET_ROOT_CATEGORY_LIST](state, payload) {
    state.rootCategoryList = payload
  },
  [types.SET_SORT](state, payload) {
    state.sort = { ...payload }
  },
  [types.SET_PAGE_SIZE](
    state,
    { pageSize = DEFAULT_PAGE_SIZE, onlyPageSize = false }
  ) {
    state.pageSize = pageSize
    if (onlyPageSize) return false
    state.pageNumber = DEFAULT_PAGE_NUMBER
  },
  [types.SET_PAGE_NUMBER](state, payload) {
    state.pageNumber = payload
  },
  [types.CLEAR_FILTERS](state, resetPageNumber = true) {
    state.sort = DEFAULT_SORT
    if (resetPageNumber) {
      state.pageNumber = DEFAULT_PAGE_NUMBER
    }
    state.filters = { ...DEFAULT_FILTERS } // re-assign to trigger non-deep watchers.
  },
  [types.SET_FILTERS](state, { filters = {}, resetPageNumber = true }) {
    state.sort = DEFAULT_SORT
    if (resetPageNumber) {
      state.pageNumber = DEFAULT_PAGE_NUMBER
    }
    state.filters = castFilterValuesAsIntWhereNeeded(filters) // re-assign to trigger non-deep watchers.
  },
  [types.SET_FILTERS_ONLY](state, payload) {
    state.filters = payload
    let lsData = getLocalStorage(CATALOG_ALIAS)
    lsData = {
      ...lsData,
      filters: { ...payload },
    }
    setLocalStorage({
      name: CATALOG_ALIAS,
      data: lsData,
    })
  },
  [types.UPDATE_FILTERS](state, payload) {
    state.filters = {
      ...state.filters,
      ...castFilterValuesAsIntWhereNeeded(payload),
    } // re-assign to trigger non-deep watchers.
  },
  [types.SET_FILTER_DISTRIBUTOR](state, { value }) {
    state.sort = DEFAULT_SORT
    state.pageNumber = DEFAULT_PAGE_NUMBER
    const newFilters = { ...state.filters }
    newFilters.distributorId = Array.isArray(value) ? value : [value]
    state.filters = castFilterValuesAsIntWhereNeeded(newFilters) // re-assign to trigger non-deep watchers.
  },
  [types.SET_FILTER_MANUFACTURER](state, { value }) {
    state.sort = DEFAULT_SORT
    state.pageNumber = DEFAULT_PAGE_NUMBER
    const newFilters = { ...DEFAULT_FILTERS }
    newFilters.manufacturerId = value
    state.filters = newFilters
  },
  [types.SET_ORDER_HISTORY](state, payload) {
    const newFilters = { ...state.filters }
    newFilters.fromOrderHistory = payload
    state.filters = newFilters // re-assign to trigger non-deep watchers.
  },
  [types.SET_SEARCH](state, payload) {
    state.sort = DEFAULT_SORT
    state.pageNumber = DEFAULT_PAGE_NUMBER
    const newFilters = { ...state.filters }
    newFilters.searchable = payload
    state.filters = newFilters // re-assign to trigger non-deep watchers.
  },
  [types.SET_IS_FETCHING](state, payload) {
    state.isFetching = payload
  },
  [types.SET_GENERAL_PRODUCT_WORKSPACE](state, { value = {} }) {
    state.generalProductWorkspace = value
  },
  [types.SET_DISTRIBUTORS_OVERALL](state, payload = []) {
    saveLocalStorageProp(CATALOG_ALIAS, 'distributorsOverall', payload)
    state.distributorsOverall = payload
  },
  [types.CLEAR_FILTER_OPTIONS](state) {
    const filterOptions = {
      distributors: [],
      packages: [],
      categories: [],
      subcategories: [],
      generics: [],
      modifiers: [],
      manufacturers: [],
    }
    state.filterOptions = { ...filterOptions }
    for (const filterOptionsKey in filterOptions) {
      saveFilterOptionsToLS({ data: [], option: filterOptionsKey })
    }
  },
  [types.SET_DISTRIBUTORS_FILTER_OPTIONS](state, payload) {
    saveFilterOptionsToLS({ data: payload, option: 'distributors' })
    state.filterOptions.distributors = payload
  },
  [types.SET_PACKAGES_FILTER_OPTIONS](state, payload) {
    saveFilterOptionsToLS({ data: payload, option: 'packages' })
    state.filterOptions.packages = payload
  },
  [types.SET_CATEGORIES_FILTER_OPTIONS](state, payload) {
    saveFilterOptionsToLS({ data: payload, option: 'categories' })
    state.filterOptions.categories = payload
  },
  [types.SET_SUBCATEGORIES_FILTER_OPTIONS](state, payload) {
    saveFilterOptionsToLS({ data: payload, option: 'subcategories' })
    state.filterOptions.subcategories = payload
  },
  [types.SET_GENERICS_FILTER_OPTIONS](state, payload) {
    saveFilterOptionsToLS({ data: payload, option: 'generics' })
    state.filterOptions.generics = payload
  },
  [types.SET_MODIFIERS_FILTER_OPTIONS](state, payload) {
    saveFilterOptionsToLS({ data: payload, option: 'modifiers' })
    state.filterOptions.modifiers = payload
  },
  [types.SET_MANUFACTURERS_FILTER_OPTIONS](state, payload) {
    saveFilterOptionsToLS({ data: payload, option: 'manufacturers' })
    state.filterOptions.manufacturers = payload
  },
}

function castFilterValuesAsIntWhereNeeded(filters) {
  const copy = { ...filters }
  if (copy.categoryId) {
    copy.categoryId = copy.categoryId.map((i) => parseInt(i))
  }
  if (copy.parentId) {
    copy.parentId = copy.parentId.map((i) => parseInt(i))
  }
  if (copy.grandparentId) {
    copy.grandparentId = copy.grandparentId.map((i) => parseInt(i))
  }
  if (copy.distributorId) {
    copy.distributorId = copy.distributorId.map((i) => parseInt(i))
  }
  if (copy.manufacturerId) {
    copy.manufacturerId = copy.manufacturerId.map((i) => parseInt(i))
  }
  if (copy.modifierValueId) {
    copy.modifierValueId = copy.modifierValueId.map((i) => parseInt(i))
  }
  if (copy.packageQty) {
    copy.packageQty = copy.packageQty.map((i) => parseInt(i))
  }
  return copy
}
