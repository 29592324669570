import { isNull } from 'lodash/lang'
import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'
import { isObjectNotUndefinedHasProps } from '~/ZenOne-StoryBook/helpers/object'

export const isMovingParentFolderToChild = (
  itemId = null,
  destinationId = null,
  foldersTree = []
) => {
  const rootChildren = foldersTree[0].children
  const item = getItemById(rootChildren, itemId)
  const itemChildren = item?.children ?? []
  if (!isArrayWithLength(itemChildren)) return false
  const destination = getItemById(itemChildren, destinationId)
  return !isNull(destination) && isObjectNotUndefinedHasProps(destination)
}

export const isRootFolderHasChildren = (foldersTree = []) => {
  const rootChildren = foldersTree?.[0]?.children ?? []
  return isArrayWithLength(rootChildren)
}

const getItemById = (items = [], id = null) => {
  const searchById = (acc, el) => {
    if (el?.id === id) return el
    if (el?.children) return el.children.reduce(searchById, acc)
    return acc
  }
  return items.reduce(searchById, null)
}
