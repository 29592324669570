
import ButtonRoundWhiteOutlined from '~/components/Buttons/ButtonRound/ButtonRoundWhiteOutlined'
import subscriptionRetry from '~/mixins/subscriptionRetry'

export default {
  name: 'RechargeActions',
  components: {
    ButtonRoundWhiteOutlined,
  },
  mixins: [subscriptionRetry],
  props: {
    showDialogPaymentInfo: {
      type: Function,
      default: () => {},
      required: true,
    },
    cardNumber: {
      type: String,
      default: '',
    },
  },
  computed: {
    showRetryActions() {
      const {
        isPracticeLocationPastDue = false,
        isPracticeLocationCanceled = false,
        isRetryBtnClicked = false,
        isRenewDeclined = false,
      } = this
      return (
        isPracticeLocationPastDue ||
        (isPracticeLocationCanceled && (isRetryBtnClicked || isRenewDeclined))
      )
    },
    showRenewBtn() {
      const {
        isPracticeLocationCanceled = false,
        isRetryBtnClicked = false,
        isRenewDeclined = false,
      } = this
      return (
        isPracticeLocationCanceled && !isRetryBtnClicked && !isRenewDeclined
      )
    },
  },
  methods: {
    updateCreditCardBtnClickHandler() {
      this.showDialogPaymentInfo()
    },
  },
}
