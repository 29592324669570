import moment from 'moment'
import pluralize from 'pluralize'
import { isNull } from 'lodash'
import {
  isArrayWithLength,
  isNotUndefined,
} from '~/ZenOne-StoryBook/helpers/types'

export const DEFAULT_PAGE_SIZE = 35
export const INVENTORY_LS_ALIAS = 'ZenOne__Consumers__Inventory'

export const mapFolderItemsList = (data = [], folderColumnsVisibility = {}) => {
  const result = {
    list: [],
  }
  if (!isArrayWithLength(data)) return result
  const list = data
    .map((item) => mapFolderItem(item, folderColumnsVisibility))
    .filter((item) => !isNull(item?.id) && item.id > 0)
  result.list = [...list]
  return result
}

export const mapFolderItem = (item = {}, folderColumnsVisibility = {}) => {
  const id = item?.id ?? null
  const cartQty = item?.cartQty ?? 0
  const quantities = getQuantities(item, folderColumnsVisibility)
  return {
    id,
    ...quantities,
    checked: false,
    distributorName: item?.distributorName ?? '',
    isActiveStatus: item?.isActiveStatus ?? false,
    folderId: item?.workspaceId ?? null,
    customName: item?.customName,
    folderItemId: id,
    productDistributorId: item?.productDistributorId ?? null,
    productDistributorSku: item?.productDistributorSku ?? '',
    cartQty,
    inCart: cartQty > 0,
    productManufacturerId: item?.productManufacturerId || null,
    productDescription: item?.productDescription || '',
    stockLevel: item?.stockLevel ?? 0,
    productName: item?.productName ?? '',
    note: item?.note ?? null,
    price: item?.price ? parseFloat(item.price) : 0,
    manufacturerName: item?.manufacturerName ?? '',
    lastOrderedDate: item?.lastOrderedDate
      ? moment.utc(item.lastOrderedDate).format('MM/DD/YYYY')
      : '',
    expirationDate: item?.expirationDate
      ? moment.utc(item.expirationDate).format('YYYY-MM-DD')
      : '',
    orderHistory: item?.orderHistory
      ? mapOrderHistory(
        item.orderHistory,
        quantities,
        folderColumnsVisibility,
        id
      )
      : [],
    qrCode: item?.qrCode ?? false,
    rootCategoryName: item?.rootCategoryName ?? '',
    imageSrc: item?.imageSrc ?? null,
    location: item?.workSpaceName ?? '',
  }
}

export const getQtyFieldValue = (qty) => {
  if (qty === 0) return 0
  if (!isNotUndefined(qty) || qty === null) return null
  if (qty > 0) return parseInt(qty)
}

const mapOrderHistory = (
  orderHistory = [],
  quantities = {},
  folderColumnsVisibility = {},
  folderItemId = {}
) =>
  orderHistory.map((item) =>
    mapOrderHistoryItem(item, quantities, folderColumnsVisibility, folderItemId)
  )

export const mapOrderHistoryItem = (
  item,
  quantities,
  folderColumnsVisibility,
  folderItemId
) => {
  const { qtyOnHand = null, qtyNeedToHave = null } = quantities
  item.qtyOnHand = qtyOnHand
  item.qtyNeedToHave = qtyNeedToHave
  return {
    ...item,
    folderItemId,
    inCart: item.cartQty > 0,
    lastOrderedDate: item?.lastOrderedDate
      ? moment.utc(item.lastOrderedDate).format('MM/DD/YYYY')
      : '',
    ...getQuantities(item, folderColumnsVisibility),
  }
}

const getReorderQty = (quantities = {}, folderColumnsVisibility = {}) => {
  const { qtyOnHand = 0, qtyNeedToHave = 0, reorderWhenLte = null } = quantities
  let res = 0

  if (folderColumnsVisibility.qtyOnHand && folderColumnsVisibility.qtyNeedToHave && qtyNeedToHave) {
    if (folderColumnsVisibility.reorderWhenLte && reorderWhenLte !== null) {
      res = qtyOnHand <= reorderWhenLte ? qtyNeedToHave - qtyOnHand : 0
    } else {
      res = qtyNeedToHave - qtyOnHand
    }

    return Math.max(0, res)
  }

  return quantities.lastOrderedQty || 1
}

export const getQuantities = (item = {}, folderColumnsVisibility) => {
  const quantities = {
    lastOrderedQty: item?.lastOrderedQty === null ? 0 : item.lastOrderedQty,
    qtyOnHand: getQtyFieldValue(item?.qtyOnHand),
    qtyNeedToHave: getQtyFieldValue(item?.qtyNeedToHave),
    reorderWhenLte: getQtyFieldValue(item?.reorderWhenLte),
  }

  quantities.reorderQty = item.cartQty || getReorderQty(quantities, folderColumnsVisibility)

  return quantities
}

export const getSuccessMovingItemsMessage = (
  folderId = null,
  folders = [],
  count = 1
) => {
  const folderName = getFolderName(folderId, folders)
  return `${count} ${pluralize('item', count)} ${
    count === 1 ? 'was' : 'were'
  } added to ${folderName}`
}

export const getFolderName = (folderId = null, folders = []) => {
  const index = folders.findIndex((f) => parseInt(f?.id) === parseInt(folderId))
  if (index === -1) return ''
  return folders[index]?.name ?? ''
}
