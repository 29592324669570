import actions from './actions'
import mutations from './mutations'
import { isNull } from '~/ZenOne-StoryBook/helpers/types'
export const DEFAULT_PAGE_NUMBER = 1
export const DEFAULT_PAGE_SIZE = 35

export const DEFAULT_FILTERS = {
  dateFrom: new Date(2024, 0, 1),
  dateTo: new Date(2025, 0, 1),
  distributorId: [],
  search: '',
  orderId: null, // (Purchase order)
}

export function isDefaultFilters(filters) {
  return (
    isNull(filters.orderId) &&
    filters.search === '' &&
    Array.isArray(filters.distributorId) &&
    filters.distributorId.length === 0 &&
    filters.dateTo === null &&
    filters.dateFrom &&
    filters.dateFrom.getTime() === DEFAULT_FILTERS.dateFrom.getTime()
  )
}

const manageOrders = {
  state: {
    availableDistributors: null, // null indicates loading state
    availableDistributorsPaymentHistory: null, // null indicates loading state
    filters: DEFAULT_FILTERS,
    byOrderFilterOptions: [],
  },
  getters: {
    filters: (state) => state.filters,
    orderIdFilterValue: (state, getters) => getters.filters?.orderId,
    byOrderFilterOptions: (state) => state.byOrderFilterOptions,
    availableDistributors: (state) => state.availableDistributors,
    availableDistributorsPaymentHistory: (state) =>
      state.availableDistributorsPaymentHistory,
  },
  mutations,
  actions,
  namespaced: true,
}

export default manageOrders
