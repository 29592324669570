
import 'vue-custom-scrollbar/dist/vueScrollbar.css'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { isEqual } from 'lodash'
import catalogFilters from './mixins/catalog-filters'
import catalogFiltersParserQueryParams from './mixins/catalog-filters-parser-query-params'
import { DEFAULT_FILTERS } from './helper'
import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'
import storage from '~/ZenOne-StoryBook/mixins/localStorage'
import ui from '~/ZenOne-StoryBook/mixins/ui'
import FilterList from '~/components/Catalog/CatalogFilters/FilterList'
import FilterCheckbox from '~/components/Catalog/CatalogFilters/FilterCheckbox'

export default {
  name: 'CatalogFilters',
  components: {
    FilterCheckbox,
    FilterList,
  },
  mixins: [ui, storage, catalogFilters, catalogFiltersParserQueryParams],
  data() {
    return {
      filters: {},
      init: false,
    }
  },
  computed: {
    // most of it's in catalogFilters mixin
    ...mapGetters({
      filtersInStore: 'catalog/filters',
      isFiltersActive: 'catalog/isFiltersActive',
      pageSize: 'catalog/pageSize',
      pageNumber: 'catalog/pageNumber',
      showTops: 'catalog/showTops',
      headerIsHidden: 'ui/headerIsHidden',
    }),
    showFilterDistributors() {
      return isArrayWithLength(this.distributorOptions)
    },
  },
  watch: {
    filtersInStore() {
      if (!isEqual(this.filters, this.filtersInStore)) {
        this.filters = { ...this.filtersInStore } // make a copy to dereference from Vuex state.
      }
    },
    filters: {
      deep: true,
      handler() {
        this.setFilters({
          filters: { ...this.filters },
          resetPageNumber: this.init,
        })
        if (!this.init) {
          this.init = true
        }
      },
    },
  },
  async mounted() {
    await this.setInitFilters()
    // todo add conditions fetch distr only if we have distr filter in active state
    if (!isArrayWithLength(this.distributorsOverall)) {
      await this.SetDistributorList()
    } // todo set distributor options all and distributorOptions partially
  },
  methods: {
    ...mapActions('catalog', ['SetDistributorList']),
    ...mapMutations({
      setFilters: 'catalog/SET_FILTERS',
      setPageSize: 'catalog/SET_PAGE_SIZE',
      setPageNumber: 'catalog/SET_PAGE_NUMBER',
    }),
    onFilterSelect({ filterAlias = '', value = null }) {
      if (!filterAlias) return false
      this.filters[filterAlias] = value
    },
    onCheckboxFilterSelect({ filterAlias = '' }) {
      if (!filterAlias) return false
      const currentValue = this.filters?.[filterAlias] ?? false
      this.filters[filterAlias] = !currentValue
    },
    setInitFilters() {
      // todo updateLocalStorage if it is empty
      const categoryFiltersFromNetworkParams = this.parserQueryParamsUrl(
        this.$route.query
      )
      // if query param is set, then apply to filters. Otherwise we seed from the ones in the store.
      if (!isEqual(DEFAULT_FILTERS, categoryFiltersFromNetworkParams.filters)) {
        this.filters = categoryFiltersFromNetworkParams.filters
      } else {
        this.filters = this.filtersInStore
      }

      // also set initial page size, page number, and sort
      this.setPageNumber(categoryFiltersFromNetworkParams.pageNumber)
      this.setPageSize({
        pageSize: categoryFiltersFromNetworkParams.pageSize,
        onlyPageSize: true,
      })
    },
  },
}
