
import logoSmall from '~/components/img/logo-new.svg'

export default {
  components: {},
  mixins: [],
  data: () => ({
    logoSmall,
  }),
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
}
