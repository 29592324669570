
import { mapGetters } from 'vuex'
import iconUsd from '~/assets/img/currency-usd__icon.svg'
import Tooltip from '~/ZenOne-StoryBook/components/Tooltip'
export default {
  name: 'SavedTotal',
  components: {
    Tooltip,
  },
  data() {
    return {
      iconUsd,
    }
  },
  computed: {
    ...mapGetters({
      savedTotal: `user/savedTotal`,
    }),
  },
}
