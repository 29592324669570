import { mapGetters } from 'vuex'
import { isObject } from 'lodash'
import { isHavingProps } from '~/ZenOne-StoryBook/helpers/object'
import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'
import { findTopItem } from '~/components/Catalog/helper'

export default {
  computed: {
    ...mapGetters({
      topsList: 'catalog/tops',
    }),
  },
  methods: {
    updateProductDistributorInTops(productDistributorId = null, data = {}) {
      const tops = this.topsList
      const topsList =
        tops && isObject(tops) && isHavingProps(tops)
          ? { ...this.topsList }
          : {}
      if (!isHavingProps(topsList)) return false
      const {
        topsList: {
          ordered: { productList: ordered = [] },
          recentlyPurchased: { productList: recentlyPurchased = [] },
          mostPurchased: { productList: mostPurchased = [] },
        },
      } = this
      if (
        !isArrayWithLength(ordered) &&
        !isArrayWithLength(recentlyPurchased) &&
        !isArrayWithLength(mostPurchased)
      ) {
        return false
      }
      const list = {
        ordered,
        recentlyPurchased,
        mostPurchased,
      }
      const foundList = findTopItem(productDistributorId, list)
      if (!isArrayWithLength(foundList)) return false
      for (const foundItem of foundList) {
        const { typeName = '', index = null } = foundItem
        if (!typeName || index === null) continue
        const product = {
          ...list[typeName][index],
          ...data,
        }
        this.$store.commit('catalog/SET_TOP_ITEM', {
          type: typeName,
          index,
          item: product,
        })
      }
    },
  },
}
