
export default {
  name: 'FadeFS',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
}
