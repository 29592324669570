import {
  CART_ROUTE_NAME,
  DETAIL_PRODUCT_CARD_ROUTE_NAME,
} from '~/helpers/route'
import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'

export default async function ({ route, store }) {
  const prices = store.getters['prices/prices'] ?? []
  if (isArrayWithLength(prices)) {
    await store.dispatch('prices/ResetPricesState')
  }
  const routeName = route?.name ?? null
  if (routeName === CART_ROUTE_NAME) {
    await store.dispatch('prices/AddPrices', {
      productDistributorId: null,
      all: false,
      cart: true,
    })
  } else if (routeName === DETAIL_PRODUCT_CARD_ROUTE_NAME) {
    const productDistributorId = route.params?.pdid ?? null
    if (!productDistributorId) return false
    await store.dispatch('prices/AddPrices', {
      productDistributorId,
      all: true,
    })
  }
}
