import _, { isNull } from 'lodash'
import { DEFAULT_FILTERS } from '~/components/Catalog/CatalogFilters/helper'
import {
  getLocalStorage,
  setLocalStorage,
} from '~/ZenOne-StoryBook/helpers/localStorage'
import { CATALOG_ALIAS } from '~/components/Catalog/helper'
import { isObjectNotUndefinedHasProps } from '~/ZenOne-StoryBook/helpers/object'
import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'
import { capitalizeFirstLetter } from '~/ZenOne-StoryBook/helpers/format'

export const saveFilterOptionsToLS = ({ data = [], option = '' }) => {
  if (!option) return false

  let dataLS = getLocalStorage(CATALOG_ALIAS)
  if (dataLS && isObjectNotUndefinedHasProps(dataLS)) {
    dataLS.filterOptions = {
      ...dataLS?.filterOptions,
      [option]: data,
    }
  } else {
    dataLS = {
      filterOptions: {
        [option]: data,
      },
    }
  }

  setLocalStorage({
    name: CATALOG_ALIAS,
    data: dataLS,
  })
}

export const getFiltersOptionsFromLS = (option = '') => {
  if (!option) return []
  return getLocalStorage(CATALOG_ALIAS)?.filterOptions?.[option] ?? []
}

export const getIsFiltersActive = (filters = {}) => {
  let result = false
  const defaultFilters = { ...DEFAULT_FILTERS }
  for (const filterName in defaultFilters) {
    if (!_.isEqual(defaultFilters?.[filterName], filters?.[filterName])) {
      result = true
      break
    }
  }
  return result
}

export const mapBasicOptions = (data = [], optionName = '') => {
  if (!isArrayWithLength(data)) return []
  return data.reduce((acc, cur) => {
    const item = {
      id: cur?.id ? parseInt(cur.id) : null,
      name: cur?.name ?? '',
    }
    if (optionName === 'distributors')
      item.isConnected = cur?.is_connected ?? false
    acc.push(item)
    return acc
  }, [])
}

export const mapPackagesOptions = (data = []) => {
  if (!isArrayWithLength(data)) return []
  return data.reduce((acc, cur) => {
    const packageQty = cur?.package_qty ?? null
    if (!isNull(packageQty)) {
      acc.push({
        id: packageQty,
        name: packageQty,
      })
    }
    return acc
  }, [])
}

export const mapModifiersOptions = (data = {}) => {
  if (!isObjectNotUndefinedHasProps(data)) return {}
  return Object.keys(data).reduce((acc, cur) => {
    acc[capitalizeFirstLetter(cur)] = data[cur].map((item) => {
      return {
        id: item?.value_id ?? null,
        name: item?.value ? capitalizeFirstLetter(item.value) : '',
      }
    })
    return acc
  }, {})
}
