export const SET_TOPS = 'SET_TOPS'
export const SET_TOP_ITEM = 'SET_TOP_ITEM'
export const SET_ROOT_CATEGORY_LIST = 'SET_ROOT_CATEGORY_LIST'
export const SET_SORT = 'SET_SORT'
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE'
export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER'
export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export const SET_FILTERS = 'SET_FILTERS'
export const SET_FILTERS_ONLY = 'SET_FILTERS_ONLY'
export const UPDATE_FILTERS = 'UPDATE_FILTERS'
export const SET_FILTER_DISTRIBUTOR = 'SET_FILTER_DISTRIBUTOR'
export const SET_FILTER_MANUFACTURER = 'SET_FILTER_MANUFACTURER'
export const SET_SEARCH = 'SET_SEARCH'
export const SET_ORDER_HISTORY = 'SET_ORDER_HISTORY'
export const SET_IS_FETCHING = 'SET_IS_FETCHING'

// Filter Options
export const CLEAR_FILTER_OPTIONS = 'CLEAR_FILTER_OPTIONS'
export const SET_DISTRIBUTORS_OVERALL = 'SET_DISTRIBUTORS_OVERALL'
export const SET_DISTRIBUTORS_FILTER_OPTIONS = 'SET_DISTRIBUTORS_FILTER_OPTIONS'
export const SET_CATEGORIES_FILTER_OPTIONS = 'SET_CATEGORIES_FILTER_OPTIONS'
export const SET_GENERICS_FILTER_OPTIONS = 'SET_GENERICS_FILTER_OPTIONS'
export const SET_MODIFIERS_FILTER_OPTIONS = 'SET_MODIFIERS_FILTER_OPTIONS'
export const SET_SUBCATEGORIES_FILTER_OPTIONS =
  'SET_SUBCATEGORIES_FILTER_OPTIONS'
export const SET_MANUFACTURERS_FILTER_OPTIONS =
  'SET_MANUFACTURERS_FILTER_OPTIONS'
export const SET_PACKAGES_FILTER_OPTIONS = 'SET_PACKAGES_FILTER_OPTIONS'

// Detail product
export const SET_GENERAL_PRODUCT_WORKSPACE = 'SET_GENERAL_PRODUCT_WORKSPACE'
