import * as types from './mutation-types'
import { UPDATE_PRICE } from '~/store/modules/prices/mutation-types'
import { isEmailConfirmed } from '~/ZenOne-StoryBook/helpers/socket'
import { SET_EMAIL_IS_CONFIRMED } from '~/store/modules/user/mutation-types'

export default {
  addNotification({ commit, state }, payload) {
    if (isEmailConfirmed(payload?.type)) {
      commit(`user/${SET_EMAIL_IS_CONFIRMED}`, null, {
        root: true,
      })
      return false
    }
    commit(`socket/${types.ADD_NOTIFICATION}`, payload, {
      root: true,
    })
  },
  updatePrice({ commit, state }, payload) {
    commit(`prices/${UPDATE_PRICE}`, payload, {
      root: true,
    })
  },
}
