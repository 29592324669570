export const SET_ALL_CART_ITEMS_SELECTED = 'SET_ALL_CART_ITEMS_SELECTED'
export const SET_DELETED_ITEMS = 'SET_DELETED_ITEMS'
export const SET_DISTRIBUTORS = 'SET_DISTRIBUTORS'
export const SET_DISTRIBUTOR_BY_INDEX = 'SET_DISTRIBUTOR_BY_INDEX'
export const CLEAR_DISTRIBUTORS = 'CLEAR_DISTRIBUTORS'
export const SET_CART_SELECTED_IDS = 'SET_CART_SELECTED_IDS'
export const SET_CART_ITEM_IDS = 'SET_CART_ITEM_IDS'
export const CLEAR_CART_ITEM_IDS = 'CLEAR_CART_ITEM_IDS'
export const SET_CART_ITEMS_COUNT = 'SET_CART_ITEMS_COUNT'
export const SET_SELECT_ALL_CART_ITEMS_TRIGGER = 'SET_SELECT_ALL_CART_ITEMS_TRIGGER'
export const SET_DESELECT_ALL_CART_ITEMS_TRIGGER = 'SET_DESELECT_ALL_CART_ITEMS_TRIGGER'
export const SET_CART_ITEMS_TOTAL_COUNT = 'SET_CART_ITEMS_TOTAL_COUNT'
export const SET_SAVING_COSTS = 'SET_SAVING_COSTS'
export const SET_SAVING_SUBTOTAL = 'SET_SAVING_SUBTOTAL'
export const SET_WITHOUT_SAVINGS = 'SET_WITHOUT_SAVINGS'
export const SET_IS_HAVING_ACTIVE_SAVINGS = 'SET_IS_HAVING_ACTIVE_SAVINGS'
export const SET_IS_CHECKOUT = 'SET_IS_CHECKOUT'

// Saved For Later
export const SET_SAVED_FOR_LATER_LIST = 'SET_SAVED_FOR_LATER_LIST'
export const CLEAR_SAVED_FOR_LATER_LIST = 'CLEAR_SAVED_FOR_LATER_LIST'
export const SET_SFL_IDS = 'SET_SFL_IDS'
export const CLEAR_SFL_IDS = 'CLEAR_SFL_IDS'
export const SET_SAVED_FOR_LATER_SELECTED_IDS = 'SET_SAVED_FOR_LATER_SELECTED_IDS'
export const SET_SELECT_ALL_SFL_TRIGGER = 'SET_SELECT_ALL_SFL_TRIGGER'
export const SET_DESELECT_ALL_SFL_TRIGGER = 'SET_DESELECT_ALL_SFL_TRIGGER'

// Search
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM'
