import { map as _map, sortBy as _sortBy, isNull } from 'lodash'
import moment from 'moment'
import {
  isHavingProps,
  convertCollection,
  isObjectNotUndefinedHasProps,
} from '~/ZenOne-StoryBook/helpers/object'
import {
  isArrayWithLength,
  isNotUndefined,
} from '~/ZenOne-StoryBook/helpers/types'
import noImage from '~/components/img/no-image.svg'
import { api } from '~/config/api'
import { unitsExtended } from '~/ZenOne-StoryBook/helpers/unitsList'
import {
  CLIENT_PRICE,
  RETAIL_PRICE,
  ZEN_PRICE,
  RETAIL_PRICE_VALUE,
  ZEN_PRICE_VALUE,
  CLIENT_PRICE_VALUE, SC_PRICE_VALUE, SC_PRICE, MFG_PRICE_VALUE, MFG_PRICE
} from '~/ZenOne-StoryBook/components/Distributor/DialogPriceBulkUpload/helper'

export const VIDEO_FILE_TYPE = 'video'
export const IMAGE_FILE_TYPE = 'image'
export const PDF_FILE_TYPE = 'pdf'

export const LIST_TYPE_OTHER_OPTIONS = 'other options'
export const LIST_TYPE_ALTERNATIVE = 'alternative'

export const sortByPrice = (list) => {
  let listWithPrice = []
  let listWithoutPrice = []
  list.forEach((item) => {
    item.price === 'N/A'
      ? listWithoutPrice.push(item)
      : listWithPrice.push(item)
  })
  listWithPrice = _sortBy(listWithPrice, 'price')
  listWithoutPrice = _sortBy(listWithoutPrice, 'name')
  return [...listWithPrice, ...listWithoutPrice]
}

export const mapProductImages = (data = {}) => {
  const imageList = []
  const images = data?.product?.images
  if (images && isHavingProps(images)) {
    const imageItems = Object.values(images)
    for (const image of imageItems) {
      imageList.push({
        type: IMAGE_FILE_TYPE,
        id: image?.fileId ?? null,
        name: image?.name ?? '',
        src: image?.path ?? noImage,
        size: image?.size ?? 0,
        primary: image?.primary ?? false,
        thumb: {
          src: image?.thumb?.path ?? noImage,
        },
      })
    }

    return imageList.sort((a, b) => Number(b.primary) - Number(a.primary))
  } else
    return [
      {
        type: IMAGE_FILE_TYPE,
        id: null,
        name: '',
        src: noImage,
        size: '',
        primary: true,
        thumb: {
          src: noImage,
        },
      },
    ]
}

export const productDistributorIncludeString =
  'product.product-model.category.category-modifiers,cart-items,distributor.logo.thumb,product.product-model.manufacturer,product.product-model.manufacturer.logo,product.product-model.category.parent.parent,product.product-model,product.product-model.product-model-modifier.modifier,product.product-model.manufacturer.logo,product.images.thumb,product.modifier-values.modifier,product.sds-file'
export const siblingProductListIncludeString = `${api.DISTRIBUTOR},product,cart-items`
export const modifierListIncludeString =
  'general-product-distributor,modifier-values,product-model,product-model.product-model-modifier'

export const getCartItemData = (data, practiceLocationId) => {
  const cartItem = {
    id: null,
    qty: 0,
  }
  let cartItemsData = data?.['cart-items'] ?? {}
  if (isNotUndefined(cartItemsData) && isHavingProps(cartItemsData)) {
    cartItemsData = Object.values(cartItemsData)[0]
    // todo need only current practiceLocationId
    cartItem.id = cartItemsData?._jv?.id ? parseInt(cartItemsData._jv.id) : null
    cartItem.qty = cartItemsData?.qty ?? 0
  }
  return cartItem
}

const getCategoryModifiers = (category = {}) => {
  if (!isObjectNotUndefinedHasProps(category)) return []
  const categoryModifiers = category?.['category-modifiers'] ?? {}
  if (!isObjectNotUndefinedHasProps(categoryModifiers)) return []
  return Object.keys(categoryModifiers).reduce((acc, cur) => {
    const modifierId = categoryModifiers[cur]?.modifier_id ?? null
    if (modifierId) acc.push(modifierId)
    return acc
  }, [])
}

export const mapProductDistributor = (
  data = {},
  productDistributorId = null,
  updatingPricesList = []
) => {
  if (!isHavingProps(data)) return {}
  const id = productDistributorId
    ? parseInt(productDistributorId)
    : data?.id
    ? parseInt(data.id)
    : null
  const price = mapDistributorPrice(
    data?.distributorPrice,
    updatingPricesList,
    id
  )
  const isPriceLoading = checkIsPriceLoading(
    id,
    updatingPricesList,
    price?.actualPrice?.price
  )
  const category = data?.product?.['product-model']?.category ?? {}
  const categoryId = category?.id ? parseInt(category.id) : null
  const manufacturer = data?.product?.['product-model']?.manufacturer ?? {}
  const distributor = data?.distributor ?? {}
  const lastOrderedAt = data?.lastOrderedPracticeLocationDate ?? null
  data.id = id
  data.addedShoppingCart = data?.isInPracticeLocationCart ?? false
  data.price = price
  data.isPriceLoading = isPriceLoading
  data.package = getPackageString(data?.product)
  data.productDistributorId = id
  data.cartItem = getCartItemData(data)
  delete data['cart-items']
  data.categoryId = categoryId ? parseInt(categoryId) : null
  data.category = category
  data.isZenChoice = data?.product?.zenChoice ?? false
  data.sdsFiles =
    typeof data?.product?.['sds-file'] === 'object' &&
    isHavingProps(data?.product?.['sds-file'])
      ? [...convertCollection(data.product?.['sds-file'])]
      : []
  data.videos = mapVideos(data?.videoLink)
  data.instructions = mapInstructions(data?.instructions ?? [])
  data.name = data?.product?.name ?? ''
  data.images = mapProductImages(data)
  data.description = data?.product?.description ?? ''
  data.manufacturerSku = data?.product?.sku ?? ''
  data.modifierList = getModifierList(data, category)
  data.shipping = {
    isNextDayAllStates: distributor?.isNextDayAllStates ?? false,
    deliveryTime: distributor?.deliveryTime ?? null,
    shippingCost: distributor?.shippingCost ?? null,
    minFreeShipping: distributor?.minFreeShipping ?? null,
    hasHandling: distributor?.hasHandling ?? false,
    handlingFuelCharge: distributor?.handlingFuelCharge ?? null,
    isCoveredByZen: distributor?.isCoveredByZen ?? false,
    hasFreeShipping: distributor?.hasFreeShipping ?? false,
  }
  data.manufacturer = {
    name: manufacturer?.name ?? '',
    logo: manufacturer?.logo?.path ?? null,
  }
  data.distributorSku = data?.sku ?? ''
  data.productDistributorId = id
  data.productManufacturerId = data?.productId || null
  data.additionalDescription =
    data?.product?.parameters?.additionalDescription ?? null
  data.otherInfo = data?.otherInfo ?? null
  data.videoLink = data?.videoLink ?? []
  data.lastOrderedAt = lastOrderedAt
    ? moment(lastOrderedAt).format('MM/DD/YY')
    : ''
  data.lastOrderedQty = data?.lastOrderedPracticeLocationQty ?? 0
  data.units = data?.product?.units ?? 1
  delete data._jv
  return data
}

const getModifierList = (data = {}, category = {}) => {
  const categoryModifiers = getCategoryModifiers(category)
  const modifierValues = data?.product?.['modifier-values'] ?? []
  if (isArrayWithLength(modifierValues)) return []
  return _map(modifierValues, (item) => {
    const modifierId = item?.modifierId ?? null
    let modifierValueId = item?._jv?.id
    modifierValueId =
      isNotUndefined(modifierValueId) && modifierValueId !== null
        ? parseInt(modifierValueId)
        : null
    return {
      name: item?.modifier?.name ?? '',
      value: item?.value ?? '',
      modifierId,
      modifierValueId,
    }
  }).filter((item) => categoryModifiers.includes(item?.modifierId))
}

export const mapSiblingProductDistributorList = (
  productList = [],
  updatingPricesList = []
) => {
  const result = []
  productList.forEach((siblingProductDistributor) => {
    result.push(
      mapProductDistributor(siblingProductDistributor, null, updatingPricesList)
    )
  })
  return result
}

export const mapManufacturerProductsGrouped = (
  data = [],
  generalModifierId = null
) => {
  let modifierValueList = []
  if (!data || !isArrayWithLength(data)) return { modifierValueList: [] }
  const props = Object.keys(data)
  if (props.length) {
    modifierValueList = Object.keys(data).reduce((acc, cur) => {
      const product = data[cur]
      const modifierValues = product?.['modifier-values']
      if (isNotUndefined(modifierValues) && isHavingProps(modifierValues)) {
        const item = getProductItemByModifierValues(
          modifierValues,
          generalModifierId,
          product
        )
        if (isHavingProps(item)) {
          acc.push(item)
        }
      }
      return acc
    }, [])
  }
  return { modifierValueList }
}

const getProductItemByModifierValues = (
  modifierValues = {},
  generalModifierId = null,
  product = {}
) => {
  let result = {}
  Object.keys(modifierValues).forEach((modifierValueId) => {
    const modifierId = modifierValues?.[modifierValueId]?.modifierId
    if (
      isNotUndefined(modifierId) &&
      modifierId &&
      parseInt(modifierId) === parseInt(generalModifierId)
    ) {
      const generalProductDistributor =
        isHavingProps(product) && product?.['general-product-distributor']
          ? product['general-product-distributor']
          : {}
      const productDistributorId =
        parseInt(generalProductDistributor?._jv?.id) ?? null
      const productManufacturerId = generalProductDistributor?.productId ?? null
      const name = modifierValues?.[modifierValueId]?.value ?? ''
      if (isHavingProps(generalProductDistributor) && name) {
        result = {
          id: parseInt(modifierValueId),
          name,
          productDistributorId,
          productManufacturerId,
        }
      }
    }
  })
  return result
}

export const mapAlternativeList = (list = []) => {
  return Object.keys(list).reduce((acc, key) => {
    const alternative = list[key]
    if (isHavingProps(alternative)) {
      const productManufacturerId = alternative?.product_id ?? null
      const productDistributorId = alternative?.id ?? null
      const distributor = alternative?.distributor ?? {}
      const price = mapDistributorPrice(alternative?.distributorPrice ?? {})
      const cartItems = alternative?.['cart-items']
      const cartItem = isArrayWithLength(cartItems) ? cartItems[0] : {}
      const cartItemQty = isHavingProps(cartItem) ? cartItem?.qty : 0
      const getShippingCost = (data = undefined) => {
        if (isNull(data)) return null
        if (data === 0) return 0
        return parseInt(data)
      }
      const item = {
        id: productDistributorId,
        productId: productManufacturerId,
        name: alternative?.productName ?? '',
        distributor,
        imgSrc: alternative?.imgSrc,
        stockLevel: alternative?.stockLevel ?? 0,
        status: alternative?.status ?? 0,
        productManufacturerId,
        productDistributorId,
        manufacturerName: alternative?.manufacturer?.name ?? '',
        distributorName: alternative?.distributor?.name ?? '',
        manufacturerSku: alternative?.product?.sku ?? '',
        price,
        isAuthorized: alternative.isAuthorized,
        pricePerUnit: alternative?.altUnitPrice,
        distributorPrice: price,
        addedShoppingCart: cartItemQty > 0,
        cartItem: getAlternativeCartItem(alternative),
        shipping: {
          deliveryTime: distributor?.deliveryTime ?? null,
          isCoveredByZen: distributor?.isCoveredByZen ?? false,
          hasFreeShipping: distributor?.hasFreeShipping ?? false,
          hasHandling: distributor?.hasHandling ?? false,
          handlingFuelCharge: distributor?.handlingFuelCharge ?? null,
          shippingCost: getShippingCost(distributor?.fixedShippingCost),
          minFreeShipping: distributor?.minOrderTotalFree
            ? parseInt(distributor.minOrderTotalFree)
            : null,
        },
      }
      acc.push(item)
    }
    return acc
  }, [])
}
const getAlternativeCartItem = (data = {}) =>
  data?.['cart-items']?.[0] ? data['cart-items'][0] : { id: null, qty: 0 }

const mapVideos = (videoList = []) => {
  if (!isArrayWithLength(videoList)) return []
  return videoList.map((video, index) => {
    return {
      id: index,
      type: VIDEO_FILE_TYPE,
      src: video,
    }
  })
}

const mapInstructions = (instructions = []) => {
  if (!isArrayWithLength(instructions)) return []
  return instructions.map((instruction) => {
    const { id = null, path = '' } = instruction
    return {
      id,
      type: PDF_FILE_TYPE,
      src: path,
    }
  })
}

const getPackageString = (product = {}) => {
  if (
    !product ||
    !isHavingProps(product) ||
    !product?.package_qty ||
    !product?.package_measure
  )
    return ''
  return `${getMeasureByAcronym(product.package_measure)} of ${
    product.package_qty
  }`
}

const getMeasureByAcronym = (measure = '') => {
  if (!measure) return ''
  const index = unitsExtended.findIndex((unit) => unit.value === measure)
  if (index !== -1) {
    return unitsExtended[index].name ?? ''
  }
}

export const defaultPriceObject = {
  price: null,
  type: '',
  typeName: '',
}

const mapDistributorPrice = (
  distributorPrice = {},
  updatingPricesList = [],
  id = null
) => {
  const price = { ...distributorPrice }
  const index = updatingPricesList.findIndex(
    (updatingPrice) => updatingPrice.productDistributorId === id
  )
  if (index !== -1) {
    const updatingDistributorPrice =
      updatingPricesList?.[index]?.distributorPrice ?? {}
    const actualPriceValueFromStorage =
      updatingDistributorPrice?.actualPrice?.price ?? null
    const actualTypeValueFromStorage =
      updatingDistributorPrice?.actualPrice?.type ?? ''
    const actualLastCheckedAtValueFromStorage =
      updatingDistributorPrice?.actualPrice?.lastCheckedAt ?? null
    const actualPriceObject = getActualPriceObject(price)
    const currentPrice = actualPriceObject?.price ?? null
    const currentType = actualPriceObject?.type ?? ''
    const currentLastCheckedAt = actualPriceObject?.lastCheckedAt ?? null

    const tempDistributorPrice = {
      ...updatingDistributorPrice,
    }
    const priceType = currentType || actualTypeValueFromStorage
    const tempDistributorActualPriceObject = {
      ...tempDistributorPrice?.actualPrice,
      price: currentPrice || actualPriceValueFromStorage,
      type: priceType,
      typeName: getPriceTypeName(priceType),
      lastCheckedAt:
        currentLastCheckedAt || actualLastCheckedAtValueFromStorage,
    }
    tempDistributorPrice.actualPrice = { ...tempDistributorActualPriceObject }
    return tempDistributorPrice
  }
  if (!distributorPrice || !isHavingProps(distributorPrice)) {
    if (!id && !isArrayWithLength(updatingPricesList)) {
      return {
        actualPrice: { ...defaultPriceObject },
      }
    }
  }
  price.actualPrice = getActualPriceObject(price)
  price.upperPrice = getUpperPriceObject(price)
  return price
}

export const getActualPriceObject = (distributorPrice = {}) => {
  const actualPrice = distributorPrice?.actualPrice ?? {}
  if (isHavingProps(actualPrice)) {
    const priceType = actualPrice?.type ?? ''
    return {
      price: actualPrice?.price ?? null,
      type: priceType,
      typeName: getPriceTypeName(priceType),
      lastCheckedAt: actualPrice?.lastCheckedAt ?? null,
    }
  }
  return { ...defaultPriceObject }
}

export const getUpperPriceObject = (distributorPrice = {}) => {
  const upperPrice = distributorPrice?.upperPrice ?? {}
  if (isHavingProps(upperPrice)) {
    const priceType = upperPrice?.type
    return {
      price: upperPrice.price ?? null,
      type: priceType,
      typeName: getPriceTypeName(priceType),
    }
  }
  return null
}

export const getPriceTypeName = (type = '') => {
  if (!type) return ''
  switch (type) {
    case ZEN_PRICE_VALUE:
      return ZEN_PRICE
    case RETAIL_PRICE_VALUE:
      return RETAIL_PRICE
    case CLIENT_PRICE_VALUE:
      return CLIENT_PRICE
    case SC_PRICE_VALUE:
      return SC_PRICE
    case MFG_PRICE_VALUE:
      return MFG_PRICE
  }

  return ''
}

export const checkIsPriceLoading = (
  productDistributorId = null,
  updatingPricesList = [],
  priceValue = null
) => {
  if (!productDistributorId || !isArrayWithLength(updatingPricesList)) {
    return false
  }
  let result = false
  for (const updatingPrice of updatingPricesList) {
    if (
      parseInt(updatingPrice.productDistributorId) ===
      parseInt(productDistributorId)
    ) {
      result = true
      break
    }
  }
  return result
}

export const getExcludedSiblingIds = (siblingProductDistributorList = []) => {
  if (!isArrayWithLength(siblingProductDistributorList)) return []
  return siblingProductDistributorList
    .filter((pd) => !isNull(pd?.distributorPrice?.actualPrice?.price))
    .map((pd) => pd.id)
}
