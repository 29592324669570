
export default {
  name: 'FolderColumnCheckbox',
  props: {
    columnType: {
      type: String,
      default: '',
      required: true,
    },
    column: {
      type: Object,
      default() {
        return {}
      },
      required: true,
    },
  },
  data() {
    return {
      init: false,
      model: false,
    }
  },
  mounted() {
    this.model = this.column?.visible ?? false
    this.init = true
  },
  methods: {
    changeModelHandler() {
      if (!this.init) return false
      const { column = {} } = this
      this.$emit('on-column-checkbox-change', {
        name: column?.name ?? '',
        columnType: this.columnType,
        visible: this.model,
      })
    },
  },
}
