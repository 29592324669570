import { RESPONSE_STATUS_OK } from '~/ZenOne-StoryBook/helpers/response'

export const initCheckRealTimePrices = async (
  $axios = {},
  productDistributorId = null,
  all = false,
  cart = false
) => {
  try {
    if (!$axios) throw new Error('Should pass $axios instance to make request')
    const res = await $axios.get(
      `check_price?${getQueryParamsString(productDistributorId, all, cart)}`
    )
    if (res?.status !== RESPONSE_STATUS_OK) throw new Error('Server error')
    return res?.data?.checking_ids ?? []
  } catch (e) {
    console.error(`Init check prices: ${e}`)
  }
}

const getQueryParamsString = (
  productDistributorId = null,
  all = false,
  cart = false
) => {
  let result = ''
  if (productDistributorId) {
    result = `id=${productDistributorId}`
    if (all) result = `${result}&all=1`
  } else if (cart) result = `cart=1`
  return result
}
