
import vueCustomScrollbar from 'vue-custom-scrollbar'
import { mapGetters } from 'vuex'
import FolderNameInput from './FolderNameInput'
import FolderColumnsForm from './FolderColumnsForm'
import { CREATE_MODE, EDIT_MODE, FOLDER_TYPE } from './helper'
import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'
import {
  DEFAULT_COLUMN,
  OPTIONAL_COLUMN,
  columnsListDefault,
  columnsListOptional,
} from '~/helpers/inventory/columns'
import ui from '~/ZenOne-StoryBook/mixins/ui'
import { API as api } from '~/ZenOne-StoryBook/config/api'
import ButtonRoundGreen from '~/components/Buttons/ButtonRound/ButtonRoundGreen'
import { isHavingProps } from '~/ZenOne-StoryBook/helpers/object'
import { findIndexByPropNameAndValue } from '~/ZenOne-StoryBook/helpers/array'
import {
  RESPONSE_STATUS_NO_CONTENT,
  RESPONSE_STATUS_CREATED,
} from '~/ZenOne-StoryBook/helpers/response'

export default {
  name: 'FolderDialog',
  components: {
    FolderNameInput,
    FolderColumnsForm,
    ButtonRoundGreen,
    vueCustomScrollbar,
  },
  mixins: [ui],
  data() {
    return {
      showNameInput: false,
      entityType: '',
      folderId: null,
      folder: {},
      parentFolderId: null,
      dialog: false,
      isLoading: false,
      mode: CREATE_MODE,
      api,
    }
  },
  computed: {
    ...mapGetters({
      activeFolderId: `inventory/folderId`,
    }),
    isCreateMode() {
      return this.mode === CREATE_MODE
    },
    isEditMode() {
      return this.mode === EDIT_MODE
    },
    folderName() {
      return this.folder?.name ? this.folder.name : ''
    },
    actionBtnText() {
      if (this.isCreateMode) return 'Create'
      else if (this.isEditMode) return 'Save'
      return ''
    },
    isActionBtnDisabled() {
      return this.isLoading || this.folderName === ''
    },
    hasOptionalColumns() {
      return isArrayWithLength(this.folder?.optionalColumns)
    },
    columnsMerged() {
      if (!this.dialog) return []
      return !this.hasOptionalColumns
        ? [...this.folder.defaultColumns]
        : [...this.folder.defaultColumns, ...this.folder.optionalColumns]
    },
  },
  watch: {
    dialog: {
      handler(value) {
        if (this.isCreateMode) {
          this.initFolderColumnsData()
        }
        if (value) {
          document.addEventListener('keydown', this.enterKeyDownHandler)
        } else if (!value) {
          document.removeEventListener('keydown', this.enterKeyDownHandler)
          this.mode = CREATE_MODE
          this.folderId = null
          this.folder = {}
          this.parentFolderId = null
          this.entityType = ''
        }
      },
    },
  },
  methods: {
    enterKeyDownHandler(e) {
      if (this.showNameInput || e.key !== 'Enter') return false
      // todo check isFormDirty: if not - just close the dialog without request
      this.actionBtnClickHandler()
    },
    onShowNameInputChange(value) {
      this.showNameInput = value
    },
    show({ folder = {}, parentFolderId = null, entityType = '' }) {
      if (isHavingProps(folder)) {
        const folderId = folder?.id ? parseInt(folder.id) : null
        if (!folderId) return false
        this.folderId = folderId
        this.folder = { ...folder }
        this.parentFolderId = folder?.parent_id ?? null
        this.mode = EDIT_MODE
      } else {
        this.parentFolderId = parentFolderId
      }
      this.entityType = !entityType ? FOLDER_TYPE : entityType
      this.dialog = true
    },
    initFolderColumnsData() {
      this.folder = {
        ...this.folder,
        defaultColumns: [
          ...columnsListDefault.filter((col) => col.onlyRoot !== true),
        ],
        optionalColumns: [...columnsListOptional],
      }
    },
    close() {
      this.dialog = false
    },
    closeBtnClickHandler() {
      this.close()
    },
    onFolderNameUpdate({ name = '', needRequest = false }) {
      this.$set(this.folder, 'name', name)
      if (needRequest) {
        this.actionBtnClickHandler()
      }
    },
    onFolderSettingsChanged({ name = '', columnType = '', visible = false }) {
      if (!name || !columnType) return false
      // todo make helper
      if (columnType === DEFAULT_COLUMN) {
        const index = findIndexByPropNameAndValue(
          this.folder.defaultColumns,
          'name',
          name
        )
        if (index !== -1) {
          const defaultColumnsCopy = [...this.folder.defaultColumns]
          const col = { ...defaultColumnsCopy[index], visible }
          this.$set(this.folder.defaultColumns, index, { ...col })
        }
      } else if (columnType === OPTIONAL_COLUMN) {
        const index = findIndexByPropNameAndValue(
          this.folder.optionalColumns,
          'name',
          name
        )
        if (index !== -1) {
          const optionalColumnsCopy = [...this.folder.optionalColumns]
          const col = { ...optionalColumnsCopy[index], visible }
          this.$set(this.folder.optionalColumns, index, { ...col })
        }
      }
    },
    async actionBtnClickHandler() {
      if (!this.folderName) return false
      const attributes = {
        name: this.folderName,
        parent_id: this.parentFolderId,
        columns: this.columnsMerged,
      }
      if (this.isCreateMode) {
        await this.createFolder(attributes)
      } else if (this.isEditMode) {
        await this.saveFolder(attributes)
      }
      this.$emit('on-action-completed')
    },
    async createFolder(attributes = {}) {
      try {
        this.showFSLoader()
        const res = await this.$axios.post(`/workspaces/`, {
          ...attributes,
        })
        if (res?.status !== RESPONSE_STATUS_CREATED) {
          throw new Error('Response error')
        }
        await this.$store.dispatch('inventory/SetFolders')
        this.close()
      } catch (e) {
        const error = e?.response?.data?.errors?.[0]?.detail ?? e
        console.error(error)
        this.showAlert({
          type: this.ERROR,
          content: `Cannot create folder: ${error}`,
        })
      } finally {
        this.hideFSLoader()
      }
    },
    async saveFolder(attributes = {}) {
      try {
        const { folderId = null } = this
        this.showFSLoader()
        const res = await this.$axios.patch(`/workspaces/${folderId}`, {
          ...attributes,
        })
        if (res?.status !== RESPONSE_STATUS_NO_CONTENT) {
          throw new Error('Response error')
        }
        await this.$store.dispatch('inventory/SetFolders')
        if (folderId === this.activeFolderId) {
          await this.$store.dispatch('inventory/SetActiveFolder', folderId)
        }
        this.close()
      } catch (e) {
        const error = e?.response?.data?.errors?.[0]?.detail ?? e
        console.error(error)
        this.showAlert({
          type: this.ERROR,
          content: `Cannot update sub folder: ${error}`,
        })
      } finally {
        this.hideFSLoader()
      }
    },
  },
}
