import * as types from './mutation-types'
import { SUCCESS } from '~/ZenOne-StoryBook/helpers/ui'
import { ALERT_DEFAULT_TIMEOUT } from '~/ZenOne-StoryBook/helpers/ui'

export default {
  [types.SET_SHOW_FULL_SCREEN_LOADER](state, payload) {
    state.showFullScreenLoader = payload
  },
  [types.SET_SHOW_FADE_FS](state, payload) {
    state.showFadeFS = payload
  },
  [types.SHOW_ALERT](state, payload) {
    state.alert = payload
  },
  [types.HIDE_ALERT](state) {
    state.alert = {
      show: false,
      content: '',
      type: SUCCESS,
      actionComponent: null,
      timeout: ALERT_DEFAULT_TIMEOUT,
    }
  },
  [types.SET_NOTIFICATIONS](state, payload) {
    state.notifications = payload
  },
  [types.HIDE_NOTIFICATIONS](state) {
    state.notifications = []
  },
  [types.HIDE_HEADER](state) {
    state.headerIsHidden = true
  },
  [types.SHOW_HEADER](state) {
    state.headerIsHidden = false
  },
}
