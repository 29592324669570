export const RETAIL_PRICE = 'Retail Price'
export const ZEN_PRICE = 'Zen Price'
export const SC_PRICE = 'SC Price'
export const CLIENT_PRICE = 'Client Price'
export const MFG_PRICE = 'Mfg Price'

export const RETAIL_PRICE_VALUE = 'price_retail'
export const ZEN_PRICE_VALUE = 'price_zen'
export const SC_PRICE_VALUE = 'price_sc'
export const CLIENT_PRICE_VALUE = 'price_client_practice_location'
export const MFG_PRICE_VALUE = 'price_manufacturer'

export const priceTypesList = [
  { id: 1, text: RETAIL_PRICE, value: RETAIL_PRICE_VALUE },
  { id: 2, text: ZEN_PRICE, value: ZEN_PRICE_VALUE },
  { id: 3, text: CLIENT_PRICE, value: CLIENT_PRICE_VALUE },
]
