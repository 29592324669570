
import { mapMutations } from 'vuex'
import { SET_SEARCH_TERM } from '~/store/modules/distributors/mutation-types'
import iconSearch from '~/components/img/icon-search.svg'
export default {
  name: 'DistributorSearch',
  props: {
    placeholder: {
      type: String,
      default: 'Search',
    },
    typeStyle: {
      type: String,
      default: 'common',
    },
  },
  data() {
    return {
      iconSearch,
      model: '',
      focus: false,
    }
  },
  methods: {
    ...mapMutations({
      setSearchTerm: `distributors/${SET_SEARCH_TERM}`,
    }),
    inputHandler() {
      this.setSearchTerm(this.model)
    },
    clearSearchTermHandler() {
      this.model = ''
      this.setSearchTerm(this.model)
    },
    enterKeyDownHandler() {
      this.setSearchTerm(this.model)
    },
  },
}
