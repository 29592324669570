import {
  CART_ROUTE_NAME,
  SETTINGS_ROUTE_NAME,
  DETAIL_PRODUCT_CARD_ROUTE_NAME,
  ROOT_ROUTE_NAME,
  WORKSPACE_ROUTE_NAME,
  MY_DISTRIBUTORS_ROUTE_NAME,
  LOGIN_ROUTE_NAME,
  REGISTRATION_ROUTE_NAME,
  INVENTORY_ROUTE_NAME,
} from '~/helpers/route'

export default {
  data() {
    return {}
  },
  computed: {
    isCatalogRoute() {
      return this.$route.name === ROOT_ROUTE_NAME
    },
    isInventoryRoute() {
      return this.$route.name === INVENTORY_ROUTE_NAME
    },
    isWorkspaceRoute() {
      return this.$route.name === WORKSPACE_ROUTE_NAME
    },
    isSettingsRoute() {
      return this.$route.name === SETTINGS_ROUTE_NAME
    },
    isDetailProductCardRoute() {
      return this.$route.name === DETAIL_PRODUCT_CARD_ROUTE_NAME
    },
    isCartRoute() {
      return this.$route.name === CART_ROUTE_NAME
    },
    isMyDistributorsRoute() {
      return this.$route.name === MY_DISTRIBUTORS_ROUTE_NAME
    },
    isLoginRoute() {
      return this.$route.name === LOGIN_ROUTE_NAME
    },
    isRegistrationRoute() {
      return this.$route.name === REGISTRATION_ROUTE_NAME
    },
  },
}
