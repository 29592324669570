export default {
  methods: {
    removeLocalStorageItem(key) {
      return localStorage.removeItem(key)
    },
    setLocalStorage(e) {
      return localStorage.setItem(e.name, JSON.stringify(e.data))
    },
    getLocalStorage(e) {
      if (e.length) {
        return JSON.parse(localStorage.getItem(e))
      } else {
        return false
      }
    },
  },
}
