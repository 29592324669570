import {
  checkLSHasInvalidDistributorLogin,
  checkLSHasSuccessDistributorLogin,
  checkInvalidDistributorLogins,
  removeSuccessDistributorLoginEvents,
  STATUS_FAILED,
} from '~/helpers/connect-distributors'
import {
  convertCollection,
  isHavingProps,
} from '~/ZenOne-StoryBook/helpers/object'
import { DISTRIBUTOR_PROCESS_SUBTYPE_LOGIN_RESULT } from '~/ZenOne-StoryBook/helpers/socket'
import { api } from '~/config/api'
import { getLocalStorage } from '~/ZenOne-StoryBook/helpers/localStorage'

export default function ({ store = {}, $auth = {} }) {
  if (!$auth?.loggedIn) {
    return false
  }

  if (checkLSHasSuccessDistributorLogin()) {
    const list = removeSuccessDistributorLoginEvents()
    if (Array.isArray(list)) {
      updateStore(store, list)
    }
  }
  if (
    !store.getters['socket/invalidLoginCheck'] &&
    checkLSHasInvalidDistributorLogin()
  ) {
    store.commit('socket/SET_INVALID_LOGIN_CHECK', {
      value: true,
    })
    const practiceLocationId = getPracticeLocationId(store)
    const fetchPromise = fetchPracticeLocation(practiceLocationId, store)
    fetchPromise.then(({ data }) => {
      const ids = getActualFailedDistributorsIds(data)
      const list = checkInvalidDistributorLogins(ids)
      if (Array.isArray(list)) {
        updateStore(store, list)
      }
      store.commit('socket/SET_INVALID_LOGIN_CHECK', {
        value: false,
      })
    })
  }
}

export const getPracticeLocationId = (store = {}) => {
  let id = null
  if (isHavingProps(store)) {
    id = store.getters['user/practiceLocationId']
  }
  if (!id) {
    id = getLocalStorage('practiceLocationId') ?? null
  }

  return id
}

export const fetchPracticeLocation = (
  practiceLocationId = null,
  store = {}
) => {
  if (!practiceLocationId || !isHavingProps(store)) return []

  const params = {
    full: true,
    include: `${api.PRACTICE_LOCATION_DISTRIBUTORS}`,
  }

  return store.dispatch('jvZen/get', [
    `${api.PRACTICE_LOCATIONS}/${practiceLocationId}`,
    { params },
  ])
}

export const getActualFailedDistributorsIds = (practiceLocationData = {}) => {
  return convertCollection(
    practiceLocationData?.['practice-location-distributors']
  )
    .filter((pld) => pld.status === STATUS_FAILED)
    .map((pld) => {
      return pld?.distributorId ? parseInt(pld.distributorId) : null
    })
}

export const updateStore = (store = {}, value = []) => {
  if (!isHavingProps(store)) return false
  store.commit('socket/SET_NOTIFICATIONS_BY_SUBTYPE', {
    subType: DISTRIBUTOR_PROCESS_SUBTYPE_LOGIN_RESULT,
    value,
  })
}
