
import Vuex from 'vuex'
import { isNotUndefined } from '~/ZenOne-StoryBook/helpers/types'

export default {
  layout: 'login',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      pageNotFound: '404 Not Found',
      otherError:
        'Something went wrong. Click the button below to go to Catalog',
    }
  },
  computed: {
    ...Vuex.mapGetters({
      user: 'user/user',
    }),
    is404() {
      return this.error.statusCode === 404
    },
    msg() {
      // return this.is404 ? this.pageNotFound : this.otherError
      return this.otherError
    },
  },
  beforeCreate() {
    const loggedIn = this?.$auth?.loggedIn
    if (
      !isNotUndefined(loggedIn) ||
      (isNotUndefined(loggedIn) && loggedIn === false)
    ) {
      this.$router.push('/login')
    }
  },
}
