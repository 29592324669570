import actions from './actions'
import mutations from './mutations'
import {
  getInitialDeleteItemsFromCart,
  getInitialEventNotificationList,
} from '~/store/modules/socket/helper'

const socket = {
  state: {
    notifications: {
      subscriptionPayment: [],
      invoicePayment: [],
      deleteItemsFromCart: getInitialDeleteItemsFromCart(),
      eventNotificationList: getInitialEventNotificationList(),
      distributorLoginResult: [],
      invalidLoginCheck: false,
    },
    prices: {},
  },
  getters: {
    subscriptionPayment: (state) => state.notifications.subscriptionPayment,
    invoicePayment: (state) => state.notifications.invoicePayment,
    deleteItemsFromCart: (state) => state.notifications.deleteItemsFromCart,
    eventNotificationList: (state) => state.notifications.eventNotificationList,
    distributorLoginResult: (state) =>
      state.notifications.distributorLoginResult,
    invalidLoginCheck: (state) => state.notifications.invalidLoginCheck,
    prices: (state) => state.prices,
  },
  mutations,
  actions,
  namespaced: true,
}

export default socket
