import { mapGetters } from 'vuex'
import catalogFiltersQueryParams from './catalog-filters-query-params'
import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'
import storage from '~/ZenOne-StoryBook/mixins/localStorage'
import { isObjectNotUndefinedHasProps } from '~/ZenOne-StoryBook/helpers/object'
export default {
  mixins: [storage, catalogFiltersQueryParams],
  data() {
    return {
      isClearFilter: false,
    }
  },
  computed: {
    ...mapGetters({
      filterOptions: 'catalog/filterOptions',
      distributorsOverall: 'catalog/distributorsOverall',
    }),
    showFilterManufacturers() {
      return isArrayWithLength(this.manufacturerOptions)
    },
    showFilterPackages() {
      return isArrayWithLength(this.packageOptions)
    },
    showFilterCategories() {
      return isArrayWithLength(this.categoryOptions)
    },
    showFilterGenerics() {
      return isArrayWithLength(this.genericOptions)
    },
    showFilterModifiers() {
      return isObjectNotUndefinedHasProps(this.modifierOptions)
    },
    isDistributorFilterActive() {
      return isArrayWithLength(this.filters?.distributorId)
    },
    genericOptions() {
      return this.filterOptions?.generics ?? []
    },
    categoryOptions() {
      return this.filterOptions?.categories ?? []
    },
    distributorOptions() {
      const { distributorsOverall = [] } = this
      const options = this.filterOptions?.distributors
      return isArrayWithLength(options)
        ? options
        : isArrayWithLength(distributorsOverall)
        ? distributorsOverall
        : []
    },
    manufacturerOptions() {
      return this.filterOptions?.manufacturers ?? []
    },
    packageOptions() {
      return this.filterOptions?.packages ?? []
    },
    modifierOptions() {
      return this.filterOptions?.modifiers ?? []
    },
  },
}
