import * as types from './mutation-types'
import { RESPONSE_STATUS_OK } from '~/ZenOne-StoryBook/helpers/response'
import {
  isArrayWithLength,
  isNotUndefinedArrayWithLength,
  isObject,
} from '~/ZenOne-StoryBook/helpers/types'
import { mapFolder } from '~/helpers/inventory/folder'
import {
  isHavingProps,
  isObjectNotUndefinedHasProps,
} from '~/ZenOne-StoryBook/helpers/object'
import {
  getFilterQuery,
  OPTIONS_BY_FILTER_NAME,
  getRequestParams,
} from '~/store/modules/inventory/helper'
import { mapFolderItemsList } from '~/components/Inventory/helper'
import { mapLaravelPagination } from '~/ZenOne-StoryBook/helpers/pagination'
import { ERROR } from '~/ZenOne-StoryBook/helpers/ui'

export default {
  async FetchFolderItems({ getters }) {
    try {
      const params = getRequestParams(
        getters.sortValue,
        getters.pagination,
        getFilterQuery(getters.filters)
      )
      const res = await this.$axios.get(
        `workspaces/${getters.folderId}/items`,
        {
          params,
        }
      )
      if (res?.status !== RESPONSE_STATUS_OK) {
        throw new Error('Response error')
      }
      const data = res?.data ?? {}
      return {
        data: data?.data ?? [],
        meta: data?.meta ?? {},
      }
    } catch (e) {
      console.error(
        `Fetch folder items: ${e?.response?.data?.errors?.[0]?.detail ?? e}`
      )
      return false
    }
  },
  async SetFolderItems(
    { commit, state, getters, dispatch },
    { needClearList = false, useLoader = true, saveScrollPos = false }
  ) {
    if (getters.isLoading) return false
    commit('SET_IS_LOADING', true)
    try {
      const scrollPosition = getters.scrollPosition
      if (useLoader) {
        commit('ui/SET_SHOW_FULL_SCREEN_LOADER', true, { root: true })
      }
      if (needClearList) {
        commit('SET_FOLDER_ITEMS', [])
        commit('SET_PAGINATION_PROP', { propName: 'currentPage', value: 1 })
      }
      const { data = {}, meta = {} } = await dispatch('FetchFolderItems')
      let pagination = { ...mapLaravelPagination(meta) }
      if (saveScrollPos) {
        pagination = {
          ...pagination,
          scrollPosition,
        }
      }
      commit('SET_PAGINATION', {
        ...pagination,
        folderId: getters.folderId,
      })
      if (data && isArrayWithLength(data)) {
        const { list = [] } = mapFolderItemsList(
          data,
          getters.folderColumnsVisibility
        )
        commit(
          'SET_FOLDER_ITEMS',
          needClearList ? list : [...getters.folderItems, ...list]
        )
        if (saveScrollPos) {
          if (getters.scrollPosition === 0) return false
          let tableEl = document.querySelector('.folder-template-table')
          if (!tableEl) return false
          setTimeout(() => {
            tableEl.scrollTo(0, getters.scrollPosition)
            tableEl = null
          }, 10)
        }
      }
    } catch (e) {
      console.error('Set folder items: ', e)
    } finally {
      commit('SET_IS_LOADING', false)
      if (useLoader) {
        commit('ui/SET_SHOW_FULL_SCREEN_LOADER', false, { root: true })
      }
    }
  },

  SetActiveFolder({ commit, state }, id = null) {
    try {
      if (!id) throw new Error('No folder id')

      const folders = [...state.folders]
      if (!isArrayWithLength(folders)) throw new Error('Folder list is empty')

      const index = folders.findIndex(f => parseInt(f.id) === parseInt(id))
      if (index === -1) throw new Error('No such folder with id: ' + id)

      commit(types.SET_ACTIVE_FOLDER, { ...folders[index] })
    } catch (e) {
      console.error('Set active inventory folder: ', e)
    }
  },

  async SetFolders({ commit, dispatch, rootState }, needToCheckFilters = false) {
    try {
      const response = await this.$axios.get(`/workspaces/`, {})
      if (response?.status !== RESPONSE_STATUS_OK) {
        throw new Error('Response error')
      }

      commit(types.SET_FILTER_OPTIONS, response?.data?.meta)
      const folders = response?.data?.data
      if (!isArrayWithLength(folders)) return []
      commit(types.SET_FOLDERS, folders.map((folder) => mapFolder(folder)))

      if (needToCheckFilters) dispatch('CheckFilters')
    } catch (e) {
      console.error('Get Inventory folders: ', e)
    }
  },

  async GetFolderById({ commit }, id = null) {
    try {
      if (!id) throw new Error('No folder id')
      const response = await this.$axios.get(`/workspaces/${id}`, {})
      const responseStatus = response?.status ?? ''
      if (responseStatus !== RESPONSE_STATUS_OK) {
        throw new Error('Response error')
      }
      const folder = response?.data?.data ?? {}
      if (!folder || !isObject(folder) || !isHavingProps(folder)) return {}
      return mapFolder(folder)
    } catch (e) {
      console.error('Get Inventory folder by id: ', e)
    }
  },
  async SetFolderById({ commit, dispatch, getters }, id = null) {
    try {
      const folder = await dispatch('GetFolderById', id)
      commit(types.SET_FOLDER_BY_ID, { id, data: folder })
      if ([getters.folderId, getters.sharedFolderIdByRoute].includes(id)) {
        dispatch('SetActiveFolder', id)
      }
    } catch (e) {
      console.error('Set Inventory folder by id: ', e)
    }
  },
  CheckFilters({ commit, getters }) {
    try {
      const filters = { ...getters.filters }
      const filterOptions = { ...getters.filterOptions }
      const folderItems = [...getters.folderItems]

      if (folderItems.length > 1 || !isHavingProps(filters) || !isHavingProps(filterOptions)) return false

      let filtersCopy = { ...filters }
      for (const filterName in filters) {
        const values = filters?.[filterName] ?? []
        if (!isArrayWithLength(values)) continue
        const options =
          filterOptions?.[OPTIONS_BY_FILTER_NAME[filterName]] ?? []
        if (!isArrayWithLength(options)) {
          continue
        }
        const currentFilter = [...filtersCopy[filterName]]
        for (const value of values) {
          if (options.includes(value)) continue
          const set = new Set(currentFilter)
          set.delete(value)
          filtersCopy = {
            ...filtersCopy,
            [filterName]: [...set],
          }
        }
        if (!isArrayWithLength(filtersCopy[filterName]))
          delete filtersCopy[filterName]
      }
      commit(types.SET_FILTERS, filtersCopy)
      return true
    } catch (e) {
      console.error('Check Inventory filters: ', e)
    }
  },
  ResetStateExceptFolders({ commit, getters }) {
    if (isObjectNotUndefinedHasProps(getters?.filterOptions))
      commit('SET_FILTER_OPTIONS', {})
    if (isNotUndefinedArrayWithLength(getters.folderItems))
      commit('SET_FOLDER_ITEMS', [])
    if (isObjectNotUndefinedHasProps(getters?.folder))
      commit('SET_ACTIVE_FOLDER', {})
    commit('RESET_PAGINATION')
  },
  setLastMoveItemsData({ commit }, payload = {}) {
    commit('SET_LAST_MOVE_ITEMS_DATA', payload)
  },
  clearLastMoveItemsData({ commit }) {
    commit('SET_LAST_MOVE_ITEMS_DATA', {})
  },
  setWorkspaceItemRemovedIds({ commit }, payload) {
    commit('SET_WORKSPACE_ITEM_REMOVED_IDS', payload)
  },
  clearWorkspaceItemRemovedIds({ commit }) {
    commit('SET_WORKSPACE_ITEM_REMOVED_IDS', [])
  },
  async deleteFolderItemsBatch(
    { commit },
    { selectedList = [], folderId = null, force = false }
  ) {
    try {
      if (!folderId) throw new Error('No folder id')
      if (!isArrayWithLength(selectedList)) {
        throw new Error('No items selected')
      }
      let data = {
        workspaceItemIds: selectedList,
      }
      if (force)
        data = {
          ...data,
          force: 1,
        }
      const res = await this.$axios.post(
        `workspaces/${folderId}/items/delete`,
        data
      )
      if (res?.status !== RESPONSE_STATUS_OK)
        throw new Error('Response error: ' + res.status)
      return true
    } catch (e) {
      const error = `Delete
                     from folder: ${
                       e?.response?.data?.errors?.[0]?.detail ?? e
                     }`
      commit(
        'ui/SHOW_ALERT',
        {
          type: ERROR,
          content: error,
          show: true,
        },
        { root: true }
      )
      console.error(error)
      return false
    }
  },
  async moveBackItems({ commit }, workspaceItemIds = []) {
    try {
      if (!isArrayWithLength(workspaceItemIds))
        throw new Error('No workspaceItem ids')
      const response = await this.$axios.patch(`/workspaces/items/move-back`, {
        workspaceItemIds,
      })
      const responseStatus = response?.status ?? ''
      if (responseStatus !== RESPONSE_STATUS_OK) {
        throw new Error('Response error')
      }
      const data = response?.data ?? {}
      if (!isObjectNotUndefinedHasProps(data) || !data?.success) {
        throw new Error('Result error')
      }
      return data?.workSpaceItem ?? []
    } catch (e) {
      console.error('Move back items: ', e)
    }
  },
}
