export const SET_USER = 'SET_USER'
export const SET_EMAIL_IS_CONFIRMED = 'SET_EMAIL_IS_CONFIRMED'
export const SET_BUDGET = 'SET_BUDGET'
export const SET_NAME = 'SET_NAME'
export const SET_CARD = 'SET_CARD'
export const SET_PRACTICE = 'SET_PRACTICE'
export const SET_PRACTICE_ID = 'SET_PRACTICE_ID'
export const SET_CURRENT_PRACTICE_LOCATION_ID = 'SET_CURRENT_PRACTICE_LOCATION_ID'
export const SET_PRACTICE_LOCATION = 'SET_PRACTICE_LOCATION'
export const SET_BUDGET_CURRENT = 'SET_BUDGET_CURRENT'
export const SET_AUTHORIZED_ONLY = 'SET_AUTHORIZED_ONLY'
export const SET_CARD_WAS_UPDATED_ON_RECHARGE =
  'SET_CARD_WAS_UPDATED_ON_RECHARGE'
export const SET_PRACTICE_LOCATION_STATUS_WAS_UPDATED_ON_RECHARGE =
  'SET_PRACTICE_LOCATION_STATUS_WAS_UPDATED_ON_RECHARGE'
export const SET_WIZARD_IS_COMPLETED = 'SET_WIZARD_IS_COMPLETED'
