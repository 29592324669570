
import { mapGetters } from 'vuex'
import Alert from '~/ZenOne-StoryBook/components/alert'

export default {
  components: {
    Alert,
  },
  computed: {
    ...mapGetters({
      alert: 'ui/alert',
    }),
  },
  methods: {
    closeAlert() {
      this.$store.commit('ui/HIDE_ALERT')
    },
  },
}
