import { isArrayWithLength, isObject } from '~/ZenOne-StoryBook/helpers/types'
import { isHavingProps } from '~/ZenOne-StoryBook/helpers/object'
import { getArrayCopyFromObjectProp } from '~/ZenOne-StoryBook/helpers/array'

export const getCatalogFilterTags = (
  filterOptions = {},
  filters = {},
  defaultFilters = {},
  optionsByFilterName = {},
  sortFn = null
) => {
  if (
    !isObject(filterOptions) ||
    !isObject(filters) ||
    !isHavingProps(filterOptions) ||
    !isHavingProps(filters)
  )
    return []
  let tags = []
  for (const filterName in defaultFilters) {
    if (filterName === 'fromOrderHistory') {
      const value = filters?.fromOrderHistory ?? false
      if (!value) continue
      tags = [
        ...tags,
        {
          id: filters?.[filterName],
          name: 'In: Order History',
          filterName,
        },
      ]
    } else {
      const optionsName = optionsByFilterName[filterName]
      const optionsList =
        filterName !== 'modifierValueId'
          ? getArrayCopyFromObjectProp(filterOptions, optionsName)
          : Object.values(filterOptions[optionsName]).reduce(
              (acc, current) => [...acc, ...current],
              []
            )

      tags = [
        ...tags,
        ...getTagsByType(
          filterName,
          getArrayCopyFromObjectProp(filters, filterName),
          optionsList
        ),
      ]
      // }
    }
  }
  return !sortFn ? tags : sortFn(tags)
}

export const getInventoryFilterTags = (
  filterOptions = {},
  filters = {},
  defaultFilters = {},
  optionsByFilterName = {},
  sortFn = null
) => {
  if (!isHavingProps(filterOptions) || !isHavingProps(filters)) return []

  let tags = []
  for (const filterName in defaultFilters) {
    if (filterName === 'folderId') {
      const val = filters?.[filterName]?.[0]
      if (!val) continue

      tags = [
        ...tags,
        {
          id: parseInt(val),
          name: 'Not In Folder',
          filterName
        }
      ]
    } else if (filterName === 'onlyBackordered') {
      const val = filters?.[filterName]
      if (!val) continue

      tags = [
        ...tags,
        {
          id: 1,
          name: 'Only Backordered',
          filterName
        }
      ]
    } else {
      const optionsName = optionsByFilterName[filterName]
      tags = [
        ...tags,
        ...getTagsByType(
          filterName,
          getArrayCopyFromObjectProp(filters, filterName),
          getArrayCopyFromObjectProp(filterOptions, optionsName)
        ),
      ]
    }
  }
  return !sortFn ? tags : sortFn(tags)
}

const getTagsByType = (filterName = '', valueList = [], optionsList = []) => {
  const tags = []
  if (
    !filterName ||
    !isArrayWithLength(valueList) ||
    !isArrayWithLength(optionsList)
  )
    return []
  for (const valueId of valueList) {
    const index = optionsList.findIndex((optionItem) => {
      return parseInt(optionItem?.id) === parseInt(valueId)
    })
    if (index === -1) {
      continue
    }
    tags.push({
      ...optionsList[index],
      filterName,
    })
  }
  return tags
}
