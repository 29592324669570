import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'
import { getLocalStorageProp } from '~/ZenOne-StoryBook/helpers/localStorage'
import { isObjectNotUndefinedHasProps } from '~/ZenOne-StoryBook/helpers/object'

export const CART_LS_ALIAS = 'ZenOne__Consumers__Cart'
export const CART_ITEM_SELECTION_TYPE = 'cart'
export const SFL_ITEM_SELECTION_TYPE = 'savedForLater'

export const getFilteredDistributors = (distributors = [], searchTerm = '') => {
  if (!searchTerm || searchTerm.length < 2) return distributors
  const res = []
  searchTerm = searchTerm.trim().toLowerCase()
  for (const distributor of distributors) {
    const cartItems = distributor?.cartItems ?? []
    if (!isArrayWithLength(cartItems)) continue
    const cartItemsFiltered = cartItems.filter((ci) => {
      const productName = ci?.productName.toLowerCase()
      const sku = ci?.productDistributorSku.toLowerCase()
      const distributorName = ci?.distributorName.toLowerCase()
      const manufacturerName = ci?.manufacturerName.toLowerCase()
      const manufacturerSku = ci?.manufacturerSku.toLowerCase()
      return [
        productName,
        sku,
        distributorName,
        manufacturerName,
        manufacturerSku,
      ].some((item) => item.includes(searchTerm))
    })
    if (!isArrayWithLength(cartItemsFiltered)) continue
    res.push({
      ...distributor,
      cartItems: [...cartItemsFiltered],
    })
  }
  return res
}

export const getCartItemsAll = (distributors = [], savedForLaterList = []) => {
  if (!isArrayWithLength(distributors) && !isArrayWithLength(savedForLaterList))
    return []
  let list = []
  if (isArrayWithLength(distributors)) {
    for (const distributor of distributors) {
      const cartItems = distributor?.cartItems ?? []
      if (!isArrayWithLength(cartItems)) continue
      for (const cartItem of cartItems) {
        list.push(cartItem)
      }
    }
  }
  if (isArrayWithLength(savedForLaterList)) {
    list = [...list, ...savedForLaterList]
  }

  return list
}

export const getIsAllSelected = (selectedIds = [], totalCount = 0) => {
  if (!isArrayWithLength(selectedIds) || totalCount === 0) return false
  return selectedIds.length === totalCount
}

export const getCartItemIds = (distributors = []) => {
  const ids = []
  distributors.forEach((distributor) => {
    const cartItems = distributor?.cartItems ?? []
    if (isArrayWithLength(cartItems)) {
      for (const cartItem of cartItems) {
        const cartItemId = cartItem?.id ?? null
        if (!cartItemId) continue
        ids.push(cartItemId)
      }
    }
  })
  return ids
}

export const getSelectedIdsByType = (type = '', getters = {}) => {
  if (!type || !isObjectNotUndefinedHasProps(getters)) return []
  if (type === CART_ITEM_SELECTION_TYPE) return getInitialCartSelectedIds()
  else if (type === SFL_ITEM_SELECTION_TYPE) return getInitialSflSelectedIds()
}

export const getSavedForLaterIds = (list = []) => {
  if (!isArrayWithLength(list)) return []
  return list.map((i) => parseInt(i?.id) ?? null)
}

export const getSavingsList = (distributors = []) => {
  if (!isArrayWithLength(distributors)) return []
  const list = []
  distributors.forEach((distributor) => {
    const cartItems = distributor?.cartItems
    if (isArrayWithLength(cartItems)) {
      for (const cartItem of cartItems) {
        const savings = cartItem?.savings ?? []
        const productNameForSort = savings?.[0]?.productNameForSort ?? ''
        if (isArrayWithLength(savings)) {
          list.push({
            productNameForSort,
            cartItemId: cartItem?.id ?? null,
            productName: cartItem?.productName ?? '',
            items: savings,
          })
        }
      }
    }
  })
  return list.sort((a, b) =>
    a.productNameForSort.localeCompare(b.productNameForSort)
  )
}

export const getInitialCartItemIds = () => {
  return getLocalStorageProp(CART_LS_ALIAS, 'cartItemIds') ?? []
}

export const getInitialCartSelectedIds = () => {
  return getLocalStorageProp(CART_LS_ALIAS, 'cartSelectedIds') ?? []
}

export const getInitialSflIds = () => {
  return getLocalStorageProp(CART_LS_ALIAS, 'savedForLaterIds') ?? []
}

export const getInitialSflSelectedIds = () => {
  return getLocalStorageProp(CART_LS_ALIAS, 'savedForLaterSelectedIds') ?? []
}

export const getSelectedSubtotalQty = (distributors = []) => {
  if (!isArrayWithLength(distributors)) return 0
  let sum = 0
  distributors.forEach((distributor) => {
    const cartItems = distributor?.cartItems
    if (isArrayWithLength(cartItems)) {
      for (const cartItem of cartItems) {
        if (cartItem?.checked !== true) continue
        sum += cartItem?.qty ?? 0
      }
    }
  })
  return sum
}

export const combineDataToUpdateTops = (
  cartItemsAll = [],
  cartItemIds = [],
  addedShoppingCart = false
) => {
  if (!isArrayWithLength(cartItemsAll) || !isArrayWithLength(cartItemIds))
    return []
  const productDistributors = []
  for (const cartItemId of cartItemIds) {
    const index = cartItemsAll.findIndex((ci) => ci.id === cartItemId)
    if (index === -1) continue
    const cartItem = cartItemsAll[index]
    productDistributors.push({
      productDistributorId: cartItem?.productDistributorId
        ? parseInt(cartItem.productDistributorId)
        : null,
      cartItem: {
        id: cartItem?.id ? parseInt(cartItem.id) : null,
        qty: cartItem?.qty ?? 0,
      },
      addedShoppingCart,
    })
  }
  return productDistributors
}
