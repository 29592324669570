import { getLocalStorage } from '~/ZenOne-StoryBook/helpers/localStorage'
import {
  CONSUMER_CART_ALIAS,
  EVENT_NOTIFICATIONS_ALIAS,
} from '~/helpers/common'

export const getInitialEventNotificationList = () => {
  return (
    getLocalStorage(EVENT_NOTIFICATIONS_ALIAS)?.eventNotificationList || {
      loginResult: [],
      orderHistoryUploaded: [],
      clientPricesUploaded: [],
      orderDeliveryConfirmation: [],
    }
  )
}

export const getInitialDeleteItemsFromCart = () => {
  return getLocalStorage(CONSUMER_CART_ALIAS)?.deleteItemsFromCart || []
}
