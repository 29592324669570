import Vuex from 'vuex'
import ui from './modules/ui'
import user from './modules/user'
import shoppingCart from './modules/shopping_cart'
import catalog from './modules/catalog'
import search from './modules/search'
import socket from './modules/socket'
import subscription from './modules/subscription'
import inventory from './modules/inventory'
import distributors from './modules/distributors'
import issue from './modules/issue'
import manageOrders from './modules/manage_orders'
import prices from './modules/prices'
import resources from './modules/resources'
import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'

const modules = {
  ui,
  inventory,
  user,
  shoppingCart,
  catalog,
  search,
  socket,
  subscription,
  distributors,
  issue,
  manageOrders,
  prices,
  resources,
}

const store = new Vuex.Store({
  namespaced: true,
  modules,
  strict: process.env.NODE_ENV !== 'production',
})

store.watch(
  (state, getters) => getters['socket/distributorLoginResult'],
  async (value) => {
    if (!isArrayWithLength(value)) return false
    await store.dispatch('catalog/SetDistributorList')
  }
)

const createStore = () => {
  return store
}

export { store }
export default createStore
