import { mapActions, mapGetters, mapMutations } from 'vuex'
import { getFolderName } from '~/components/Inventory/helper'
import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'

export default {
  computed: {
    ...mapGetters({
      folders: 'inventory/folders',
      folderItems: 'inventory/folderItems',
    }),
  },
  methods: {
    ...mapActions({
      SetActiveFolder: 'inventory/SetActiveFolder',
    }),
    ...mapMutations({
      setFolderItems: 'inventory/SET_FOLDER_ITEMS',
      setFolders: 'inventory/SET_FOLDERS',
    }),
    updateFolderCountsAfterMoving({
      destinationFolderId = null,
      movedItemsCount = null,
    }) {
      const folders = [...this.folders]
      const folder = { ...this.folder }
      const currentFolderId = folder?.id ?? null
      const currentFolderCount = folder?.count ?? 0
      if (!this.currentFolderIsRoot) {
        const index = folders.findIndex((f) => f.id === currentFolderId)
        if (index !== -1) {
          folders[index] = {
            ...folder,
            count: currentFolderCount - movedItemsCount,
          }
        }
      }
      const destIndex = folders.findIndex((f) => f.id === destinationFolderId)
      if (destIndex !== -1) {
        const destFolder = { ...folders[destIndex] }
        const destFolderCount = destFolder?.count ?? 0
        folders[destIndex] = {
          ...destFolder,
          count: destFolderCount + movedItemsCount,
        }
      }
      this.setFolders([...folders])
      this.SetActiveFolder(currentFolderId)
    },
    updateFolderItemsAfterMoving({
      destinationFolderId = null,
      workspaceItemIds = [],
    }) {
      if (!destinationFolderId || !isArrayWithLength(workspaceItemIds))
        return false

      const folders = [...this.folders]
      const folderItems = [...this.folderItems]
      const destFolderName = getFolderName(destinationFolderId, folders)
      for (const workspaceItemId of workspaceItemIds) {
        const index = folderItems.findIndex(
          (fi) => parseInt(fi.id) === parseInt(workspaceItemId)
        )
        if (index === -1) continue
        if (this.currentFolderIsRoot) {
          const fi = {
            ...folderItems[index],
            location: destFolderName,
            folderId: destinationFolderId,
          }
          folderItems[index] = { ...fi }
        } else {
          folderItems.splice(index, 1)
        }
      }
      this.setFolderItems([...folderItems])
    },
  },
}
