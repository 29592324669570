// distributor-manage-practices //
import moment from 'moment'
import _ from 'lodash'
import { isNotUndefined } from '../../../helpers/types'

export const status = {
  PAST_DUE: 'past_due',
  ACTIVE: 'active',
  TRIALING: 'trialing',
  INACTIVE: 'inactive',
  CANCELED: 'canceled',
  DRAFT: 'draft',
}

export const statusList = [
  { id: 1, text: 'Past Due', value: status.PAST_DUE },
  { id: 2, text: 'Draft', value: status.DRAFT },
  { id: 3, text: 'Active', value: status.ACTIVE },
  { id: 4, text: 'Trialing', value: status.TRIALING },
  { id: 5, text: 'Inactive', value: status.INACTIVE },
  { id: 6, text: 'Canceled', value: status.CANCELED },
]

export const sortTypes = {
  BY_PRACTICE_NAME: 'name',
}

export const SORT_ALIAS_LS = 'DistributorPractices__Sort'
export const STATUS_FILTER_ALIAS_LS = 'DistributorPractices__Filter__Status'

export const mapPracticeLocations = (list, distributorId) => {
  return list.reduce((acc, item) => {
    const { name: practiceLocationName, address, status, practice } = item

    let { id: practiceLocationId } = item

    const distributorSettings = item?.['practice-location-distributors']?.data
    let distributorSetting = null
    distributorSetting = _.find(
      distributorSettings,
      (el) => parseInt(el.distributorId) === parseInt(distributorId)
    )

    const {
      data: { licenseNumber, licenseParameters, dea },
    } = practice
    practiceLocationId = parseInt(practiceLocationId)
    const practiceLocation = {
      practiceLocationId,
      customerId: mapCustomerId(item, distributorId),
      practiceLocationDistributorId: mapPracticeLocationDistributorId(
        item,
        distributorId
      ),
      practiceLocationName,
      unit: item?.address?.unit,
      address: mapAddress(address),
      licenseNumber,
      expirationDate: mapExpirationDate(licenseParameters),
      phone: mapPhone(item),
      dea,
      status,
      distributorSettings: distributorSetting || null,
      userId: item?.user?.data?.id,
      doctorName: mapDoctorName(item),
    }
    acc.push(practiceLocation)
    return acc
  }, [])
}

export const mapDoctorName = (practiceLocation) => {
  const { user = null } = practiceLocation
  if (user) {
    const user = practiceLocation.user.data
    if (user) {
      return user.name
    }
  }
  return null
}

export const mapExpirationDate = (licenseParameters) => {
  let { expirationDate = null } = licenseParameters
  if (expirationDate) {
    expirationDate = moment(expirationDate).format('MM/DD/YY')
    return expirationDate
  }
  return null
}

export const mapAddress = (address) => {
  let result = ''
  if (address) {
    const {
      building = null,
      street = null,
      city = null,
      state = null,
      postcode = null,
    } = address
    if (building) result += `${building} `
    if (street) result += `${street} `
    if (city) result += `${city}, `
    if (state) result += `${state} `
    if (postcode) result += `${postcode}`
  }
  return result
}

export const mapCustomerId = (item, distributorId) => {
  const {
    'practice-location-distributors': {
      data: practiceLocationDistributors = null,
    },
  } = item
  if (
    !practiceLocationDistributors ||
    !Array.isArray(practiceLocationDistributors) ||
    practiceLocationDistributors.length === 0
  )
    return null
  let data = null
  let i = 0
  for (i; i < practiceLocationDistributors.length; i++) {
    if (practiceLocationDistributors[i].distributorId === distributorId) {
      data = practiceLocationDistributors[i]
      break
    }
  }
  if (data) {
    const customerId = data?.customerId
    return isNotUndefined(customerId) ? customerId : null
  }
  return null
}

export const mapPracticeLocationDistributorId = (item, distributorId) => {
  const {
    'practice-location-distributors': {
      data: practiceLocationDistributors = null,
    },
  } = item
  if (
    !practiceLocationDistributors ||
    !Array.isArray(practiceLocationDistributors) ||
    practiceLocationDistributors.length === 0
  )
    return null
  let data = null
  let i = 0
  for (i; i < practiceLocationDistributors.length; i++) {
    if (practiceLocationDistributors[i].distributorId === distributorId) {
      data = practiceLocationDistributors[i]
      break
    }
  }
  if (data) {
    const id = data?.id
    return isNotUndefined(id) ? parseInt(id) : null
  }
  return null
}

export const mapPhone = (item) => {
  const phone = item?.params?.phone
  return isNotUndefined(phone) ? phone : 'No phone'
}
