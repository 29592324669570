import actions from './actions'
import mutations from './mutations'
import {
  DEFAULT_POSITION,
  getHasNoSuggestions,
} from '~/store/modules/search/helper'

const search = {
  state: {
    searchTerm: '',
    makeSearchRequestTrigger: false,
    dropdownPosition: DEFAULT_POSITION,
    // historyStuff
    history: [],
    clickedOnDeleteHistoryItem: false,
    // suggestsStuff
    mostRecentSuggestString: '',
    isLoadingSuggestions: false,
    categoryList: [],
    modifierValueList: [],
  },
  getters: {
    searchTerm: (state) => state.searchTerm,
    makeSearchRequestTrigger: (state) => state.makeSearchRequestTrigger,
    dropdownPosition: (state) => state.dropdownPosition,
    // historyStuff
    history: (state) => state.history,
    clickedOnDeleteHistoryItem: (state) => state.clickedOnDeleteHistoryItem,
    // suggestsStuff:
    joinedSuggestionList: (state, getters) => [
      ...getters.categoryList,
      ...getters.modifierValueList,
    ],
    mostRecentSuggestString: (state) => state.mostRecentSuggestString,
    isLoadingSuggestions: (state) => state.isLoadingSuggestions, // todo rename
    categoryList: (state) => state.categoryList,
    modifierValueList: (state) => state.modifierValueList,
    hasNoSuggestions: (state, getters) =>
      getHasNoSuggestions(getters.categoryList, getters.modifierValueList),
  },
  namespaced: true,
  actions,
  mutations,
}
export default search
