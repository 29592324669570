import _ from 'lodash'
import moment from 'moment'
import * as types from './mutation-types'
import { mapByOrderFilterOptions } from './helper'
import { api } from '~/config/api'
import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'

const DATE_FORMAT = 'YYYY-MM-DD'
export default {
  async loadDistributors({ commit, dispatch }) {
    const { data } = await dispatch(
      'jvZen/get',
      [
        `${api.DISTRIBUTORS}`,
        {
          params: {
            full: true,
            'filter[orderedProductDistributor]': true,
          },
        },
      ],
      {
        root: true,
      }
    )

    const availableDistributors = _.sortBy(data, (item) =>
      item.name.toLowerCase()
    )
    commit(types.SET_DISTRIBUTORS, availableDistributors)
  },
  // only difference is filter[fromOrders] param
  async loadDistributorsForPaymentHistory({ commit, dispatch }) {
    const { data } = await dispatch(
      'jvZen/get',
      [
        `${api.DISTRIBUTORS}`,
        {
          params: {
            full: true,
            'filter[fromOrders]': true,
          },
        },
      ],
      {
        root: true,
      }
    )

    const availableDistributors = _.sortBy(data, (item) =>
      item.name.toLowerCase()
    )

    availableDistributors.push({ id: -1, name: 'ZenOne' })

    commit(types.SET_DISTRIBUTORS_PAYMENT_HISTORY, availableDistributors)
  },
  async setByOrderFilterOptions({ dispatch, commit }) {
    commit(
      'SET_BY_ORDER_FILTER_OPTIONS',
      await dispatch('fetchByOrderFilterOptions')
    )
  },
  async fetchByOrderFilterOptions({ dispatch, getters }) {
    try {
      const params = {
        full: true,
        sort: '-id',
        'filter[status]': 'in_progress,on_hold,completed',
      }
      const filtersState = getters.filters
      const distributorId = filtersState?.distributorId ?? []
      if (isArrayWithLength(distributorId)) {
        params['filter[distributorId]'] = distributorId.join(',')
      }
      const dateTo = filtersState?.dateTo ?? ''
      if (dateTo) {
        params['filter[orderDate][to]'] = moment(dateTo)
          .utc()
          .format(DATE_FORMAT)
      }
      const dateFrom = filtersState?.dateFrom ?? ''
      if (dateFrom) {
        params['filter[orderDate][from]'] = moment(dateFrom)
          .utc()
          .format(DATE_FORMAT)
      }

      const res = await dispatch('jvZen/get', [`${api.ORDERS}`, { params }], {
        root: true,
      })
      const data = res?.data ?? []
      if (!isArrayWithLength(data)) return []
      return mapByOrderFilterOptions(data)
    } catch (e) {
      console.error('Get purchase order filter options list error')
      return []
    }
  },
  setPurchaseOrderIdFilter({ commit }, payload = null) {
    commit('SET_PURCHASE_ORDER_ID_FILTER', payload)
  },
}
