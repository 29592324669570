import moment from 'moment'
import noImage from '~/components/img/no-image.svg'
import { isNotUndefined } from '~/ZenOne-StoryBook/helpers/types'
import { parseFloat2Digits } from '~/ZenOne-StoryBook/helpers/numbers'
import { isHavingProps } from '~/ZenOne-StoryBook/helpers/object'
import {
  TOP_10_MOST_NAME,
  TOP_10_ORDERED_NAME,
  TOP_10_RECENTLY_NAME,
} from '~/components/Catalog/constants'

export const PRODUCT_TYPE_MANUFACTURER = 'manufacturer'
export const PRODUCT_TYPE_DISTRIBUTOR = 'distributor'
export const CATALOG_ALIAS = 'ZenOne__Consumers__Catalog'

const getProductImage = (product) => {
  const images = product?.images
  if (isNotUndefined(images)) {
    const keys = Object.keys(images)
    if (keys.length) {
      const path = images[keys?.[0]]?.path
      if (isNotUndefined(path)) return path
    }
    return noImage
  }
}

export const mapProductDistributors = (list) => {
  return list.reduce((acc, product) => {
    const item = mapProductData(product, PRODUCT_TYPE_DISTRIBUTOR, false)
    acc.push(item)
    return acc
  }, [])
}

export const getProductManufacturerPrice = (
  priceMinDistributor = null,
  priceMaxDistributor = null
) => {
  return getMinMaxPrice(priceMinDistributor, priceMaxDistributor)
}

export const getOrderHistory = (
  type = PRODUCT_TYPE_MANUFACTURER,
  lastOrderedPracticeLocationDate = null
) => {
  if (type === PRODUCT_TYPE_MANUFACTURER) return null
  return lastOrderedPracticeLocationDate
    ? moment.utc(lastOrderedPracticeLocationDate).format('MM/DD/YY')
    : null
}

export const getMinMaxPrice = (min, max) => {
  if (min === 0) {
    if (max === 0) return null
    else return parseFloat2Digits(max)
  } else if (max === 0) return parseFloat2Digits(min)
  else return `${parseFloat2Digits(min)} - ${parseFloat2Digits(max)}`
}

export const mapProductData = (product) => {
  const productDistributor = product?.productDistributor ?? {}
  const productDistributorId = productDistributor?.id ?? null
  const productManufacturer = product?.product ?? {}
  const type =
    productManufacturer?.showProductDistributor === true
      ? PRODUCT_TYPE_DISTRIBUTOR
      : PRODUCT_TYPE_MANUFACTURER
  const manufacturer = product?.manufacturer ?? {}
  const distributor = product?.distributor ?? {}
  const productManufacturerId = product?.product?.id
    ? parseInt(product.product.id)
    : null
  const productManufacturerSku = productManufacturer?.sku ?? ''
  const freeGood = product?.freeGood ?? null
  const image = productManufacturer?.image ?? null
  const productManufacturerName = productManufacturer?.name || ''
  const isConnected = distributor?.isConnected ? distributor.isConnected : false
  const isPreferred = distributor?.isPreferred ? distributor.isPreferred : false
  const isZenChoice = productManufacturer?.zenChoice || false
  const {
    sku: productDistributorSku = '',
    lastOrderedPracticeLocationDate = null,
    lastOrderedPracticeLocationQty = null,
    isInPracticeLocationCart = false,
    isSaveForLater = false,
    stockLevel = 0,
    isAddedToWorkSpace = false,
    workSpaceItemId = null,
    workSpaceId = null,
    workSpaceName = null,
    status = '',
  } = productDistributor
  return {
    id: PRODUCT_TYPE_DISTRIBUTOR ? productDistributorId : productManufacturerId,
    typeContentProduct: type,
    productDistributorId,
    productDistributorStatus: status,
    productManufacturerId,
    name: productManufacturerName,
    sku:
      type === PRODUCT_TYPE_DISTRIBUTOR
        ? productDistributorSku
        : productManufacturerSku,
    stockLevel,
    price:
      type === PRODUCT_TYPE_DISTRIBUTOR
        ? getProductDistributorPrice(productDistributor)
        : getProductManufacturerPrice(
            productManufacturer?.minPrice ?? 0,
            productManufacturer?.maxPrice ?? 0
          ),
    addedShoppingCart:
      type === PRODUCT_TYPE_DISTRIBUTOR ? isInPracticeLocationCart : false,
    image,
    distributor,
    manufacturer,
    priceType: getProductDistributorPriceType(productDistributor),
    isAuthorized: productDistributor.isAuthorized,
    isZenChoice,
    isConnected,
    isPreferred,
    isSaveForLater,
    freeGood,
    isAddedToWorkSpace,
    workSpaceItemId,
    workSpaceId,
    workSpaceName,
    orderHistory: getOrderHistory(type, lastOrderedPracticeLocationDate),
    orderHistoryQty: lastOrderedPracticeLocationQty,
    cartItem:
      type === PRODUCT_TYPE_DISTRIBUTOR
        ? getCartItemData(productDistributor)
        : {},
  }
}

export const mapProductStatistics = (list) => {
  return list.reduce((acc, productDistributor) => {
    const {
      product = {},
      lastOrderedPracticeLocationDate = null,
      lastOrderedPracticeLocationQty = null,
      isInPracticeLocationCart = false,
      isSaveForLater = false,
      sku = '',
      stockLevel = 0,
      distributor = {},
      productId: productManufacturerId = null,
      freeGood,
      isAddedToWorkSpace = false,
      workSpaceItemId = null,
      workSpaceId = null,
      workSpaceName = null,
      status = '',
    } = productDistributor
    const productModel = product?.['product-model'] ?? {}
    const manufacturerName = productModel?.manufacturer?.name ?? ''
    const manufacturerId = productModel?.manufacturerId
      ? parseInt(productModel.manufacturerId)
      : null
    const productDistributorId = productDistributor?._jv?.id
      ? parseInt(productDistributor._jv.id)
      : null
    const item = {
      id: productDistributorId,
      productDistributorStatus: status,
      productManufacturerId,
      orderHistory: getOrderHistory(
        PRODUCT_TYPE_DISTRIBUTOR,
        lastOrderedPracticeLocationDate
      ),
      orderHistoryQty: lastOrderedPracticeLocationQty,
      addedShoppingCart: isInPracticeLocationCart,
      isSaveForLater,
      name: product?.name,
      isZenChoice: product?.zenChoice || false,
      isAuthorized: productDistributor.isAuthorized,
      image: getProductImage(product),
      typeContentProduct: PRODUCT_TYPE_DISTRIBUTOR,
      productDistributorId,
      category: productModel?.category?.name ?? '',
      manufacturer: {
        id: manufacturerId,
        name: manufacturerName,
      },
      distributor: {
        id: distributor?._jv?.id ? parseInt(distributor._jv.id) : null,
        name: distributor?.name ?? '',
      },
      priceType: getProductDistributorPriceType(productDistributor),
      isConnected: isNotUndefined(distributor?.isConnected)
        ? distributor.isConnected
        : false,
      isPreferred: isNotUndefined(distributor?.preferred)
        ? distributor.preferred
        : false,
      stockLevel,
      sku,
      price: getProductDistributorPrice(productDistributor),
      freeGood,
      isAddedToWorkSpace,
      workSpaceItemId,
      workSpaceId,
      workSpaceName,
      cartItem: getCartItemData(productDistributor),
    }

    acc.push(item)
    return acc
  }, [])
}

export const getPagination = (page) => {
  const { size, number } = page
  return {
    page: {
      number,
      size,
    },
  }
}

export const getQueryParamsString = (filters = {}, meta = {}) => {
  filters = { ...filters } // make a copy to dereference vuex store.
  if (filters?.fromOrderHistory === true) {
    filters.fromOrderHistory = '1'
  } else if (filters?.fromOrderHistory === false) {
    filters.fromOrderHistory = '0'
  }
  let filtersQueryString = ''
  let pageQueryString = ''
  if (isHavingProps(meta)) {
    const { page } = getPagination(meta)
    pageQueryString = Object.keys(page)
      .map((key) => `page[${key}]=${page[key]}`)
      .join('&')
  }

  if (isHavingProps(filters)) {
    const filtersKeys = Object.keys(filters)
    const lastKey = filtersKeys[filtersKeys.length - 1]
    filtersQueryString = filtersKeys.reduce((acc, key) => {
      let value = ''
      let type = ''
      const filterValue = filters[key]
      if (Array.isArray(filterValue) && filterValue.length) {
        type = 'array'
        value = filterValue.join()
      } else if (
        typeof filterValue === 'object' &&
        isHavingProps(filterValue)
      ) {
        type = 'object'
        value = filterValue
      } else if (['string', 'boolean'].includes(typeof filterValue)) {
        type = typeof filterValue
        value =
          key === 'searchable' ? encodeURIComponent(filterValue) : filterValue
      }
      acc = collectFilterString(value, key, lastKey, type, acc)
      return acc
    }, '')
  }
  let result = `${filtersQueryString}`
  if (pageQueryString) result += `${pageQueryString}`
  return result
}

export const collectFilterString = (
  value = null,
  key = null,
  lastKey = null,
  type = '',
  acc
) => {
  if (!value || !key || !lastKey || !type) return acc
  if (type !== 'object') {
    acc += `filters[${key}]=${value}&`
  } else {
    let prop
    let objectValueString = ''
    for (prop in value) {
      objectValueString += `filters[${key}][${prop}]=${value[prop]}&`
    }
    acc += objectValueString
  }
  return acc
}

export const onBackResultsCatalog = () => {
  const lsPath = JSON.parse(localStorage.getItem('pathBackResultsCatalog'))
  return lsPath ?? '/'
}

export const getProductDistributorPrice = (productDistributor = {}) => {
  return productDistributor?.distributorPrice ?? null
}
export const getProductDistributorPriceType = (productDistributor = {}) => {
  return (
    getProductDistributorPrice(productDistributor)?.actualPrice?.type ?? null
  )
}

export const findTopItem = (productDistributorId = null, list = {}) => {
  const { ordered = [], recentlyPurchased = [], mostPurchased = [] } = list
  if (!productDistributorId) {
    return []
  }
  const foundList = []
  if (isHavingProps(ordered)) {
    const index = ordered.findIndex(
      (productDistributor) =>
        parseInt(productDistributor.productDistributorId) ===
        parseInt(productDistributorId)
    )
    if (index !== -1) {
      foundList.push({
        index,
        typeName: TOP_10_ORDERED_NAME,
      })
    }
  }
  if (isHavingProps(recentlyPurchased)) {
    const index = recentlyPurchased.findIndex(
      (productDistributor) =>
        parseInt(productDistributor.productDistributorId) ===
        parseInt(productDistributorId)
    )
    if (index !== -1) {
      foundList.push({
        index,
        typeName: TOP_10_RECENTLY_NAME,
      })
    }
  }
  if (isHavingProps(mostPurchased)) {
    const index = mostPurchased.findIndex(
      (productDistributor) =>
        parseInt(productDistributor.productDistributorId) ===
        parseInt(productDistributorId)
    )
    if (index !== -1) {
      foundList.push({
        index,
        typeName: TOP_10_MOST_NAME,
      })
    }
  }

  return foundList
}

export const getCartItemData = (productDistributor = {}) => {
  const cartItem = {
    id: null,
    qty: 0,
  }
  if (!isHavingProps(productDistributor)) return cartItem
  return {
    ...cartItem,
    id: productDistributor?.cartItemId ?? null,
    qty: productDistributor?.practiceLocationCartQty ?? 0,
  }
}

export const getUpdateInventoryData = (workSpaceItem = {}) => {
  const {
    id: workSpaceItemId = null,
    work_space_id: workSpaceId = null,
    work_space_name: workSpaceName = null,
  } = workSpaceItem
  return {
    isAddedToWorkSpace: !!workSpaceId,
    workSpaceId,
    workSpaceItemId,
    workSpaceName,
  }
}
