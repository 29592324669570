export default {
  methods: {
    shrink(value, length = 50) {
      if (!value) {
        return ''
      }
      if (value.length > length) {
        return `${value.substring(0, length)}...`
      }
      return value
    },
  },
}
