import { isNull } from 'lodash'
import * as types from './mutation-types'
import { isObjectNotUndefinedHasProps } from '~/ZenOne-StoryBook/helpers/object'
import { saveLocalStorageProp } from '~/ZenOne-StoryBook/helpers/localStorage'
import { CART_LS_ALIAS } from '~/store/modules/shopping_cart/helper'

export default {
  [types.SET_DISTRIBUTORS](state, payload) {
    state.distributors = payload
  },
  [types.SET_DISTRIBUTOR_BY_INDEX](state, { distributor = {}, index = null }) {
    if (isNull(index) || !isObjectNotUndefinedHasProps(distributor))
      return false
    const distributorsCopy = [...state.distributors]
    distributorsCopy[index] = { ...distributor }
    state.distributors = [...distributorsCopy]
  },
  [types.SET_SAVED_FOR_LATER_LIST](state, payload) {
    state.savedForLaterList = payload
  },
  [types.SET_IS_CHECKOUT](state, payload) {
    state.isCheckout = payload
  },
  [types.SET_DELETED_ITEMS](state, payload) {
    state.deletedItems = payload
  },
  [types.CLEAR_DISTRIBUTORS](state) {
    state.distributors = []
  },
  [types.CLEAR_SAVED_FOR_LATER_LIST](state) {
    state.savedForLaterList = []
  },
  [types.SET_CART_SELECTED_IDS](state, { ids }) {
    state.selected.cart = ids
    saveLocalStorageProp(CART_LS_ALIAS, 'cartSelectedIds', ids)
  },
  [types.SET_SAVED_FOR_LATER_SELECTED_IDS](state, { ids }) {
    state.selected.savedForLater = ids
    saveLocalStorageProp(CART_LS_ALIAS, 'savedForLaterSelectedIds', ids)
  },
  [types.SET_CART_ITEMS_COUNT](state, payload) {
    state.cartItemsCount = payload
  },
  [types.SET_SELECT_ALL_CART_ITEMS_TRIGGER](state) {
    state.selectAllCartItemsTrigger = !state.selectAllCartItemsTrigger
  },
  [types.SET_DESELECT_ALL_CART_ITEMS_TRIGGER](state) {
    state.deselectAllCartItemsTrigger = !state.deselectAllCartItemsTrigger
  },
  [types.SET_CART_ITEMS_TOTAL_COUNT](state, payload) {
    state.cartItemsTotalCount = payload
  },
  [types.SET_SAVING_COSTS](state, payload) {
    state.savingCosts = payload
  },
  [types.SET_SAVING_SUBTOTAL](state, payload) {
    state.savingSubtotal = payload
  },
  [types.SET_WITHOUT_SAVINGS](state, payload) {
    state.withoutSavings = payload
  },
  [types.SET_IS_HAVING_ACTIVE_SAVINGS](state, payload) {
    state.isHavingActiveSavings = payload
  },
  [types.SET_CART_ITEM_IDS](state, { ids = [] }) {
    state.cartItemIds = [...ids]
    saveLocalStorageProp(CART_LS_ALIAS, 'cartItemIds', ids)
  },
  [types.CLEAR_CART_ITEM_IDS](state) {
    state.cartItemIds = []
    saveLocalStorageProp(CART_LS_ALIAS, 'cartItemIds', [])
  },
  [types.SET_SELECT_ALL_SFL_TRIGGER](state) {
    state.selectAllSflTrigger = !state.selectAllSflTrigger
  },
  [types.SET_DESELECT_ALL_SFL_TRIGGER](state) {
    state.deselectAllSflTrigger = !state.deselectAllSflTrigger
  },
  [types.SET_SFL_IDS](state, { ids = [] }) {
    state.savedForLaterIds = [...ids]
    saveLocalStorageProp(CART_LS_ALIAS, 'savedForLaterIds', ids)
  },
  [types.CLEAR_SFL_IDS](state) {
    state.savedForLaterIds = []
    saveLocalStorageProp(CART_LS_ALIAS, 'savedForLaterIds', [])
  },
  [types.SET_SEARCH_TERM](state, payload) {
    state.searchTerm = payload
  },
  [types.SET_ALL_CART_ITEMS_SELECTED](state, val) {
    state.isAllCartItemsSelected = val
    saveLocalStorageProp(CART_LS_ALIAS, 'isAllCartItemsSelected', val)
  },
}
