
import { mapGetters } from 'vuex'

export default {
  name: 'FullScreenLoader',
  computed: {
    ...mapGetters({
      show: 'ui/showFullScreenLoader',
    }),
  },
}
