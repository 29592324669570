
import { directive as onClickaway } from 'vue-clickaway'
import { FOLDER_TYPE } from './../helper'
export default {
  name: 'FolderNameInput',
  directives: {
    onClickaway,
  },
  props: {
    initialName: {
      type: String,
      default: '',
    },
    entityType: {
      type: String,
      default: FOLDER_TYPE,
    },
    dialog: {
      type: Boolean,
      default: false,
      required: true,
    },
    isCreateMode: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      model: '',
      modelBefore: '',
      inputFocus: false,
    }
  },
  computed: {
    isModelEmpty() {
      return this.model === ''
    },
    showInput() {
      return this.inputFocus
    },
    showText() {
      return !this.inputFocus
    },
    placeholder() {
      return this.entityType === FOLDER_TYPE
        ? 'Enter Folder Name'
        : 'Enter Sub Folder Name'
    },
  },
  watch: {
    showInput: {
      immediate: true,
      handler(value) {
        this.$emit('on-show-name-input-change', value)
      },
    },
    dialog: {
      immediate: true,
      handler(value) {
        if (!value) {
          this.inputFocus = false
          this.model = ''
          this.modelBefore = ''
        } else {
          this.initModel()
          if (this.isCreateMode) {
            this.focusIn(true)
          }
        }
      },
    },
  },
  methods: {
    initModel() {
      this.model = this.initialName
      this.modelBefore = this.initialName
    },
    changeModelHandler() {
      this.updateFolderName()
    },
    textClickHandler() {
      this.focusIn()
    },
    clickAwayHandler() {
      this.focusOut()
    },
    updateFolderName() {
      this.$emit('on-folder-name-update', { name: this.model })
    },
    keyUpEnterHandler() {
      this.focusOut(true)
    },
    keyUpEscapeHandler() {
      this.focusOut()
    },
    async focusIn(onlyRefFocus = false) {
      if (!onlyRefFocus) {
        this.modelBefore = this.initialName
      }
      this.inputFocus = true
      await this.$nextTick(() => {
        const input = this.$refs?.input ?? null
        if (input) input.focus()
      })
    },
    focusOut(enterKeyDown = false) {
      const input = this.$refs?.input ?? null
      if (input) input.blur()
      this.inputFocus = false
      if (enterKeyDown) {
        this.$emit('on-folder-name-update', {
          name: this.model,
          needRequest: true,
        })
      }
    },
  },
}
