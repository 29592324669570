import * as types from './mutation-types'
import { api } from '~/config/api'
export default {
  async RechargeSubscription({ commit }, payload) {
    const { practiceLocationId = null, isRenew = false } = payload
    try {
      if (!practiceLocationId) throw new Error('No practice location id')
      if (!isRenew) commit(types.SET_IS_RETRY_BTN_CLICKED, true)
      else commit(types.SET_IS_RENEW_BTN_CLICKED, true)
      await this.$axios.post(
        `/${api.PRACTICE_LOCATIONS}/${practiceLocationId}/${api.SUBSCRIPTION_RECHARGE}`
      )
      return true
    } catch (e) {
      if (!isRenew) commit(types.SET_IS_RETRY_BTN_CLICKED, false)
      else commit(types.SET_IS_RENEW_BTN_CLICKED, false)
      console.error('Recharge subscription is failed', e)
    }
  },
}
