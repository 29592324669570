
import iconCurrencyUsd from '~/components/img/icon-currency-usd-small.svg'
import currencyFormat from '~/ZenOne-StoryBook/mixins/currencyFormat'

export default {
  name: 'TopMenuBudget',
  mixins: [currencyFormat],
  props: {
    budget: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      iconCurrencyUsd,
      customBudget: '',
    }
  },
  watch: {
    budget(newValue) {
      this.onBudget(newValue)
    },
  },
  mounted() {
    this.onBudget(this.budget)
  },
  methods: {
    onBudget(newValue) {
      const value = `${newValue}`.split('.')
      if (value.length === 1) {
        this.customBudget = newValue
        this.customBudget = this.currencyFormat(`${this.customBudget}`)
        return
      }
      this.customBudget = value[1].length === 1 ? newValue + '0' : newValue
      this.customBudget = this.currencyFormat(`${this.customBudget}`)
    },
  },
}
