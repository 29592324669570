import actions from './actions'
import mutations from './mutations'
import { SUCCESS, ALERT_DEFAULT_TIMEOUT } from '~/ZenOne-StoryBook/helpers/ui'
const ui = {
  state: {
    headerIsHidden: false,
    showFullScreenLoader: false,
    showFadeFS: false,
    notifications: [],
    alert: {
      show: false,
      content: '',
      type: SUCCESS,
      actionComponent: null,
      timeout: ALERT_DEFAULT_TIMEOUT,
    },
  },
  getters: {
    showFullScreenLoader: (state) => state.showFullScreenLoader,
    showFadeFS: (state) => state.showFadeFS,
    alert: (state) => state.alert,
    notifications: (state) => state.notifications,
    headerIsHidden: (state) => state.headerIsHidden,
  },
  mutations,
  actions,
  namespaced: true,
}

export default ui
