export default {
  methods: {
    parserQueryParamsUrl(query) {
      return {
        filters: {
          grandparentId: query?.grandparentId
            ? this.stringModifier(query?.grandparentId)
            : [],
          categoryId: query?.categoryId
            ? this.stringModifier(query?.categoryId)
            : [],
          parentId: query?.parentId ? this.stringModifier(query?.parentId) : [],
          distributorId: query?.distr ? this.stringModifier(query?.distr) : [],
          packageQty: query?.packageQty
            ? this.stringModifier(query?.packageQty)
            : [],
          fromOrderHistory: !!query?.orderHistory,
          manufacturerId: query?.manuf ? this.stringModifier(query?.manuf) : [],
          modifierValueId: query?.modifier
            ? this.stringModifier(query?.modifier)
            : [],
          searchable:
            query?.search && Object.keys(query?.search).length
              ? query.search
              : '',
        },
        pageNumber: query?.pg ? parseInt(query?.pg) : 1,
        pageSize: query?.ps ? parseInt(query?.ps) : 35,
        sort: {
          name: query?.sort === '-popularity' ? 'Popularity' : 'Price',
          value: query?.sort,
        },
      }
    },
    stringModifier(str) {
      return str.split(',').map((id) => parseInt(id))
    },
  },
}
