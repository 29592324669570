
import { mapGetters } from 'vuex'
import iconCartCounter from '~/components/img/icon-cart-counter.svg'
import iconCartEmpty from '~/components/img/icon-cart-empty.svg'

export default {
  name: 'TopMenuCart',
  data() {
    return {
      iconCartCounter,
      iconCartEmpty,
    }
  },
  computed: {
    ...mapGetters({
      qty: 'shoppingCart/cartItemsTotalCount',
    }),
    iconSrc() {
      const { qty = 0 } = this
      return qty > 0 ? this.iconCartCounter : this.iconCartEmpty
    },
  },
}
