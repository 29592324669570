import { isInteger } from 'lodash'
import * as types from './mutation-types'
import {
  addMappedPriceByTypeToOriginList,
  createNewPriceItemById,
  findPriceIndexById,
} from './helper'
import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'
import { isHavingProps } from '~/ZenOne-StoryBook/helpers/object'

export default {
  [types.ADD_PRICES](state, checkingIds = []) {
    if (!isArrayWithLength(checkingIds)) return false
    this.commit(`prices/${types.SET_TOTAL}`, checkingIds.length)
    const oldPrices = [...state.prices].filter(
      (price) => !checkingIds.includes(price?.productDistributorId)
    )
    const pricesToAdd = checkingIds.map((id) => createNewPriceItemById(id))
    state.prices = [...oldPrices, ...pricesToAdd]
  },
  [types.UPDATE_PRICE](state, data = {}) {
    const prices = [...state.prices]
    const index = findPriceIndexById(prices, data?.meta?.id ?? null)
    if (index !== -1) {
      let newDistributorPrice = data?.meta?.distributorPrice ?? {}
      if (!isHavingProps(newDistributorPrice)) return false
      newDistributorPrice = addMappedPriceByTypeToOriginList(
        newDistributorPrice,
        'actualPrice',
        data?.meta?.hasPriceUpdateError ?? true
      )
      newDistributorPrice = addMappedPriceByTypeToOriginList(
        newDistributorPrice,
        'upperPrice'
      )
      prices[index] = {
        ...prices[index],
        distributorPrice: newDistributorPrice,
        stockLevel: data?.meta?.stockLevel ?? 0,
      }
      state.prices = [...prices]
      this.commit(`prices/${types.SET_LAST_UPDATED_INDEX}`, index)
      this.commit(`prices/${types.INC_COUNTER}`)
    }
  },
  [types.SET_LAST_UPDATED_INDEX](state, index = 0) {
    state.lastUpdatedIndex = index
  },
  [types.DELETE_PRICE_BY_INDEX](state, index = null) {
    if (!isInteger) return false
    const prices = [...state.prices]
    prices.splice(index, 1)
    state.prices = [...prices]
  },
  [types.CLEAR_PRICES](state) {
    state.prices = []
  },
  [types.RESET_COUNTER](state) {
    state.counter = 0
  },
  [types.INC_COUNTER](state) {
    state.counter++
  },
  [types.SET_TOTAL](state, total = 0) {
    state.total = total
  },
  [types.RESET_TOTAL](state) {
    state.total = 0
  },
}
