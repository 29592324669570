import * as types from './mutation-types'

export default {
  [types.SET_DISTRIBUTORS_LIGHT](state, list) {
    state.distributorsLight = list
  },
  [types.SET_ROOT_CATEGORIES_LIGHT](state, list) {
    state.rootCategoriesLight = list
  },
}
