import {
  getLocalStorage,
  setLocalStorage,
} from '~/ZenOne-StoryBook/helpers/localStorage'
import { isArrayWithLength } from '~/ZenOne-StoryBook/helpers/types'
import { EVENT_NOTIFICATIONS_ALIAS } from '~/helpers/common'
import {
  DISTRIBUTOR_PROCESS,
  DISTRIBUTOR_PROCESS_SUBTYPE_LOGIN_RESULT,
} from '~/ZenOne-StoryBook/helpers/socket'

export const FAIL_REASON_WRONG_LOGIN = 'wrong_login'
export const FAIL_REASON_NEED_VERIFICATION = 'need_verification'
export const FAIL_REASON_INTERNAL_ERROR = 'internal_error'
export const FAIL_REASON_EXTERNAL_ERROR = 'external_error'

export const STATUS_ACTIVE = 'active'
export const STATUS_PENDING = 'pending'
export const STATUS_FAILED = 'failed'

export const getDistributorLoginResultList = () => {
  return getLocalStorage(EVENT_NOTIFICATIONS_ALIAS)?.eventNotificationList?.[
    DISTRIBUTOR_PROCESS_SUBTYPE_LOGIN_RESULT ?? []
  ]
}

export const checkLSHasDistributorLoginEvents = (status = '') => {
  if (!status) return false

  const distributorLoginResultList = getDistributorLoginResultList()
  if (!isArrayWithLength(distributorLoginResultList)) {
    return false
  }

  let result = false
  for (const loginResult of distributorLoginResultList) {
    if (loginResult.status === status) {
      result = true
      break
    }
  }

  return result
}

export const checkLSHasInvalidDistributorLogin = () =>
  checkLSHasDistributorLoginEvents(STATUS_FAILED)

export const checkLSHasSuccessDistributorLogin = () =>
  checkLSHasDistributorLoginEvents(STATUS_ACTIVE)

export const collectSuccessLoginResultNotification = (data = {}) => {
  return {
    type: DISTRIBUTOR_PROCESS,
    subType: DISTRIBUTOR_PROCESS_SUBTYPE_LOGIN_RESULT,
    meta: { ...data },
  }
}

export const removeLoginResultEvent = (
  eventArray = [],
  distributorId = null
) => {
  if (!distributorId) return eventArray
  const index = eventArray.findIndex(
    (event) => parseInt(event.distributor_id) === parseInt(distributorId)
  )

  if (index !== -1) {
    const list = [...eventArray]
    list.splice(index, 1)
    return list
  }
  return eventArray
}

export const removeSuccessDistributorLoginEvents = () => {
  const distributorLoginResultList = getDistributorLoginResultList()
  if (!isArrayWithLength(distributorLoginResultList)) return false
  const newList = distributorLoginResultList.filter(
    (distributorLogin) => distributorLogin.status === STATUS_FAILED
  )
  setNewLoginResultEventListToLS(newList)
  return newList
}

export const checkInvalidDistributorLogins = (
  actualFailedDistributorIds = []
) => {
  const distributorLoginResultList = getDistributorLoginResultList()
  const failedLoginResultList = distributorLoginResultList.filter(
    (loginResult) => loginResult.status === STATUS_FAILED
  )
  if (!isArrayWithLength(failedLoginResultList)) return false
  const notificationIdListForRemove = []
  for (const failedLoginResult of failedLoginResultList) {
    const failedDistributorId = failedLoginResult.distributor_id
    if (!actualFailedDistributorIds.includes(failedDistributorId)) {
      notificationIdListForRemove.push(failedLoginResult.notificationId)
    }
  }

  if (!isArrayWithLength(notificationIdListForRemove)) return false

  const newList = distributorLoginResultList.filter(
    (distributorLogin) =>
      !notificationIdListForRemove.includes(distributorLogin.notificationId)
  )
  setNewLoginResultEventListToLS(newList)
  return newList
}

const setNewLoginResultEventListToLS = (list) => {
  const lsData = getLocalStorage(EVENT_NOTIFICATIONS_ALIAS)
  lsData.eventNotificationList[DISTRIBUTOR_PROCESS_SUBTYPE_LOGIN_RESULT] = list
  setLocalStorage(EVENT_NOTIFICATIONS_ALIAS, lsData)
}
