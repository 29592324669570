
import { mapGetters } from 'vuex'
import { clearLocalStorage } from '~/ZenOne-StoryBook/helpers/localStorage'

export default {
  name: 'NotificationAuthorizationAdminPortal',
  computed: {
    ...mapGetters({
      practiceLocationName: 'user/practiceLocationName',
    }),
    text() {
      return `You are acting on behalf of ${this.practiceLocationName}`
    },
  },
  methods: {
    async onClose() {
      clearLocalStorage()
      await this.$auth.logout('refresh')
      window.close()
    },
  },
}
